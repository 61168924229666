import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import { AppPath } from "../../models/AppPath";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { DatabaseKnowledge } from "../../models/DatabaseKnowledge";
import { AddDataTableModal } from "./AddDatabaseModal";
import ManageDatabaseModal from "./ManageDatabaseModal";

export const KnowledgeDatabases: React.FC = () => {
  const knowledgeService = useKnowledgeService();

  const { teamId } = useParams<{ teamId: string }>();
  const teamService = useTeams();

  const [databases, setDatabases] = useState<DatabaseKnowledge[]>([]);
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAddDatabase, setShowAddDatabase] = useState(false);
  const [addDatabaseState, setAddDatabaseState] =
    useState<AnimationState>("ready");

  const [searchParams, setSearchParams] = useSearchParams();
  const managingDatabaseId = searchParams.get("manage");
  const managingDatabase = databases.find((db) => db.id === managingDatabaseId);
  const observationRef = useRef<(() => void) | null>(null);

  const handleManageClick = (databaseId: string) => {
    setSearchParams({ manage: databaseId });
  };

  const handleModalClose = () => {
    setSearchParams({});
  };

  const loadDatabases = async () => {
    const fetchedDatabases =
      await knowledgeService.databaseKnowledgeRepo.getList(
        knowledgeService.databaseKnowledgePath(teamId!)
      );
    setDatabases(fetchedDatabases);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedTeam = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );

        if (fetchedTeam) {
          setTeam(fetchedTeam);
        }

        // Set up the observation of the databases list
        observationRef.current?.();
        const observation = knowledgeService.databaseKnowledgeRepo.observeList(
          knowledgeService.databaseKnowledgePath(teamId!),
          (updatedDatabases) => {
            setDatabases(updatedDatabases);
          }
        );
        observationRef.current = observation;
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went wrong while loading database sources");
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();

    return () => {
      observationRef.current?.();
    };
  }, [teamService, knowledgeService, teamId]);

  return (
    <CommonContainer>
      {managingDatabase && team && (
        <ManageDatabaseModal
          dataTable={managingDatabase}
          teamId={teamId!}
          isOpen={!!managingDatabaseId}
          onClose={handleModalClose}
          onSuccess={loadDatabases}
          team={team}
        />
      )}
      <CommonHeader
        title="Data"
        subtitle="Adapt and react to user needs over time with vector-powered data. Persistent chat memory anyone?"
        sections={[
          { name: "Knowledge", link: AppPath.settings(teamId ?? "") },
          { name: "Databases", link: AppPath.knowledge(teamId ?? "") },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Add Dataset"
            onClick={() => setShowAddDatabase(true)}
            buttonState={addDatabaseState}
            setButtonState={setAddDatabaseState}
            style="action"
            key="addDatabase"
            error={error}
            setError={setError}
            id="addDatabaseButton"
            leftIcon="plus"
          />,
        ]}
      />
      <FailureModal
        message={error ?? undefined}
        shows={error !== null}
        closed={() => setError("")}
      />

      {showAddDatabase && team && (
        <AddDataTableModal
          teamId={teamId!}
          isOpen={showAddDatabase}
          onClose={() => setShowAddDatabase(false)}
          onSuccess={loadDatabases}
          team={team}
        />
      )}
      {loading && <CommonSpinner />}
      {team && (
        <div className="flex flex-col gap-2 mt-4">
          {databases.map((database) => (
            <DatabaseRow
              key={database.id}
              database={database}
              onManageClick={() => handleManageClick(database.id!)}
            />
          ))}
          {databases.length === 0 && (
            <div className="text-center text-gray-500 py-8">
              No databases added yet. Click "Add Database" to get started.
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};

interface DatabaseRowProps {
  database: DatabaseKnowledge;
  onManageClick: () => void;
}

const DatabaseRow: React.FC<DatabaseRowProps> = ({
  database,
  onManageClick,
}) => {
  return (
    <div className="px-6 bg-white shadow h-28 rounded-lg grid grid-cols-[1fr_auto] items-center w-full">
      <div className="min-w-0 flex gap-2 flex-col">
        <div className="text-lg font-medium truncate">{database.name}</div>
      </div>
      <div className="flex items-center gap-6 pl-4">
        <AnimatedButton
          title="Manage"
          style="action"
          setButtonState={undefined}
          buttonState="ready"
          onClick={onManageClick}
          classNameIn="w-32"
          leftIcon="share-nodes"
          font="font-sans"
          id={`manage${database.id}Button`}
        />
      </div>
    </div>
  );
};
