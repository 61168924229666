import React, { useState, useEffect } from "react";
import { HowTo, RemoteConfig } from "../../../models/RemoteConfig";
import { CommonModal } from "../../Common/CommonModal";
import { useTeams } from "../../../contexts/TeamContext";
import { CommonUnderlineButton } from "../../Common/CommonUnderlineButton";
import { CommonSpinner } from "../../Common/CommonLoading";
import AnimatedButton from "../../AnimatedButton";
import Icon from "../../Icon";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

interface InfoComponentProps {
  howToId: string;
  howToName?: string;
}

export const InfoComponent: React.FC<InfoComponentProps> = ({
  howToId,
  howToName,
}) => {
  const teamService = useTeams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [howTo, setHowTo] = useState<HowTo | null>(null);
  const [config, setConfig] = useState<RemoteConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const displayName = howToName || howToId;

  useEffect(() => {
    const loadHowTo = async () => {
      try {
        setIsLoading(true);

        const remoteConfig = await teamService.remoteConfigRepo.get(
          teamService.remoteConfigPath(),
          "version1"
        );

        setConfig(remoteConfig);

        if (remoteConfig?.howTos && remoteConfig.howTos[howToId]) {
          setHowTo(remoteConfig.howTos[howToId]);
        }
      } catch (error) {
        console.error("Error loading how-to guide:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadHowTo();
  }, [teamService, howToId]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-blue-25 h-32 justify-center p-4 rounded-lg flex flex-row gap-4 items-center">
          <CommonSpinner />
        </div>
      ) : howTo ? (
        <div className="bg-blue-25 p-4 rounded-lg flex flex-row gap-4 items-center">
          {/* Video thumbnail with play button */}
          <div
            className="relative cursor-pointer w-32 h-18"
            onClick={handleOpenModal}
          >
            <div className="aspect-video bg-gray-100 rounded-lg overflow-hidden relative">
              {howTo.youtubeLink ? (
                <img
                  src={`https://img.youtube.com/vi/${getYoutubeID(
                    howTo.youtubeLink
                  )}/maxresdefault.jpg`}
                  alt={`${howTo.title} thumbnail`}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    // Fallback to default thumbnail if high-res isn't available
                    const target = e.target as HTMLImageElement;
                    target.src = `https://img.youtube.com/vi/${getYoutubeID(
                      howTo.youtubeLink
                    )}/0.jpg`;
                  }}
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-200">
                  <span className="text-gray-500">No video available</span>
                </div>
              )}

              {/* Play button overlay */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="size-8 bg-blue-600 rounded-full flex items-center justify-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-8 h-8"
                  >
                    <path d="M8 5.14v14l11-7-11-7z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Text content */}
          <div className="flex-1">
            <h2 className="text-base font-gooper font-medium text-gray-700">
              Curious about the{" "}
              <span className="text-blue-600">{displayName}</span>?
            </h2>
            <p className="text-sans text-sm text-gray-500">
              Watch this short video or read the step by step guide here.
            </p>
            <div className="justify-end flex">
              {" "}
              <CommonUnderlineButton
                onClick={handleOpenModal}
                title="Learn more"
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Modal for detailed content */}
      {howTo && (
        <CommonModal
          isOpen={isModalOpen}
          onDismiss={handleCloseModal}
          title={howTo.title}
          subtitle={howTo.tldr}
        >
          <div className="space-y-6">
            {/* YouTube embed if available */}
            {howTo.youtubeLink && (
              <div className="aspect-video w-full">
                <iframe
                  src={`https://www.youtube.com/embed/${getYoutubeID(
                    howTo.youtubeLink
                  )}`}
                  title={howTo.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full rounded-lg"
                ></iframe>
              </div>
            )}

            {/* Sections with text */}
            <div className="mt-8 space-y-6">
              {howTo.sections.map((section, index) => (
                <div key={index} className="">
                  <div className="flex flex-row justify-between">
                    <h3 className="text-2xl font-semibold text-gray-700 mb-4 font-gooper">
                      {section.headline}
                    </h3>
                    {section.link && (
                      <CommonUnderlineButton
                        title={section.link.title}
                        onClick={() => {
                          if (section.link?.url) {
                            // Ensure the URL has a protocol
                            let url = section.link.url;
                            if (
                              !url.startsWith("http://") &&
                              !url.startsWith("https://")
                            ) {
                              url = "https://" + url;
                            }

                            // Open link in a new tab/window
                            window.open(url, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    )}
                  </div>
                  {section.text && (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      className="prose prose-sm max-w-none w-full text-gray-600 prose-a:text-blue-600"
                    >
                      {section.text}
                    </ReactMarkdown>
                  )}
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 w-full gap-2">
              <AnimatedButton
                leftIcon="profile"
                buttonState="ready"
                title="Talk to team"
                style="secondary"
                onClick={() => {
                  window.Intercom(
                    "showNewMessage",
                    `I need help with the ${howToName ?? howToId}`
                  );
                }}
              />
              <AnimatedButton
                leftChild={<Icon type="eb-logo" className="size-4" />}
                buttonState="ready"
                title="Open Docubot"
                style="action"
                onClick={() =>
                  window.open(
                    config?.docubotURL,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              />
            </div>
          </div>
        </CommonModal>
      )}
    </>
  );
};

// Helper function to extract YouTube video ID from URL
function getYoutubeID(url: string): string {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : "";
}

export default InfoComponent;
