import React, { useRef, useEffect } from "react";
import { ChatMessage } from "easybeam-react";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import Icon from "../../../Icon";
import ChatBubble from "../ChatBubble";

interface PromptChatProps {
  messages: ChatMessage[];
  processedMessages: ChatMessage[];
  isRunning: boolean;
  onSendMessage: (message: string) => void;
}

export const PromptChat: React.FC<PromptChatProps> = ({
  messages,
  processedMessages,
  isRunning,
  onSendMessage,
}) => {
  const [newMessage, setNewMessage] = React.useState("");
  const [sendState, setSendState] = React.useState<AnimationState>("ready");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setSendState(isRunning == true ? "loading" : "ready");
  }, [messages, isRunning]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim().length === 0 || isRunning) return;

    onSendMessage(newMessage.trim());
    setNewMessage("");
    setSendState("ready");
  };

  return (
    <div className="flex bg-gray-50 flex-col min-h-0 h-full">
      <div className="flex-1 overflow-y-auto p-4">
        {processedMessages.length === 0 && (
          <div className="text-center text-gray-500 mt-10">
            <p>Describe what you want your AI Agent to do.</p>
          </div>
        )}
        {processedMessages.map((message, index) => (
          <ChatBubble
            key={message.id}
            message={message}
            isLast={index === processedMessages.length - 1}
            loading={
              isRunning &&
              index === processedMessages.length - 1 &&
              message.role === "AI"
            }
            hideCost={true}
            teamName=""
          />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex-none p-4 border-t border-gray-200 bg-white">
        <div className="bg-white rounded-lg">
          <textarea
            className="w-full h-20 p-2 border border-gray-200 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Describe what kind of prompt you want to create..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isRunning}
          />
          <div className="flex justify-end mt-2">
            <AnimatedButton
              title="Send"
              onClick={handleSendMessage}
              buttonState={sendState}
              setButtonState={setSendState}
              style="action"
              disabled={newMessage.trim().length === 0 || isRunning}
              leftChild={
                <Icon type="paper-plane" className="rotate-90 size-4" />
              }
              font="font-sans font-medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
