import React, { useState } from "react";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";
import {
  ConversationalRequirement,
  WorkflowAction,
} from "../../../../models/WorkflowAction";
import ConfigSlider from "../../../Portals/Portal Detail/AIProvider/ConfigSlider";
import { ConversationalRequirementCard } from "./ConversationRequirementCard";

interface ActionCardProps {
  action: WorkflowAction;
  onUpdate: (action: WorkflowAction) => void;
  onDelete: (id: string) => void;
}

export const ActionCard: React.FC<ActionCardProps> = ({
  action: initialAction,
  onUpdate,
  onDelete,
}) => {
  const [action, setAction] = useState<WorkflowAction>(initialAction);
  const [showRequirements, setShowRequirements] = useState(false);

  const handleUpdate = (updates: Partial<WorkflowAction>) => {
    const updatedAction = { ...action, ...updates };
    setAction(updatedAction);
    onUpdate(updatedAction);
  };

  const addRequirement = () => {
    const newRequirement: ConversationalRequirement = {
      id: `req_${Date.now()}`,
      name: "",
      type: "text",
      description: "",
    };

    handleUpdate({
      conversationalRequirements: [
        ...(action.conversationalRequirements || []),
        newRequirement,
      ],
    });
  };

  const updateRequirement = (
    reqId: string,
    updates: Partial<ConversationalRequirement>
  ) => {
    const updatedRequirements =
      action.conversationalRequirements?.map((req) =>
        req.id === reqId ? { ...req, ...updates } : req
      ) || [];
    handleUpdate({ conversationalRequirements: updatedRequirements });
  };

  const deleteRequirement = (reqId: string) => {
    const updatedRequirements =
      action.conversationalRequirements?.filter((req) => req.id !== reqId) ||
      [];
    handleUpdate({ conversationalRequirements: updatedRequirements });
  };

  return (
    <div className="flex flex-col gap-2 p-4 bg-gray-50 border border-gray-200 rounded-lg">
      <div className="flex justify-between items-start">
        <div className="flex flex-col gap-2 flex-grow ">
          <CommonInputSegment
            title="Name"
            onChange={(name) => handleUpdate({ name })}
            value={action.name}
            placeholder="Name this action"
            error={undefined}
            setError={undefined}
            id={`action_name_${action.id}`}
            className="font-sans text-gray-700 bg-gray-50"
          />
          <CommonInputSegment
            title="Condition"
            onChange={(condition) => handleUpdate({ condition })}
            value={action.condition}
            placeholder="The condition that needs to be met for the action to trigger"
            error={undefined}
            setError={undefined}
            id={`action_description_${action.id}`}
            className="font-sans text-gray-700"
            subtitle="Determines if your action is triggered"
          />
          <ConfigSlider
            name="Sensitivity"
            shortDescription="Adjust how easily this action is triggered"
            min={0}
            max={100}
            value={action.sensitivity}
            rangeLabels={["Never", "Sometimes", "Always"]}
            onChange={(sensitivity) => handleUpdate({ sensitivity })}
            classNameIn="font-sans font-medium"
          />

          <div className="mt-4">
            <button
              onClick={() => setShowRequirements(!showRequirements)}
              className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
            >
              <Icon
                type={"chevron"}
                className={`size-4 ${showRequirements ? "" : "rotate-180"}`}
              />
              Conversational Requirements
            </button>

            {showRequirements && (
              <div className="mt-2 pl-4">
                {action.conversationalRequirements?.map((req) => (
                  <ConversationalRequirementCard
                    key={req.id}
                    requirement={req}
                    onUpdate={(updates) => updateRequirement(req.id, updates)}
                    onDelete={() => deleteRequirement(req.id)}
                  />
                ))}
                <button
                  onClick={addRequirement}
                  className="mt-2 flex items-center gap-2 text-blue-500 hover:text-blue-600"
                >
                  <Icon type="plus" className="size-4" />
                  Add Requirement
                </button>
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => onDelete(action.id)}
          className="ml-2 p-2 text-gray-200 hover:bg-gray-50 rounded-full"
        >
          <Icon type="x" />
        </button>
      </div>
    </div>
  );
};
