import { useAuth } from "../../contexts/AuthContext";

import { Button, Card, Checkbox } from "flowbite-react";
import { useState, type FC, useRef } from "react";
import AuthPage from "./AuthPage";
import { ValidatedInputState, ValidatedInput } from "./ValidatedInput";
import { GoogleLogInButton } from "./GoogleLogInButton";
import { ExternalPath } from "../../models/ExternalPath";

const SignUp: FC = function () {
  const auth = useRef(useAuth());
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signUpEnabled, setSignUpEnabled] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] =
    useState<ValidatedInputState>("default");
  const [passwordError, setPasswordError] = useState("");

  const [email, setEmail] = useState("");
  const [emailState, setEmailState] = useState<ValidatedInputState>("default");
  const [emailError, setEmailError] = useState("");

  const handleGoogleSignIn = async () => {
    try {
      await auth.current.signInWithGoogle();
    } catch (e) {
      if (e instanceof Error) {
        if (e.message == "Firebase: Error (auth/popup-closed-by-user).") {
        } else {
          setError(e.message);
        }
        setError(e.message);
      } else {
        setError("Google login failed");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!email.match(/.+@.+/)) {
      setEmailError("Please enter a valid email!!!");
      setEmailState("error");
      return;
    }
    setIsLoading(true);

    try {
      const result = await auth.current.validatePassword(password);
      if (result.length > 0) {
        setError(result[0]);
        setError("error");
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong, please try again");
      }
    }

    try {
      await auth.current.signUp(email, password, window.location.origin);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message == "Please enter an email") {
          setError(
            "Invalid credentials. Maybe there's an account already here, try resetting password your if you think so."
          );
        } else {
          setError(e.message);
        }
      } else {
        setError("Please try again in a few seconds.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const runAndResetEmailError = (
    value: string,
    action: (input: string) => void
  ) => {
    action(value);
    setEmailState("default");
    setEmailError("");
  };

  const runAndResetPasswordError = (
    value: string,
    action: (input: string) => void
  ) => {
    action(value);
    setPasswordError("");
    setPasswordState("default");
  };

  const handleEmailUnfocus = () => {
    if (!email.match(/.+@.+/)) {
      setEmailError("Please enter a valid email");
      setEmailState("error");
      return;
    }
    setEmailError("");
    setEmailState("validated");
  };

  const handlePasswordUnfocus = async () => {
    try {
      const result = await auth.current.validatePassword(password);
      if (result.length > 0) {
        setPasswordError(result[0]);
        setPasswordState("error");
      } else {
        setPasswordError("");
        setPasswordState("validated");
      }
    } catch (e) {
      if (e instanceof Error) {
        setPasswordError(e.message);
        setPasswordState("error");
      }
    }
  };

  return (
    <AuthPage
      error={error}
      modalBackTitle="return to sign up"
      setError={setError}
      isLoading={isLoading}
      successMessage={""}
      setSuccessMessage={() => undefined}
    >
      <Card className="shadow-none w-[344px] md:w-[512px] rounded-2xl">
        <div className="space-y-4">
          <h1 className="text-2xl font-gooper font-black text-gray-800 sm:text-5xl">
            Start your free trial
          </h1>
          <p className="text-sm font-medium text-gray-700">
            Already have an account?&nbsp;
            <a
              href="login"
              className="font-medium text-blue-600 hover:underline"
            >
              Log in
            </a>
          </p>
          <ValidatedInput
            title="Email"
            inputId="email"
            inputType="email"
            placeholder="name@company.com"
            error={emailError}
            state={emailState}
            onUnfocus={() => handleEmailUnfocus()}
            onChange={(text) => runAndResetEmailError(text, setEmail)}
          />
          <ValidatedInput
            title="Password"
            inputId="password"
            inputType="password"
            placeholder="••••••••"
            error={passwordError}
            state={passwordState}
            onUnfocus={() => handlePasswordUnfocus()}
            onChange={(text) => runAndResetPasswordError(text, setPassword)}
          />
          <div className="flex items-center">
            <div className="h-0.5 w-full bg-gray-200"></div>
            <div className="px-5 font-gooper font-medium text-center text-gray-500">
              or
            </div>
            <div className="h-0.5 w-full bg-gray-200"></div>
          </div>
          <form className="space-y-6">
            <GoogleLogInButton
              title="Sign up with Google"
              onClick={() => handleGoogleSignIn()}
              isDisabled={false}
            />
            <div className="flex items-center">
              <div className="flex h-5 items-center text-green-50">
                <Checkbox
                  className="text-green-500 focus:ring-0"
                  id="remember-social"
                  required
                  checked={signUpEnabled}
                  onChange={(e) => setSignUpEnabled(e.target.checked)}
                />
              </div>
              <div className="ml-3 text-sm">
                <div className="text-gray-600 text-sm font-light">
                  By signing up, you are creating an easybeam account, and you
                  agree to easybeam's{" "}
                  <a
                    href={ExternalPath.tos()}
                    className="text-blue-600 hover:underline cursor-pointer"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href={ExternalPath.pp()}
                    className="text-blue-600 hover:underline cursor-pointer"
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            </div>

            <Button
              type="submit"
              className="w-full"
              onClick={handleEmailSignUp}
              color="primary"
              disabled={!signUpEnabled}
            >
              Sign up
            </Button>
          </form>
        </div>
      </Card>
    </AuthPage>
  );
};

export default SignUp;
