import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Team } from "../../models/Team";
import { useNavigate } from "react-router-dom";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { User } from "../../models/User";
import { TeamSubscription } from "../../models/TeamSubscription";
import ActionModal from "../ActionModal";
import { CommonLoading } from "../Common/CommonLoading";
import Icon from "../Icon";

interface TeamSelectorProps {
  currentUser: User | undefined;
  currentTeam: Team | undefined;
  isCollapsed: boolean;
}

const variants = {
  expanded: {
    opacity: 1,
    display: "flex",
    transition: {
      opacity: { delay: 0.1, duration: 0.1 },
    },
  },
  collapsed: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
    transition: {
      opacity: { duration: 0.1 },
    },
  },
};

const TeamSelector: React.FC<TeamSelectorProps> = ({
  currentTeam,
  isCollapsed,
  currentUser,
}) => {
  const navigate = useNavigate();
  const teamService = useTeams();
  const authService = useAuth();

  const [showSignOut, setShowSignOut] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState<[Team, TeamSubscription][]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const loadData = async () => {
      if (!currentUser?.teams) {
        return;
      }

      const activeTeamIds = Object.entries(currentUser!.teams!)
        .filter(([_, isActive]) => isActive)
        .map(([teamId, _]) => {
          return Promise.all([
            teamService.teamRepo.get(teamService.teamPath(), teamId),
            teamService.teamSubscriptionRepo.get(
              teamService.teamSubscriptionPath(),
              teamId
            ),
          ]);
        });

      const allFetchedTeams = await Promise.allSettled(activeTeamIds);
      const aliveFetched = allFetchedTeams.map((p) => {
        if (p.status == "fulfilled") {
          return p.value;
        } else {
          return null;
        }
      });
      const activeTeams = aliveFetched.filter(
        (r) => r?.[0] != null && r?.[1] != null
      ) as [Team, TeamSubscription][];
      setTeams(activeTeams);
    };
    loadData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectTeam = async (team: Team) => {
    setIsLoading(true);
    await Promise.all([
      authService.userRepo.update(
        { currentTeam: team.id },
        authService.userPath(),
        authService.userId!
      ),
      teamService.fetchCurrentTeamData(team.id!),
    ]);

    setIsLoading(false);
    navigate(`/teams/${team.id!}`);
    setShowDropdown(false);
  };

  const handleSignOut = async () => {
    setShowDropdown(false);
    await authService.signOut();
    navigate(`/bye`);
  };

  const handleCreateTeam = () => {
    navigate(`/newteam`);
  };

  const teamSelector = (
    <div className="p-2 w-full justify-start items-center flex cursor-pointer">
      {isLoading && <CommonLoading />}
      <div className="grow shrink w-full basis-0 justify-start items-center gap-3 flex">
        <motion.div
          className="flex-col justify-start gap-1 items-start"
          initial={false}
          variants={variants}
          animate={isCollapsed ? "collapsed" : "expanded"}
        >
          <div className="text-gray-400 text-xs font-normal uppercase leading-3">
            Team
          </div>
          <div className="text-gray-700 text-sm font-normal font-gooper leading-none truncate">
            {currentTeam?.name || "DEMO"}
          </div>
        </motion.div>
        {!isCollapsed && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.1,
                duration: 0.1,
              },
            }}
          >
            <Icon type="double-chevron" className="fill-gray-400" />
          </motion.div>
        )}
      </div>
    </div>
  );

  return (
    <div
      ref={wrapperRef}
      className="w-full h-8 relative inline-block text-left"
    >
      <ActionModal
        title="Are you sure you wanna sign out?"
        message="We'll miss you dearly"
        backButtonTitle="Back to Safety"
        shows={showSignOut}
        closed={() => setShowSignOut(false)}
        action={() => handleSignOut()}
        iconType="close"
        actionTitle="Sign Out"
        style="normal"
      />
      <div
        onClick={() => !isCollapsed && setShowDropdown(true)}
        className={`w-full fill-blue-200 rounded justify-start items-center flex cursor-pointer ${
          isCollapsed
            ? "pointer-events-none bg-transparent border-transparent"
            : "bg-gray-50 border border-blue-50"
        }`}
      >
        {teamSelector}
        {showDropdown && !isCollapsed && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.2,
              },
            }}
            className="absolute top-0 w-full text-left border-1 border-gray-300 bg-gray-50 right-0 z-50 rounded-md shadow-lg"
          >
            <button
              onClick={() => setShowDropdown(true)}
              className="w-full fill-gray-300 bg-gray-100 rounded-t justify-start items-center flex cursor-pointer"
            >
              {teamSelector}
            </button>
            <div className={`flex w-full flex-col border-y border-gray-300`}>
              {teams.map((team) => (
                <div
                  key={team[0].id!}
                  className={`${
                    team[0].id == currentTeam?.id ? "bg-blue-50" : "bg-gray-50"
                  } py-1 px-1 hover:bg-blue-25 flex flex-col cursor-pointer`}
                  onClick={() => {
                    handleSelectTeam(team[0]);
                  }}
                >
                  <div className="font-gooper text-sm">{team[0].name}</div>
                  <div className="font-normal text-gray-400 text-xs">
                    {team[1].currentEvent?.name}
                  </div>
                </div>
              ))}
            </div>
            <button
              className={`h-6 w-full justify-end items-center flex flex-col bg-gray-100`}
              onClick={() => handleCreateTeam()}
            >
              <div className="px-2 w-full items-center justify-end flow-root">
                <div className="text-blue-500  float-left text-xs font-gooper ">
                  Add Team
                </div>
                <Icon
                  type="plus-inverted"
                  className="float-right size-3 text-blue-500"
                />
              </div>
            </button>
            <button
              className={`py-2 px-2 w-full justify-center items-center rounded-b-lg flex flex-col bg-gray-100`}
              onClick={() => setShowSignOut(true)}
            >
              <div className="w-full flow-root">
                <div className="text-gray-400 float-left text-xs ">
                  Sign Out
                </div>
                <Icon
                  type="log-out"
                  className="float-right flex items-center justify-center size-3 rounded-full bg-gray-200 text-gray-400"
                />
              </div>
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default TeamSelector;
