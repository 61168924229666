import React, { useEffect } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../models/User";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import BannerCareousel from "./BannerCarousel";
import { useAuth } from "../../contexts/AuthContext";
import GetStartedPortals from "./GetStartedPortals";
import GetStartedTeams from "./GetStartedTeams";
import GetStartedAPI from "./GetStartedAPI";
import PortalsCard from "./PortalsCard";
import LogsCard from "./LogsCard";
import CommonContainer from "../Common/CommonContainer";
import { AgentsCard } from "./AgentsCard";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { AppPath } from "../../models/AppPath";

interface HomePageProps {
  currentUser: User | undefined;
}

const HomePage: React.FC<HomePageProps> = ({ currentUser }) => {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const authService = useAuth();
  const workflowService = useWorkflowService();

  useEffect(() => {
    const processLoad = async () => {
      if (!currentUser) {
        return;
      }
      localStorage.setItem(STORAGE_KEYS.LAST_ACCESSED_TEAM, teamId!);
      await authService.userRepo.update(
        { inProgressTeam: undefined },
        authService.userPath(),
        currentUser?.id
      );
      if (!teamId) {
        return;
      }
      const freshUser = await authService.currentUser();
      console.log("first open data", freshUser);

      if (freshUser?.firstOpen !== false) {
        console.log("redirecting");

        const updateOpp = authService.userRepo.update(
          { firstOpen: false },
          authService.userPath(),
          freshUser!.id!
        );
        const fetchExamples = workflowService.workflowRepo.getList(
          workflowService.workflowPath(teamId)
        );

        const [examples] = await Promise.all([fetchExamples, updateOpp]);

        const bestExamples = examples.filter((w) => w.isBestExample);
        const bestExample =
          bestExamples.length > 0 ? bestExamples[0] : examples[0];

        navigate(AppPath.workflow(teamId, bestExample.id!));
      }
    };

    processLoad();
  }, [teamId]);

  const setUp =
    localStorage.getItem(STORAGE_KEYS.COMPLETED_API) == "true" &&
    localStorage.getItem(STORAGE_KEYS.COMPLETED_MEMBERS) == "true" &&
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true";

  return (
    <CommonContainer>
      <CommonHeader
        title={`Welcome ${currentUser?.name ?? "Someone"}`}
        subtitle={
          setUp ? "" : "Get started by completing the 3 easy steps below"
        }
        sections={[]}
        teamId={teamId!}
        actions={[]}
      />
      <div className="">
        <BannerCareousel />
      </div>
      {setUp ? (
        <div className="flex flex-col">
          <div className="text-lg text-gray-500"></div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row -mx-2 gap-y-4">
          <div className="md:w-1/3 px-2">
            <GetStartedPortals teamId={teamId!} />
          </div>
          <div className="md:w-1/3 px-3">
            <GetStartedTeams teamId={teamId!} />
          </div>
          <div className="md:w-1/3 px-2">
            <GetStartedAPI teamId={teamId!} />
          </div>
        </div>
      )}
      <div className="">
        <AgentsCard teamId={teamId!} />
      </div>
      <div className="">
        <PortalsCard teamId={teamId!} />
      </div>
      <div className="">
        <LogsCard teamId={teamId!} />
      </div>
    </CommonContainer>
  );
};

export default HomePage;
