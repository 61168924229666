import { ReactNode, useState, useEffect, useRef } from "react";

export const Tooltip: React.FC<{
  title: string;
  description: string;
  children: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
}> = ({ title, description, children, placement = "top" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // Calculate tooltip position
  const updatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;

    const trigger = triggerRef.current.getBoundingClientRect();
    const tooltip = tooltipRef.current.getBoundingClientRect();
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;

    let top = 0;
    let left = 0;

    // Position based on placement
    switch (placement) {
      case "top":
        top = trigger.top + scrollY - tooltip.height - 10;
        left = trigger.left + scrollX + trigger.width / 2 - tooltip.width / 2;
        break;
      case "bottom":
        top = trigger.bottom + scrollY + 10;
        left = trigger.left + scrollX + trigger.width / 2 - tooltip.width / 2;
        break;
      case "left":
        top = trigger.top + scrollY + trigger.height / 2 - tooltip.height / 2;
        left = trigger.left + scrollX - tooltip.width - 10;
        break;
      case "right":
        top = trigger.top + scrollY + trigger.height / 2 - tooltip.height / 2;
        left = trigger.right + scrollX + 10;
        break;
    }

    // Adjust if tooltip goes off screen
    const padding = 10;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Prevent going off left or right
    if (left < padding) {
      left = padding;
    } else if (left + tooltip.width > viewportWidth - padding) {
      left = viewportWidth - tooltip.width - padding;
    }

    // Prevent going off top or bottom
    if (top < padding) {
      top = padding;
    } else if (top + tooltip.height > viewportHeight - padding) {
      top = viewportHeight - tooltip.height - padding;
    }

    setPosition({ top, left });
  };

  useEffect(() => {
    if (isVisible) {
      // Initial position calculation
      updatePosition();

      // Update on scroll or resize
      window.addEventListener("scroll", updatePosition);
      window.addEventListener("resize", updatePosition);

      // Check again after render to handle tooltip content loading
      requestAnimationFrame(updatePosition);

      return () => {
        window.removeEventListener("scroll", updatePosition);
        window.removeEventListener("resize", updatePosition);
      };
    }
  }, [isVisible, placement]);

  // Dynamically determine arrow position and direction
  const getArrowStyles = () => {
    if (!triggerRef.current || !tooltipRef.current) return {};

    // Position for the arrow
    let arrowStyle: any = {
      position: "absolute",
      width: "16px",
      height: "10px",
      backgroundColor: "#333",
    };

    // Base arrow position on the calculated placement
    switch (placement) {
      case "top":
        arrowStyle = {
          ...arrowStyle,
          clipPath: "polygon(50% 100%, 0 0%, 100% 0%)",
          bottom: "0px",
          left: "50%",
          transform: "translateX(-50%) rotate(180deg)",
        };
        break;
      case "bottom":
        arrowStyle = {
          ...arrowStyle,
          clipPath: "polygon(50% 100%, 0 0%, 100% 0%)",
          top: "0px",
          left: "50%",
          transform: "translateX(-50%)",
        };
        break;
      case "left":
        arrowStyle = {
          ...arrowStyle,
          clipPath: "polygon(50% 50%, 0% 0%, 0% 100%)",
          right: "0px",
          top: "50%",
          transform: "translateY(-50%) rotate(180deg)",
          width: "10px",
          height: "16px",
        };
        break;
      case "right":
        arrowStyle = {
          ...arrowStyle,
          clipPath: "polygon(50% 50%, 0% 0%, 0% 100%)",
          left: "0px",
          top: "50%",
          transform: "translateY(-50%)",
          width: "10px",
          height: "16px",
        };
        break;
    }

    return arrowStyle;
  };

  return (
    <>
      <div
        ref={triggerRef}
        className="inline-flex"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onFocus={() => setIsVisible(true)}
        onBlur={() => setIsVisible(false)}
      >
        {children}
      </div>

      {isVisible && (
        <div
          ref={tooltipRef}
          style={{
            position: "fixed",
            top: `${position.top}px`,
            left: `${position.left}px`,
            zIndex: 9999,
          }}
          className="transition-opacity duration-150"
        >
          <div className="flex max-w-xs flex-col gap-0 items-center">
            <div className="rounded bg-gray-800 text-gray-0 p-2 text-left flex flex-col max-w-full gap-2">
              <div className="font-gooper text-base">{title}</div>
              <div className="text-xs text-gray-100">{description}</div>
            </div>
            <div style={getArrowStyles()} />
          </div>
        </div>
      )}
    </>
  );
};
