import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { DatabaseKnowledge } from "../../models/DatabaseKnowledge";
import { Team } from "../../models/Team";

interface AddDataTableModalProps {
  teamId: string;
  team: Team;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface MetadataField {
  name: string;
  type: string;
}

export const AddDataTableModal: React.FC<AddDataTableModalProps> = ({
  teamId,
  team,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [name, setName] = useState("");
  const [selectedPineconeDB, setSelectedPineconeDB] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [metadataFields, setMetadataFields] = useState<MetadataField[]>([
    { name: "", type: "string" },
  ]);

  const handleAddField = () => {
    setMetadataFields([...metadataFields, { name: "", type: "string" }]);
  };

  const handleRemoveField = (index: number) => {
    setMetadataFields(metadataFields.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index: number, field: Partial<MetadataField>) => {
    const newFields = [...metadataFields];
    newFields[index] = { ...newFields[index], ...field };
    setMetadataFields(newFields);
  };

  const handleSubmit = async () => {
    if (!name.trim()) {
      setError("Please enter a name");
      return;
    }

    if (!selectedPineconeDB) {
      setError("Please select a vector server");
      return;
    }

    if (metadataFields.some((field) => !field.name.trim())) {
      setError("All metadata field names must be filled");
      return;
    }

    setButtonState("loading");
    try {
      const newTable: DatabaseKnowledge = {
        name: name.trim(),
        metadataFields: metadataFields,
        createdAt: new Date(),
        pineconeDBId: selectedPineconeDB,
      };

      await knowledgeService.databaseKnowledgeRepo.create(
        newTable,
        knowledgeService.databaseKnowledgePath(teamId)
      );

      setButtonState("ready");
      onSuccess();
      onClose();
    } catch (e) {
      setError(e instanceof Error ? e.message : "Failed to add data set");
      setButtonState("error");
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose} title="Add Data Set">
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Data Set Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            placeholder="My Data Set"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError(null);
            }}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Server
          </label>
          <select
            value={selectedPineconeDB}
            onChange={(e) => setSelectedPineconeDB(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select a server</option>
            {Object.entries(team.pineconeDBs || {}).map(([id, db]) => (
              <option key={id} value={id}>
                {db.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Metadata Fields
            </label>
            <button
              onClick={handleAddField}
              className="text-sm text-blue-600 hover:text-blue-700"
              type="button"
            >
              Add Field
            </button>
          </div>
          <div className="space-y-3">
            {metadataFields.map((field, index) => (
              <div key={index} className="flex gap-3">
                <input
                  type="text"
                  placeholder="Field name"
                  value={field.name}
                  onChange={(e) =>
                    handleFieldChange(index, { name: e.target.value })
                  }
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
                <select
                  value={field.type}
                  onChange={(e) =>
                    handleFieldChange(index, { type: e.target.value })
                  }
                  className="w-32 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="string">String</option>
                  {/* <option value="number">Number</option>
                    <option value="date">Date</option>
                    <option value="boolean">Boolean</option> */}
                </select>
                {metadataFields.length > 1 && (
                  <button
                    onClick={() => handleRemoveField(index)}
                    className="px-2 text-gray-400 hover:text-gray-600"
                    type="button"
                  >
                    ×
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {error && <p className="text-sm text-red-600">{error}</p>}
      </div>

      <div className="flex justify-between gap-3 mt-6">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <AnimatedButton
          title="Add Data Set"
          onClick={handleSubmit}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          error={error}
          setError={setError}
          triggerKey="Enter"
        />
      </div>
    </CommonModal>
  );
};
