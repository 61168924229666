import React, { useEffect, useState } from "react";
import { usePortalService } from "../../contexts/PortalContext";
import { Portal } from "../../models/Portal";
import Icon from "../Icon";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { CommonErrorToast } from "../Common/CommonErrorToast";
import { AppPath } from "../../models/AppPath";
import { useTeams } from "../../contexts/TeamContext";
import { filterExamplePortals } from "../../utils/FilterUtils";
import { useAnalytics } from "../../contexts/AnalyticsContext";

interface PortalsCardProps {
  teamId: string;
  providerId?: string;
}

const PortalsCard: React.FC<PortalsCardProps> = ({ teamId, providerId }) => {
  const portalService = usePortalService();
  const navigate = useNavigate();
  const teamService = useTeams();
  const analytics = useAnalytics();

  const [portals, setPortals] = useState<Portal[]>([]);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPortals = async () => {
      setLoading(true);
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      const portals = await portalService.portalRepo.getList(
        portalService.portalPath(teamId!),
        { name: "modifiedAt", descending: true },

        undefined,
        5
      );
      setPortals(
        portals.filter((p) => filterExamplePortals(team ?? undefined, p))
      );
      if (providerId) {
        const getVersions = portals.map((portal) =>
          portalService.portalVersionRepo
            .getList(
              portalService.portalVersionPath(teamId!, portal.id!),
              undefined,
              [{ key: "configId", filter: "==", value: providerId }]
            )
            .then((versions) => {
              return { portal: portal, versions: versions };
            })
        );
        const fetchedData = await Promise.all(getVersions);
        const filtered = fetchedData
          .filter((d) => d.versions.length)
          .sort((d) => d.versions.length);
        const fetchedPortals = filtered.map((d) => d.portal);
        setPortals(fetchedPortals);
      } else {
        setPortals(portals);
      }
      setLoading(false);
    };

    fetchPortals();
  }, []);

  const handleCreateNew = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      const result = await portalService.createPortal(teamId);
      analytics.track("createdPrompt");

      navigate(
        AppPath.portalVersion(
          teamId,
          result.portal.id,
          result.version.id!,
          true
        )
      );
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create portal. Please try again."
      );
      setLoading(false); // Ensure loading is set to false if there's an error
    }
  };

  const handleOpen = (portal: Portal) => {
    navigate(AppPath.portal(teamId, portal.id!));
  };

  return (
    <div className="bg-gray-0 w-full min-h-52 rounded-lg border border-gray-200 flex flex-col">
      <div className="flex flex-row px-4 pt-4 pb-2 border-b justify-between">
        <div className="text-gray-700 text-base font-medium font-gooper">
          Prompts
        </div>
        <button
          type="submit"
          className={`relative flex fill-blue-500 gap-1 items-center justify-center text-sm font-gooper text-blue-500`}
          onClick={() => handleCreateNew()}
        >
          Add new
          <Icon type={"plus"} className="size-4" />
          {error && (
            <CommonErrorToast message={error} onClose={() => setError(null)} />
          )}
        </button>
      </div>
      {loading ? (
        <div className="w-full h-full items-center flex justify-center">
          <Spinner className="fill-pink-500" size="xl" light={true} />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-2 border-0 p-4">
          {portals.map((portal) => {
            return (
              <div
                key={portal.id}
                className="flex flex-row w-full justify-between bg-green"
              >
                <div className="flex flex-row w-full gap-2 items-center">
                  <Icon type={"portals"} className="size-4 text-gray-200" />
                  <div className="text-gray-700 font-gooper text-xs font-medium">
                    {portal.name}
                  </div>
                </div>
                <div className="flex justify-end flex-row w-full gap-1 text-xs font-normal items-center">
                  <div className="text-gray-400 ">
                    {`${portal.currentVersionData?.name ?? ""}`}
                  </div>
                  <button
                    type="submit"
                    className={`flex items-center justify-center text-xs font-gooper text-blue-500 underline`}
                    onClick={() => handleOpen(portal)}
                  >
                    Open Prompt
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PortalsCard;
