import React, { useState } from "react";

import { CommonModal } from "../../Common/CommonModal";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import { CommonInputSegment } from "../../Common/CommonInput";
import { ExternalPath } from "../../../models/ExternalPath";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { Workflow } from "../../../models/Workflow";

interface WorkflowNameModalProps {
  teamId: string;
  shows: boolean;
  setShows: (shows: boolean) => void;
  workflow: Workflow;
  setWorkflow: (portal: Workflow) => void;
}

export const WorkflowNameModal: React.FC<WorkflowNameModalProps> = ({
  teamId,
  shows,
  setShows,
  workflow,
  setWorkflow,
}) => {
  const [saveWorkflowState, setSaveWorkflowState] =
    useState<AnimationState>("ready");
  const [workflowName, setWorkflowName] = useState(workflow.name);
  const workflowService = useWorkflowService();

  const handleSaveWorkflow = async () => {
    setSaveWorkflowState("loading");
    try {
      const newWorkflow: Workflow = { ...workflow, name: workflowName };
      workflowService.workflowRepo.update(
        newWorkflow,
        workflowService.workflowPath(teamId),
        workflow.id!
      );
      setWorkflow(newWorkflow);
      setSaveWorkflowState("success");
    } catch {
      setSaveWorkflowState("error");
    }
  };

  const handleDismiss = () => {
    setWorkflowName(workflow.name);
    setShows(false);
  };

  if (!shows) return null;

  const subItem = (
    <div className="flex justify-between items-center w-full">
      <div className="flex justify-between text-xs text-gray-500 gap-1">
        <span> Created</span>
        <span>{formatDate(workflow.createdAt)}</span>
        <span>{formatTime(workflow.createdAt)}</span>
      </div>
      <div className="flex justify-between text-xs text-gray-900 gap-2">
        <span className="">{`Agent ID:`}</span>
        <span id="agentId" className="font-medium">{`${workflow.id}`}</span>
      </div>
    </div>
  );

  return (
    <CommonModal
      isOpen={shows}
      onDismiss={handleDismiss}
      title={`Give your Agent a name`}
      subtitle={`This is your home to create complex multi-setp AI systems. Each Agent can be integrated into your app and power an AI feature.`}
    >
      {subItem}
      <CommonInputSegment
        title=""
        onChange={setWorkflowName}
        value={workflowName}
        placeholder="Name your Agent here"
        id="agentName"
      />
      <div className="flex flex-row justify-between">
        <div className="text-gray-500 text-xs">You can change this later</div>

        <a
          href={ExternalPath.agentDocs()}
          target="_blank"
          className="text-blue-400 hover:text-blue-500 text-xs underline"
        >
          Learn more about agents here
        </a>
      </div>
      <div className="flex flex-row justify-between">
        <AnimatedButton
          title={`Cancel`}
          onClick={handleDismiss}
          buttonState={"ready"}
          style={"normal"}
          key="cancel"
          font="font-sans"
          id="cancelButton"
          classNameIn="w-40"
        />
        <AnimatedButton
          title={"Save Agent"}
          onClick={() => handleSaveWorkflow()}
          buttonState={saveWorkflowState}
          setButtonState={setSaveWorkflowState}
          style={"action"}
          key="action"
          leftIcon={"workflows"}
          font="font-sans"
          id="saveButton"
          classNameIn="w-40"
          after={(success) => {
            if (success) {
              handleDismiss();
            }
          }}
          triggerKey="Enter"
        />
      </div>
    </CommonModal>
  );
};
