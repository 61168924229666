import React from "react";
import { QueryDatabaseData } from "../../Map/Nodes/DatabaseNode";
import { DatabaseKnowledge } from "../../../../models/DatabaseKnowledge";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";

interface QueryComponentProps {
  data: QueryDatabaseData;
  selectedDatabase: DatabaseKnowledge | undefined;
  availableVariables: string[];
  updateNode: (newData: Partial<QueryDatabaseData>) => void;
  setShowAdvancedSettings: (show: boolean) => void;
}

export const QueryComponent: React.FC<QueryComponentProps> = ({
  data,
  selectedDatabase,
  availableVariables,
  updateNode,
  setShowAdvancedSettings,
}) => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <div className="text-sm font-medium text-gray-700">Search Query</div>
          <CommonUnderlineButton
            title="Advanced settings"
            onClick={() => setShowAdvancedSettings(true)}
          />
        </div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.searchQuery}
          setSelectedInput={(input) => updateNode({ searchQuery: input })}
          currentNodeId={data.id}
          id="databaseQuery"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Select the input to use as the search query for finding relevant
          records
        </div>
      </div>

      {selectedDatabase && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium text-gray-700">
              Metadata Filters
            </div>
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={data.useMetadataFilters}
                onChange={(e) =>
                  updateNode({ useMetadataFilters: e.target.checked })
                }
                className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
              />
              Filter by metadata
            </label>
          </div>

          {data.useMetadataFilters && (
            <div className="p-3 bg-gray-50 rounded-md border border-gray-200">
              <div className="flex flex-col gap-4">
                {selectedDatabase.metadataFields.map((field, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <div className="text-sm font-medium text-gray-700">
                      {field.name}
                    </div>
                    <VariableInputSelector
                      availableVariables={availableVariables}
                      selectedInput={
                        data.metadataFilters?.[field.name] ?? {
                          type: "text",
                          value: "",
                        }
                      }
                      setSelectedInput={(input) => {
                        const newFilters = {
                          ...data.metadataFilters,
                          [field.name]: input,
                        };
                        updateNode({ metadataFilters: newFilters });
                      }}
                      currentNodeId={data.id}
                      id={`filter_${field.name}`}
                      includeMessages={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="text-xs text-gray-500">
            Add metadata constraints to filter search results
          </div>
        </div>
      )}
    </div>
  );
};
