import React from "react";
import Icon from "../Icon";
interface NavigationHeaderProps {
  isCollapsed: boolean;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ isCollapsed }) => {
  return (
    <>
      {isCollapsed ? (
        <div className="flex h-8 items-center px-2 justify-center gap-1">
          <Icon type="eb-logo" className="text-blue-500" />
        </div>
      ) : (
        <div className="flex h-8 items-center px-2 justify-start gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="20"
            viewBox="0 0 40 22"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.0735 0.466553C24.9813 0.466553 21.4435 2.82509 19.7401 6.25706C18.0418 2.82509 14.4989 0.466553 10.4118 0.466553C4.66164 0.466553 0 5.12819 0 10.8784C0 16.6286 4.66164 21.2902 10.4118 21.2902C13.5213 21.2902 16.3082 19.9295 18.2182 17.7675C18.6516 17.3593 18.6818 16.679 18.2787 16.2405C17.8755 15.8021 17.1951 15.7718 16.7517 16.1649C16.7517 16.1649 16.7517 16.1649 16.7466 16.1649C15.2347 17.9741 12.9568 19.1232 10.4169 19.1232C6.17856 19.1232 2.68611 15.9281 2.21743 11.8157H18.7121C19.1908 17.1225 23.6458 21.2801 29.0785 21.2801C34.8237 21.2801 39.4904 16.6185 39.4904 10.8683C39.4904 5.11811 34.8237 0.466553 29.0735 0.466553ZM2.20735 9.93093C2.67603 5.81862 6.16848 2.6235 10.4068 2.6235C14.6451 2.6235 18.1375 5.81862 18.6062 9.93093H2.20735ZM29.0735 19.1333C24.8352 19.1333 21.3427 15.9381 20.874 11.8258H29.517L27.3197 14.0231C27.3197 14.0231 27.3096 14.0332 27.3046 14.0382C26.9367 14.4061 26.9367 15.0058 27.3046 15.3787C27.6725 15.7466 28.2722 15.7466 28.6451 15.3787L32.3291 11.6948C32.3845 11.6595 32.4349 11.6192 32.4853 11.5688C32.6718 11.3823 32.7625 11.1404 32.7625 10.8985C32.7726 10.6466 32.6818 10.3946 32.4853 10.198C32.4349 10.1476 32.3845 10.1073 32.324 10.072L28.6552 6.40321C28.6552 6.40321 28.6451 6.39313 28.6401 6.38809C28.2722 6.0202 27.6725 6.0202 27.2995 6.38809C26.9317 6.75598 26.9317 7.35569 27.2995 7.72863L29.5069 9.94101H20.869C21.3377 5.82869 24.8301 2.63358 29.0685 2.63358C33.6293 2.63358 37.3233 6.32762 37.3233 10.8885C37.3233 15.4493 33.6293 19.1433 29.0685 19.1433L29.0735 19.1333Z"
              fill="#3062FF"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="24"
            viewBox="0 0 109 24"
            fill="none"
          >
            <path
              d="M92.7809 18.5438C90.2853 18.5673 89.7438 18.4261 89.7438 17.2018C89.7438 16.5896 89.9321 16.3542 90.5678 15.9775C91.2506 15.5537 91.3212 15.2947 91.3212 14.3059V8.74953C91.3212 8.16094 91.18 8.06676 90.6855 8.04322C89.9086 8.04322 89.3906 8.01967 89.3906 7.28982C89.3906 6.67767 89.7673 6.15971 90.4972 5.6182C91.1093 5.12378 91.745 4.8648 92.4278 4.8648C93.4402 4.8648 93.7698 5.26504 93.7933 6.18325C93.7933 6.70122 93.7698 6.93666 94.0523 6.93666C94.3113 6.93666 94.3584 6.55996 94.9234 5.90073C95.3708 5.35922 96.2183 4.81771 97.5839 4.81771C98.8788 4.81771 99.6322 5.2415 100.103 5.85364C100.527 6.44224 100.503 7.03083 100.809 7.00729C101.092 7.00729 101.115 6.55996 101.798 5.8301C102.41 5.17087 103.093 4.84125 104.247 4.81771C106.295 4.79417 107.637 6.04199 107.59 8.16094C107.543 10.7743 107.449 12.6107 107.449 14.4942C107.449 15.5302 107.472 15.7656 108.014 16.0952C108.626 16.4248 108.791 16.8251 108.791 17.3666C108.791 18.4967 108.273 18.5438 105.801 18.5438C103.54 18.5438 103.117 18.379 103.117 17.296C103.117 16.5896 103.376 16.3542 103.823 16.0717C104.388 15.6714 104.412 15.3654 104.412 14.5178L104.388 9.40876C104.388 8.37283 103.917 7.73715 102.952 7.73715C101.633 7.73715 101.045 8.74953 100.974 10.1857C100.88 11.4335 100.927 13.576 100.927 14.7768C100.927 15.6243 100.974 15.8362 101.398 16.1658C101.798 16.4955 101.987 16.8015 101.987 17.4137C101.987 18.4967 101.516 18.5438 99.232 18.5438C96.9953 18.5438 96.595 18.3554 96.595 17.296C96.595 16.5896 96.8305 16.3542 97.3249 16.0717C97.7958 15.7421 97.8664 15.4124 97.8664 14.3059C97.8429 12.3753 97.8193 10.4682 97.8193 9.29104C97.8193 8.27866 97.3014 7.69006 96.4302 7.69006C94.8999 7.69006 94.3584 9.07915 94.3348 10.4682V14.8003C94.3584 15.5773 94.3584 15.7656 94.7586 16.0717C95.1824 16.4013 95.4414 16.6132 95.4414 17.296C95.4414 18.3554 95.0176 18.5438 92.7809 18.5438Z"
              fill="#374151"
            />
            <path
              d="M80.249 18.7557C77.8947 18.7557 77 17.3431 77 14.8945C77 12.1164 78.342 10.7744 80.7199 10.7744C82.109 10.7744 82.5328 11.0334 82.9095 11.0334C83.3333 11.0334 83.3097 10.6331 83.3097 8.93795C83.2862 7.57241 82.7447 7.0309 81.6146 7.0309C80.8376 7.0309 80.4374 7.24279 80.2255 8.51416C80.0842 9.38528 79.7311 9.76199 78.8129 9.76199C77.7769 9.76199 77.0706 9.24402 77.0706 8.09037C77.0706 7.10153 77.565 6.30104 78.2243 5.80662C79.2131 5.02967 80.5551 4.67651 82.1326 4.67651C85.0991 4.67651 86.394 5.90079 86.394 8.51416C86.3704 11.1746 86.1586 13.788 86.1586 14.9652C86.1586 15.5067 86.4175 15.7186 86.7471 15.7421C87.0532 15.7421 87.1474 15.5302 87.3593 15.5302C87.7595 15.5302 87.8773 15.8128 87.8773 16.3307C87.8773 17.296 87.1945 18.7086 85.7348 18.7086C85.0049 18.7086 84.2986 18.4497 83.8983 17.7904C83.5216 17.2725 83.5923 16.9664 83.2627 16.9664C82.9801 16.9664 82.9095 17.296 82.6034 17.6963C82.1561 18.3319 81.5675 18.7557 80.249 18.7557ZM81.4969 16.3072C82.5799 16.3072 83.2862 15.4361 83.2862 13.6703C83.2862 12.3518 82.9801 12.3047 82.0619 12.3047C80.8847 12.3047 80.1784 12.8698 80.1784 14.4943C80.1549 15.7657 80.5787 16.3072 81.4969 16.3072Z"
              fill="#374151"
            />
            <path
              d="M69.9462 18.8028C66.5559 18.8028 64.5547 16.4485 64.5547 11.9751C64.5547 7.2899 66.3676 4.70007 69.8991 4.70007C73.0069 4.70007 74.7256 6.84256 74.7256 10.7273C74.7256 12.1399 74.3018 12.3047 72.795 12.3047H68.1569C67.7567 12.3047 67.6154 12.3518 67.6154 12.7992C67.6389 14.7768 68.9103 15.9776 70.7938 15.9776C71.8768 15.9776 72.4654 15.7186 72.9363 15.4125C73.2424 15.2713 73.4307 15.13 73.7132 15.13C74.1841 15.13 74.3489 15.6715 74.0664 16.3543C73.5484 17.8375 71.9945 18.8028 69.9462 18.8028ZM69.2399 10.4919C71.3824 10.4919 71.6414 10.5625 71.6414 9.9739C71.6414 8.49063 70.7703 6.98383 69.4518 6.98383C68.3217 6.98383 67.5448 8.0433 67.5918 9.762C67.5918 10.6802 67.6625 10.4919 69.2399 10.4919Z"
              fill="#374151"
            />
            <path
              d="M52.3085 18.6851C51.8611 18.6851 51.3667 18.5674 51.3667 17.7198C51.3667 15.389 51.5551 9.19692 51.6728 4.06436C51.6963 3.26387 51.6021 3.19324 51.0371 3.16969C50.3543 3.14615 49.7422 3.21678 49.7422 2.48692C49.7422 1.87478 50.2366 1.2391 50.8958 0.768221C51.5315 0.320888 52.0966 0.0854492 52.8264 0.0854492C53.9565 0.0854492 54.7806 0.603413 54.7806 1.96896C54.7806 2.88717 54.6158 4.0879 54.451 5.28864C54.3097 6.15976 54.2861 6.6071 54.5687 6.6071C54.8277 6.6071 54.8983 6.15976 55.3692 5.68888C55.84 5.17092 56.5699 4.70004 57.8413 4.70004C60.5253 4.70004 62.244 6.81899 62.244 11.363C62.244 16.213 60.5959 18.638 57.2527 18.638C56.1461 18.638 55.2514 18.3084 54.6158 17.555C54.1213 17.0135 54.0036 16.5661 53.7211 16.5661C53.2973 16.5897 53.3444 17.1077 53.2502 17.7904C53.156 18.4732 52.8971 18.6851 52.3085 18.6851ZM57.3468 16.0717C58.8537 15.954 59.3716 14.6355 59.0185 11.5278C58.6889 8.60832 57.9825 7.28987 56.4522 7.45467C54.8277 7.61948 54.4274 9.26755 54.7099 11.9986C55.016 15.1535 55.8636 16.213 57.3468 16.0717Z"
              fill="#374151"
            />
            <path
              d="M41.4088 23.6763C39.643 23.6763 37.9008 22.8052 37.9008 20.7334C37.9008 19.721 38.3952 18.944 39.5959 18.944C40.7025 18.944 41.2675 19.5091 41.3853 20.6627C41.4324 21.4868 41.4088 21.8635 42.1387 21.8635C42.7979 21.8635 43.1511 21.1101 43.3159 19.6503C43.41 18.5673 43.2688 18.4025 42.068 18.12C40.7967 17.8375 40.6554 17.4608 40.2552 16.0246C39.5959 12.8226 39.078 11.2452 38.6306 8.79663C38.4187 7.87842 38.2539 7.71361 37.9243 7.45463C37.5947 7.19565 37.3828 6.98375 37.3828 6.23035C37.3828 5.19442 37.9714 4.95898 39.9491 4.95898C42.3035 4.95898 42.9862 5.17088 42.9862 6.46579C42.9862 7.07793 42.7979 7.14856 42.3506 7.36046C42.0445 7.50172 41.9739 7.7607 42.0445 8.27867C42.2328 9.62067 42.9392 12.9639 43.3394 14.8003C43.5513 15.6008 43.6219 15.7892 43.9045 15.7656C44.2812 15.7656 44.3047 15.436 44.4695 14.7532C44.8462 12.7755 45.3171 10.9862 45.529 8.60828C45.6467 7.7607 45.529 7.54881 45.0817 7.28982C44.6343 7.05439 44.3282 6.7954 44.3282 6.08909C44.3282 5.19442 44.7991 4.98253 46.9181 4.98253C48.6368 4.98253 48.9899 5.42986 48.9899 6.39516C48.9899 6.91312 48.7545 7.10147 48.4484 7.36046C48.1424 7.64298 48.0717 7.73716 47.954 8.44347C47.6479 10.4212 46.5885 15.2006 45.8586 18.6144C45.011 22.2166 44.1634 23.6763 41.4088 23.6763Z"
              fill="#374151"
            />
            <path
              d="M27.421 18.7792C26.7853 18.7792 26.6205 18.3319 26.2909 16.9428C26.0319 16.0717 25.8906 15.6243 25.8906 15.1299C25.8906 14.5649 26.1967 14.1882 26.644 13.9998C27.4445 13.6467 27.8683 13.8821 28.8101 14.9416C29.7989 16.2365 30.5759 16.7074 31.3763 16.7074C32.1297 16.7074 32.5771 16.2836 32.5771 15.6243C32.5771 14.5649 31.8237 14.0705 30.1992 13.1052C29.0926 12.5166 27.6329 11.9515 26.903 10.9391C26.4086 10.2563 26.1496 9.43231 26.1496 8.49056C26.1732 6.15972 27.7506 4.77063 29.8695 4.77063C30.8819 4.77063 31.5176 5.12379 32.012 5.59466C32.2239 5.78302 32.4358 6.01845 32.7419 6.01845C33.3069 6.01845 32.7419 4.79417 33.8955 4.79417C34.5783 4.79417 34.6725 5.21796 35.0492 6.37161C35.3788 7.21919 35.5201 7.83133 35.5201 8.23158C35.5201 8.63182 35.2846 8.91435 35.0021 9.07915C34.2016 9.52649 33.7778 9.43231 32.7654 8.44347C32.012 7.57235 31.2115 6.93666 30.364 6.93666C29.6106 6.93666 29.1161 7.384 29.1161 8.04323C29.1161 8.8908 29.8224 9.31459 31.0467 10.1151C33.048 11.0333 34.0839 11.5748 34.8137 12.5636C35.2611 13.2229 35.5201 13.9292 35.5201 14.9651C35.5201 17.4608 33.9662 18.7792 31.7295 18.7792C30.6936 18.7792 30.0108 18.4261 29.4693 17.9787C29.0926 17.7197 28.9278 17.4843 28.6453 17.4843C28.0096 17.4843 28.5982 18.7792 27.421 18.7792Z"
              fill="#374151"
            />
            <path
              d="M16.5616 18.7557C14.2072 18.7557 13.3125 17.3431 13.3125 14.8945C13.3125 12.1164 14.6545 10.7744 17.0324 10.7744C18.4215 10.7744 18.8453 11.0334 19.222 11.0334C19.6458 11.0334 19.6223 10.6331 19.6223 8.93795C19.5987 7.57241 19.0572 7.0309 17.9271 7.0309C17.1501 7.0309 16.7499 7.24279 16.538 8.51416C16.3967 9.38528 16.0436 9.76199 15.1254 9.76199C14.0894 9.76199 13.3831 9.24402 13.3831 8.09037C13.3831 7.10153 13.8776 6.30104 14.5368 5.80662C15.5256 5.02967 16.8676 4.67651 18.4451 4.67651C21.4116 4.67651 22.7065 5.90079 22.7065 8.51416C22.683 11.1746 22.4711 13.788 22.4711 14.9652C22.4711 15.5067 22.73 15.7186 23.0597 15.7421C23.3657 15.7421 23.4599 15.5302 23.6718 15.5302C24.072 15.5302 24.1898 15.8128 24.1898 16.3307C24.1898 17.296 23.507 18.7086 22.0473 18.7086C21.3174 18.7086 20.6111 18.4497 20.2108 17.7904C19.8341 17.2725 19.9048 16.9664 19.5752 16.9664C19.2926 16.9664 19.222 17.296 18.9159 17.6963C18.4686 18.3319 17.88 18.7557 16.5616 18.7557ZM17.8094 16.3072C18.8924 16.3072 19.5987 15.4361 19.5987 13.6703C19.5987 12.3518 19.2926 12.3047 18.3744 12.3047C17.1972 12.3047 16.4909 12.8698 16.4909 14.4943C16.4674 15.7657 16.8912 16.3072 17.8094 16.3072Z"
              fill="#374151"
            />
            <path
              d="M6.25873 18.8028C2.86842 18.8028 0.867188 16.4485 0.867188 11.9751C0.867188 7.2899 2.68006 4.70007 6.21164 4.70007C9.31943 4.70007 11.0381 6.84256 11.0381 10.7273C11.0381 12.1399 10.6143 12.3047 9.10754 12.3047H4.4694C4.06915 12.3047 3.92789 12.3518 3.92789 12.7992C3.95143 14.7768 5.2228 15.9776 7.10631 15.9776C8.18933 15.9776 8.77792 15.7186 9.2488 15.4125C9.55487 15.2713 9.74322 15.13 10.0257 15.13C10.4966 15.13 10.6614 15.6715 10.3789 16.3543C9.86094 17.8375 8.30705 18.8028 6.25873 18.8028ZM5.55241 10.4919C7.69491 10.4919 7.95389 10.5625 7.95389 9.9739C7.95389 8.49063 7.08276 6.98383 5.76431 6.98383C4.6342 6.98383 3.85726 8.0433 3.90434 9.762C3.90434 10.6802 3.97498 10.4919 5.55241 10.4919Z"
              fill="#374151"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default NavigationHeader;
