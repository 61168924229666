import React, { useState } from "react";
import { WriteDatabaseData } from "../../Map/Nodes/DatabaseNode";
import { DatabaseKnowledge } from "../../../../models/DatabaseKnowledge";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import { WriteAdvancedSettings } from "./WriteAdvancedSettings";

interface WriteComponentProps {
  data: WriteDatabaseData;
  selectedDatabase: DatabaseKnowledge;
  availableVariables: string[];
  updateNode: (newData: Partial<WriteDatabaseData>) => void;
}

export const WriteComponent: React.FC<WriteComponentProps> = ({
  data,
  selectedDatabase,
  availableVariables,
  updateNode,
}) => {
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  return (
    <div className="space-y-6">
      {/* Record ID field */}

      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <div className="text-sm font-medium text-gray-700">Data Point ID</div>

          <CommonUnderlineButton
            title="Advanced settings"
            onClick={() => setShowAdvancedSettings(true)}
          />
        </div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.recordId ?? { type: "text", value: "" }}
          setSelectedInput={(input) => updateNode({ recordId: input })}
          currentNodeId={data.id}
          id="recordId"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Optionally specify a Data Point, or leave blank to auto-generate
        </div>
      </div>

      {/* Content field */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Record Content</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.content}
          setSelectedInput={(input) => updateNode({ content: input })}
          currentNodeId={data.id}
          id="content"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          The content that will be encoded and stored in the vector database
        </div>
      </div>

      {/* Async toggle */}
      <div className="flex items-center gap-2">
        <label className="flex items-center gap-2 text-sm text-gray-600">
          <input
            type="checkbox"
            checked={data.isAsync ?? false}
            onChange={(e) => updateNode({ isAsync: e.target.checked })}
            className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          />
          Run asynchronously
        </label>
        <label className="flex items-center gap-2 text-sm text-gray-600">
          <input
            type="checkbox"
            checked={data.appendContent ?? false}
            onChange={(e) => updateNode({ appendContent: e.target.checked })}
            className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          />
          Append content
        </label>
      </div>

      {/* Metadata fields */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Metadata Fields</div>
        <div className="p-3 bg-gray-50 rounded-md border border-gray-200">
          <div className="flex flex-col gap-4">
            {selectedDatabase.metadataFields.map((field, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="text-sm font-medium text-gray-700">
                  {field.name}
                </div>
                <VariableInputSelector
                  availableVariables={availableVariables}
                  selectedInput={
                    data.metadata?.[field.name] ?? { type: "text", value: "" }
                  }
                  setSelectedInput={(input) => {
                    const newFields = {
                      ...data.metadata,
                      [field.name]: input,
                    };
                    updateNode({ metadata: newFields });
                  }}
                  currentNodeId={data.id}
                  id={`field_${field.name}`}
                  includeMessages={true}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="text-xs text-gray-500">
          Additional fields to store alongside the vector-encoded content
        </div>
      </div>
      <WriteAdvancedSettings
        isOpen={showAdvancedSettings}
        onClose={() => setShowAdvancedSettings(false)}
        data={data}
        updateNode={updateNode}
      />
    </div>
  );
};
