import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Portal } from "../../models/Portal";
import { usePortalService } from "../../contexts/PortalContext";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import CommonContainer from "../Common/CommonContainer";
import { CommonSpinner } from "../Common/CommonLoading";
import { StatusBadge } from "./StatusBadge";
import { AppPath } from "../../models/AppPath";
import { useTeams } from "../../contexts/TeamContext";
import { CommonNoData } from "../Common/CommonNoData";
import { filterExamplePortals } from "../../utils/FilterUtils";
import { useAnalytics } from "../../contexts/AnalyticsContext";

interface PortalsProps {}

const Portals: React.FC<PortalsProps> = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();
  const portalService = usePortalService();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const [portals, setPortals] = useState<Portal[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [createPortalAnimationState, setCreatePortalAnimationState] =
    useState<AnimationState>("ready");

  useEffect(() => {
    const loadData = async () => {
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );

      const fetchedPortals = await portalService.portalRepo.getList(
        portalService.portalPath(teamId!),
        { name: "modifiedAt", descending: true },
        undefined
      );
      setPortals(
        fetchedPortals.filter((p) => filterExamplePortals(team ?? undefined, p))
      );
      setLoading(false);
    };
    loadData();
  }, [teamId, portalService]);

  const handleRowClick = (portal: Portal) => {
    navigate(`${portal.id}/version`);
  };

  const handleCreateNew = async () => {
    setCreatePortalAnimationState("loading");
    setError(null); // Clear any previous errors
    try {
      const result = await portalService.createPortal(teamId!);
      analytics.track("createdPrompt");
      navigate(
        AppPath.portalVersion(
          teamId!,
          result.portal.id,
          result.version.id!,
          true
        )
      );
      setCreatePortalAnimationState("success");
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create prompt. Please try again."
      );
      setCreatePortalAnimationState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="Prompts"
        subtitle="Manage prompts, versions, and AI providers. Publish straight to your app, no code needed."
        sections={[{ name: "Prompts", link: "" }]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Create new Prompt"
            onClick={handleCreateNew}
            buttonState={createPortalAnimationState}
            setButtonState={setCreatePortalAnimationState}
            style="action"
            key="saveChanges"
            error={error}
            setError={setError}
          />,
        ]}
      />
      <div>
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <thead className="border border-gray-200">
            <tr>
              <Header title="Name" />
              <Header title="Edited" />
              <Header title="Score" />
              <Header title="Status" />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="bg-gray-0 hover:bg-gray-100">
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
              </tr>
            ) : (
              portals.map((portal) => (
                <tr
                  className="bg-gray-0 hover:bg-gray-100"
                  onClick={() => handleRowClick(portal)}
                  key={portal.id}
                >
                  <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                    {portal.name}
                  </td>
                  <td className="border-b p-3 text-sm text-gray-500 font-medium">
                    {new Date(portal.modifiedAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    {portal.currentVersionData?.averageReviewScore ?? "..."}
                  </td>
                  <td className="border-b p-3 border-r">
                    {portal.currentVersionData && (
                      <div className="flex items-center">
                        <StatusBadge
                          portal={portal}
                          version={portal.currentVersionData}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {portals.length == 0 && loading == false && (
        <CommonNoData teamId={teamId ?? ""} />
      )}
    </CommonContainer>
  );
};

export default Portals;

const Header: React.FC<{ title: string }> = ({ title }) => {
  return (
    <th className="p-3 bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest">
      {title}
    </th>
  );
};
