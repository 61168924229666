import React, { useState, useEffect } from "react";
import { Workflow } from "../../models/Workflow";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { CopyWorkflowModal } from "./CopyWorlflowModal";

interface DemoDataSectionProps {
  teamId: string;
}

export const WorkflowExampleSection: React.FC<DemoDataSectionProps> = ({
  teamId,
}) => {
  const workflowService = useWorkflowService();

  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [exampleWorkflows, setExampleWorkflows] = useState<Workflow[]>([]);

  const loadExampleWorkflows = async () => {
    const fetchedExampleWorkflows = await workflowService.workflowRepo.getList(
      workflowService.exampleWorkflowPath(),
      {
        name: "modifiedAt",
        descending: true,
      }
    );
    setExampleWorkflows(fetchedExampleWorkflows);
  };

  useEffect(() => {
    const loadData = async () => {
      const [fetchedWorkflows, fetchedExampleWorkflows] = await Promise.all([
        workflowService.workflowRepo.getList(
          workflowService.workflowPath(teamId),
          {
            name: "modifiedAt",
            descending: true,
          }
        ),
        workflowService.workflowRepo.getList(
          workflowService.exampleWorkflowPath(),
          {
            name: "modifiedAt",
            descending: true,
          }
        ),
      ]);
      setWorkflows(fetchedWorkflows);
      setExampleWorkflows(fetchedExampleWorkflows);
    };
    loadData();
  }, [workflowService, teamId]);

  const handleRowClick = (workflow: Workflow) => {
    console.log("clicked", workflow.id);
  };

  const handleCopyToExamples = async (workflow: Workflow) => {
    try {
      await workflowService.copyWorkflowToExamples(teamId, workflow.id!);
      await loadExampleWorkflows();
    } catch (error) {
      console.error("Error copying workflow to examples:", error);
    }
  };

  const handleDeleteExampleWorkflow = async (workflowId: string) => {
    try {
      await workflowService.deleteWorkflowExample(workflowId);
      setExampleWorkflows(exampleWorkflows.filter((w) => w.id !== workflowId));
    } catch (error) {
      console.error("Error deleting example workflow:", error);
    }
  };

  const handleCopyToProd = async (workflowId: string) => {
    try {
      await workflowService.copyExampleToProd(workflowId);
    } catch (error) {
      console.error("Error copying example workflow to prod:", error);
    }
  };

  // New function to toggle isBestExample flag
  const handleToggleBestExample = async (workflow: Workflow) => {
    try {
      const updatedWorkflow = {
        ...workflow,
        isBestExample: !workflow.isBestExample,
      };

      await workflowService.workflowRepo.update(
        updatedWorkflow,
        workflowService.exampleWorkflowPath(),
        workflow.id!
      );

      // Update the local state
      setExampleWorkflows(
        exampleWorkflows.map((w) =>
          w.id === workflow.id ? updatedWorkflow : w
        )
      );
    } catch (error) {
      console.error("Error updating best example status:", error);
    }
  };

  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null
  );
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const handleCopyToTeam = async (toTeam: string) => {
    if (!selectedWorkflow?.id) return;
    try {
      await workflowService.copyAgent(selectedWorkflow.id, teamId, toTeam);
      // Optionally refresh the workflows list here if needed
    } catch (error) {
      console.error("Error copying workflow to team:", error);
    }
  };

  return (
    <>
      <CopyWorkflowModal
        isOpen={isCopyModalOpen}
        onDismiss={() => {
          setIsCopyModalOpen(false);
          setSelectedWorkflow(null);
        }}
        onSubmit={handleCopyToTeam}
        workflowName={selectedWorkflow?.name || ""}
      />
      <h2 className="text-xl font-bold mb-4">Example Workflows</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Best Example
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {exampleWorkflows.map((workflow) => (
            <tr
              key={workflow.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(workflow)}
            >
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <span>{workflow.name}</span>
                  {workflow.isBestExample && (
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      Best
                    </span>
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleBestExample(workflow);
                  }}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                    workflow.isBestExample ? "bg-indigo-600" : "bg-gray-200"
                  }`}
                >
                  <span className="sr-only">
                    {workflow.isBestExample
                      ? "Remove from best examples"
                      : "Mark as best example"}
                  </span>
                  <span
                    className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      workflow.isBestExample ? "translate-x-5" : "translate-x-0"
                    }`}
                  ></span>
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex space-x-4">
                  <button
                    className="text-blue-600 hover:text-blue-900 focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToProd(workflow.id!);
                    }}
                  >
                    Copy to prod
                  </button>
                  <button
                    className="text-red-600 hover:text-red-900 focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteExampleWorkflow(workflow.id!);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="text-xl font-bold mb-4 mt-8">Workflows</h2>
      <table className="min-w-full divide-y divide-gray-200 mb-8">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {workflows.map((workflow) => (
            <tr
              key={workflow.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(workflow)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{workflow.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex space-x-4">
                  <button
                    className="text-blue-600 hover:text-blue-900 focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToExamples(workflow);
                    }}
                  >
                    Copy to Examples
                  </button>
                  <button
                    className="text-blue-600 hover:text-blue-900 focus:outline-none ml-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedWorkflow(workflow);
                      setIsCopyModalOpen(true);
                    }}
                  >
                    Copy to Team
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
