import { WorkflowSwitchOperator } from "../../../../models/Workflow";

export const OperatorSelector: React.FC<{
  currentOperator: WorkflowSwitchOperator;
  selectedOperator: (operator: WorkflowSwitchOperator) => void;
}> = ({ currentOperator, selectedOperator }) => {
  const operators: WorkflowSwitchOperator[] = [
    "contains",
    "!contains",
    "==",
    "!=",
    "<",
    ">",
    "empty",
    "!empty",
  ];
  const operatorTitle = (operator: WorkflowSwitchOperator): string => {
    switch (operator) {
      case "contains":
        return "contains";
      case "!contains":
        return "doesn't contain";
      case "empty":
        return "is empty";
      case "!empty":
        return "isn't empty";
      case "==":
        return "is";
      case "!=":
        return "is not";
      case "<":
        return "less than";
      case ">":
        return "greater than";
    }
  };
  const isSelected = (operator: WorkflowSwitchOperator): boolean => {
    return currentOperator == operator;
  };
  return (
    <div className="grid grid-cols-2 gap-2">
      {operators.map((operator) => {
        return (
          <button
            key={operator}
            onClick={() => selectedOperator(operator)}
            className={`${
              isSelected(operator)
                ? "bg-blue-50 text-blue-500"
                : "bg-gray-50 text-gray-500 hover:bg-blue-50 hover:text-blue-500"
            } rounded-lg text-sm text-center h-10 font-medium`}
          >
            {operatorTitle(operator)}
          </button>
        );
      })}
    </div>
  );
};
