import { ReactNode, useEffect, useState } from "react";
import {
  Workflow,
  WorkflowVersion,
  WorkflowVersionPreview,
} from "../../../models/Workflow";
import { SelectableNodeData } from "../Map/WorkflowMap";
import { Team } from "../../../models/Team";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import { StartNodeType } from "../Map/Nodes/StartNode";
import { PortalNodeType } from "../Map/Nodes/PortalNode";
import { DecisionNodeType } from "../Map/Nodes/DecisionNode";
import { WebsiteNodeType } from "../Map/Nodes/WebsiteNode";
import { SearchNodeType } from "../Map/Nodes/SearchNode";
import { WorkflowNodeType } from "../Map/Nodes/WorkflowNode";
import { APINodeType } from "../Map/Nodes/APINode";
import { ActionNodeType } from "../Map/Nodes/ActionNode";
import { SidebarDecisionDetail } from "./Decision/SidebarDecisionDetail";
import { SidebarWebsiteDetail } from "./Website/SidebarWebsiteDetail";
import { SidebarSearchDetail } from "./Detail/SidebarSearchDetail";
import { SidebarWorkflowDetail } from "./Detail/SidebarWorkflowDetail";
import { SidebarPortalContainer } from "./Portal/SidebarPortalContainer";
import { SidebarAPIContainer } from "./API/SidebarAPIContainer";
import { SidebarWorkflowMetaContainer } from "./Meta/SidebarWorkflowMetaContainer";
import { SidebarActionContainer } from "./Action/SidebarActionContainer";
import { SidebarHeader } from "./SidebarHeader";
import { WorkflowConversionProblem } from "../../../services/WorkflowConverter";
import { useReactFlow } from "reactflow";
import ProblemList from "./Problems/ProblemList";
import { KnowledgeNodeType } from "../Map/Nodes/KnowledgeNode";
import { SidebarKnowledgeDetail } from "./Knowledge/SidebarKnowledgeDetail";
import { DatabaseNodeType } from "../Map/Nodes/DatabaseNode";
import { SidebarDatabaseDetail } from "./Database/SidebarDatabase";

export const Sidebar: React.FC<{
  workflow: Workflow;
  setWorkflow: (workflow: Workflow) => void;
  workflowVersion: WorkflowVersion;
  setWorkflowVersion: (workflow: WorkflowVersion) => void;
  team: Team;
  setTeam: (team: Team) => void;
  selectedNodeData: SelectableNodeData | undefined;
  templates: ChatConfigurationTemplate[];
  workflows: Workflow[];
  previewVersion: WorkflowVersionPreview;
  setPreviewVersion: (workflow: WorkflowVersionPreview) => void;
}> = ({
  workflow,
  team,
  setWorkflow,
  setTeam,
  selectedNodeData,
  templates,
  workflows,
  workflowVersion,
  setWorkflowVersion,
  previewVersion,
  setPreviewVersion,
}) => {
  const [visibleContent, setVisibleContent] = useState<
    SelectableNodeData | undefined
  >(selectedNodeData);
  const [problems, setProblems] = useState<WorkflowConversionProblem[]>([]);

  const { setNodes } = useReactFlow();

  const updateNodes = (
    updates: { [id: string]: { data: any; general: any } },
    generalUpdates?: any
  ) => {
    const ids = Object.keys(updates);
    setNodes((nds) =>
      nds.map((node) => {
        if (ids.includes(node.id)) {
          return {
            ...node,
            data: { ...node.data, ...updates[node.id].data },
            ...updates[node.id].general,
          };
        } else {
          return { ...node, ...generalUpdates };
        }
      })
    );
  };

  // Update visible content when selection changes, but wait for animation if hiding
  useEffect(() => {
    if (selectedNodeData) {
      setVisibleContent(selectedNodeData);
    } else {
      // Wait for the slide animation to complete before clearing content
      const timer = setTimeout(() => {
        setVisibleContent(undefined);
      }, 300); // Match this to your transition duration
      return () => clearTimeout(timer);
    }
  }, [selectedNodeData]);

  const currentSection = (): ReactNode => {
    if (!visibleContent) return null;

    switch (visibleContent.type) {
      case StartNodeType:
        return (
          <SidebarWorkflowMetaContainer
            version={workflowVersion}
            setVersion={setWorkflowVersion}
            team={team}
            setTeam={setTeam}
            data={visibleContent}
            previewVersion={previewVersion}
            setPreviewVersion={setPreviewVersion}
          />
        );
      case PortalNodeType:
        return (
          <SidebarPortalContainer
            workflow={workflow}
            team={team}
            data={visibleContent}
            templates={templates}
            setTeam={setTeam}
            version={workflowVersion}
          />
        );
      case DecisionNodeType:
        return (
          <SidebarDecisionDetail
            data={visibleContent}
            version={workflowVersion}
          />
        );
      case WebsiteNodeType:
        return (
          <SidebarWebsiteDetail
            team={team}
            version={workflowVersion}
            data={visibleContent}
          />
        );
      case SearchNodeType:
        return <SidebarSearchDetail data={visibleContent} />;
      case WorkflowNodeType:
        return (
          <SidebarWorkflowDetail
            data={visibleContent}
            workflow={workflow}
            workflows={workflows}
            team={team}
          />
        );
      case APINodeType:
        return (
          <SidebarAPIContainer
            data={visibleContent}
            team={team}
            version={workflowVersion}
          />
        );
      case ActionNodeType:
        return (
          <SidebarActionContainer
            data={visibleContent}
            team={team}
            version={workflowVersion}
            templates={templates}
          />
        );
      case KnowledgeNodeType:
        return (
          <SidebarKnowledgeDetail
            data={visibleContent}
            team={team}
            version={workflowVersion}
          />
        );
      case DatabaseNodeType:
        return (
          <SidebarDatabaseDetail
            data={visibleContent}
            team={team}
            version={workflowVersion}
          />
        );
      default:
        return null;
    }
  };

  const collapse = selectedNodeData === undefined;

  return (
    <>
      <div className="absolute top-2 right-2 h-30 w-[454px]">
        <SidebarHeader
          workflow={workflow}
          workflowVersion={workflowVersion}
          team={team}
          setWorkflow={setWorkflow}
          setWorkflowVersion={setWorkflowVersion}
          onProblemsChange={setProblems}
        />
      </div>
      <div
        className={`
        absolute top-32 right-2 bottom-2 gap-2 flex flex-col
        ${collapse ? "pointer-events-none" : "pointer-events-auto"}
      `}
      >
        <div
          className={`
          w-[454px] h-full rounded-lg bg-gray-0 border-gray-200 border
          flex flex-col gap-4 p-4 transition-all overflow-y-scroll
          transform duration-300 ease-in-out
          ${collapse ? "translate-x-full" : "translate-x-0"}
        `}
        >
          {currentSection()}
        </div>
      </div>
      {/* Problem List */}
      <div className="absolute w-96 h-fit left-0 top-0">
        <ProblemList
          problems={problems}
          onDismiss={(problem) =>
            setProblems([...problems.filter((p) => p.id !== problem.id)])
          }
          clearAll={() => {
            setProblems([]);
          }}
          onTap={(problem) => {
            setProblems([...problems.filter((p) => p.id !== problem.id)]);
            updateNodes(
              {
                [problem.nodeId]: {
                  data: { hasProblem: false },
                  general: { selected: true },
                },
              },
              { selected: false }
            );
          }}
        />
      </div>
    </>
  );
};
