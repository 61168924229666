import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTeams } from "../../contexts/TeamContext";
import VerticalNavLayout from "./VerticalNavLayout";
import { Outlet } from "react-router-dom";
import { Team } from "../../models/Team";
import TeamLoading from "../TeamLoading";
import { useAuth } from "../../contexts/AuthContext";
import { TeamAlert } from "../../models/TeamAlert";
import { User } from "../../models/User";
import { TeamData } from "../../services/TeamService";
import { TeamSubscription } from "../../models/TeamSubscription";
import CatchAll from "../CatchAll";
import SubscriptionBlockedModal from "./SubscriptionBlocker";
import { useAnalytics } from "../../contexts/AnalyticsContext";

interface TeamWrapperProps {
  currentUser: User;
}

const TeamWrapper: React.FC<TeamWrapperProps> = ({ currentUser }) => {
  const { teamId } = useParams<{ teamId: string }>();
  const teamService = useTeams();
  const authService = useAuth();
  const analytics = useAnalytics();

  const [isError, setIsError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [team, setTeam] = useState<Team | undefined>();
  const [subscription, setSubscription] = useState<
    TeamSubscription | undefined
  >();
  const [alerts, setAlerts] = useState<TeamAlert[]>([]);
  const [featureFlags, _] = useState<string[]>([]);

  const isLoading =
    featureFlags == undefined || team == undefined || subscription == undefined;

  useEffect(() => {
    const loadData = async () => {
      try {
        const [teamData, isAdmin] = await Promise.all([
          teamService.fetchCurrentTeamData(teamId!),
          authService.isAdmin(),
        ]);
        setTeam(teamData.team);
        setSubscription(teamData.sub ?? undefined);
        setIsAdmin(isAdmin);

        if (teamData.sub) {
          const monthlyPayment = teamData.sub.currentEvent?.priceUSD ?? 0;
          analytics.group("team", teamId!, {
            subData: teamData.sub,
            teamData: teamData.team,
            name: teamData.team.name,
            subscription: teamData.sub.currentEvent?.name,
            createdAt: teamData.team.createdAt.toString(),
            trialActive: teamData.sub?.currentEvent?.trialEnd
              ? new Date(teamData.sub.currentEvent.trialEnd) > new Date()
              : false,
            monthlyPayment:
              teamData.sub.currentEvent?.interval == "month"
                ? monthlyPayment
                : monthlyPayment / 12,
          });
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const unsubscribe = authService.alertRepo.observeList(
      authService.alertsPath(authService.userId!),
      (fetched) => {
        setAlerts(alerts == undefined ? [] : fetched);
      },
      { name: "createdAt", descending: true }
    );
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    teamService.currentTeamDataChanged = (teamData: TeamData | undefined) => {
      if (!teamData) {
        return;
      }
      setTeam(teamData.team);
    };
  }, []);

  if (isLoading) return <TeamLoading />;
  if (isError) {
    return <CatchAll />;
  }

  return (
    <VerticalNavLayout
      teamSubscription={subscription}
      team={team!}
      alerts={alerts}
      currentUser={currentUser}
      featureFlags={featureFlags}
    >
      <Outlet />
      <SubscriptionBlockedModal
        teamId={teamId ?? ""}
        subscription={subscription}
        team={team}
        isAdmin={isAdmin}
      />
    </VerticalNavLayout>
  );
};

export default TeamWrapper;
