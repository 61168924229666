import { FC, useState } from "react";
import { Team } from "../../../../models/Team";
import { KnowledgeNodeData } from "../../Map/Nodes/KnowledgeNode";
import { CommonModal } from "../../../Common/CommonModal";
import { CommonInputSegment } from "../../../Common/CommonInput";
import { useKnowledgeService } from "../../../../contexts/KnowledgeContext";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface KnowledgeTestModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  data: KnowledgeNodeData;
  team: Team;
  selectedWebsiteNames: string[];
}

export const KnowledgeTestModal: FC<KnowledgeTestModalProps> = ({
  shows,
  data,
  setShows,
  team,
  selectedWebsiteNames,
}) => {
  const [query, setQuery] = useState("te");
  const [response, setResponse] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);

  const knowledgeService = useKnowledgeService();

  const testKnowledge = async () => {
    setButtonState("loading");
    setError(null);
    setResponse(null);

    try {
      const response = await knowledgeService.previewKnowledge(
        team.id!,
        query,
        {
          resultCount: data.resultCount,
          similarityThreshold: data.similarityThreshold,
          contextWindow: data.contextWindow,
        },
        data.websiteSources,
        data.documentSources ?? []
      );

      setResponse(response || "Nothing matched!");
      setButtonState("success");
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to process knowledge query. Please try again."
      );
      setButtonState("error");
    }
  };

  return (
    <CommonModal
      isOpen={shows}
      onDismiss={() => setShows(false)}
      title={`Preview: ${data.title}`}
    >
      <div className="space-y-6">
        <CommonInputSegment
          title="Query"
          subtitle="Enter your search query to test against the knowledge base"
          value={query}
          onChange={setQuery}
          placeholder="Enter your query"
        />

        <div className="bg-gray-50 p-4 rounded-md border border-gray-200">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Query Settings
          </h3>
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Result Count:</span>
              <span className="font-mono">{data.resultCount}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">
                Similarity Threshold:
              </span>
              <span className="font-mono">
                {(data.similarityThreshold * 100).toFixed(1)}%
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Context Window:</span>
              <span className="font-mono">{data.contextWindow}</span>
            </div>
          </div>
        </div>

        {selectedWebsiteNames.length > 0 && (
          <div className="bg-gray-50 p-4 rounded-md border border-gray-200">
            <h3 className="text-sm font-medium text-gray-700 mb-3">
              Selected Sources
            </h3>
            <div className="flex flex-wrap gap-2">
              {selectedWebsiteNames.map((name, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-white text-sm text-gray-600 rounded-md border border-gray-200"
                >
                  {name}
                </span>
              ))}
            </div>
          </div>
        )}

        {error && (
          <div className="p-4 bg-red-50 text-red-500 rounded-md border border-red-200">
            <p className="font-medium">Error</p>
            <p className="mt-1">{error}</p>
          </div>
        )}

        {response && (
          <div className="flex flex-col gap-2">
            <p className="font-medium text-gray-700">Results:</p>
            <div className="p-4 bg-gray-50 rounded-md border border-gray-200 font-mono text-sm overflow-auto max-h-[600px] whitespace-pre-wrap">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="prose text-sm text-slate-900"
              >
                {response}
              </ReactMarkdown>
            </div>
          </div>
        )}
      </div>

      <div className="flex-none px-6 py-4 border-t border-gray-200">
        <AnimatedButton
          title="Run Query"
          onClick={testKnowledge}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          font="font-sans"
          classNameIn="w-full"
          after={(success) => {
            if (!success) {
              setButtonState("ready");
            }
          }}
        />
      </div>
    </CommonModal>
  );
};
