import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { WebsiteFilterOptions } from "../../models/Workflow";
import { CrawlOptions, RecursionScope } from "../../models/WebsiteKnowledge";
import CommonSwitch from "../Common/CommonSwitch";

export const ENCODING_STRATEGIES = {
  precise: {
    chunkSize: 256,
    chunkOverlap: 64,
    label: "Precise",
    description:
      "Best for technical documentation and code. Uses smaller chunks for accurate retrieval.",
  },
  balanced: {
    chunkSize: 512,
    chunkOverlap: 128,
    label: "Balanced",
    description:
      "Good for general content like articles and blog posts. Maintains a balance between context and precision.",
  },
  contextHeavy: {
    chunkSize: 1024,
    chunkOverlap: 256,
    label: "Context-Heavy",
    description:
      "Ideal for narrative content and complex explanations. Preserves more surrounding context.",
  },
} as const;

type StrategyKey = keyof typeof ENCODING_STRATEGIES;

interface AddWebsiteModalProps {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface StrategyButtonProps {
  strategyKey: StrategyKey;
  strategy: (typeof ENCODING_STRATEGIES)[StrategyKey];
  isSelected: boolean;
  onClick: () => void;
}

export const StrategyButton: React.FC<StrategyButtonProps> = ({
  strategy,
  isSelected,
  onClick,
}) => (
  <button
    id={`${strategy.label}StrategyButton`}
    onClick={onClick}
    className={`flex flex-col p-4 rounded-lg border-2 transition-all w-full
      ${
        isSelected
          ? "border-blue-500 bg-blue-50"
          : "border-gray-200 hover:border-gray-300 bg-white"
      }`}
  >
    <span className="font-medium text-left mb-1">{strategy.label}</span>
    <span className="text-sm text-gray-600 text-left">
      {strategy.description}
    </span>
  </button>
);

// Scope selector component
interface ScopeButtonProps {
  scope: RecursionScope;
  label: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
}

export const ScopeButton: React.FC<ScopeButtonProps> = ({
  label,
  description,
  isSelected,
  onClick,
}) => (
  <button
    id={`${label}Button`}
    onClick={onClick}
    className={`flex flex-col p-4 rounded-lg border-2 transition-all w-full
      ${
        isSelected
          ? "border-blue-500 bg-blue-50"
          : "border-gray-200 hover:border-gray-300 bg-white"
      }`}
  >
    <span className="font-medium text-left mb-1">{label}</span>
    <span className="text-sm text-gray-600 text-left">{description}</span>
  </button>
);

export const AddWebsiteModal: React.FC<AddWebsiteModalProps> = ({
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [url, setUrl] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [selectedStrategy, setSelectedStrategy] =
    useState<StrategyKey>("balanced");

  // Crawl options state
  const [crawlOptions, setCrawlOptions] = useState<CrawlOptions>({
    enabled: false,
    maxDepth: 1,
    scope: RecursionScope.SAME_DOMAIN,
    urlPattern: null,
    scrollInterval: null,
    maxChildren: 100,
    useJS: false,
  });

  // Handlers for updating crawl options
  const toggleCrawlEnabled = (enabled: boolean) => {
    setCrawlOptions({
      ...crawlOptions,
      enabled,
    });
  };

  const toggleUseJS = (enabled: boolean) => {
    // When disabling JavaScript, clear the scroll interval
    if (!enabled && crawlOptions.scrollInterval !== null) {
      setCrawlOptions({
        ...crawlOptions,
        useJS: enabled,
        scrollInterval: null,
      });
    } else {
      setCrawlOptions({
        ...crawlOptions,
        useJS: enabled,
      });
    }
  };

  const updateMaxDepth = (depth: number) => {
    setCrawlOptions({
      ...crawlOptions,
      maxDepth: Math.max(1, Math.min(2, depth)), // Limit between 1-2
    });
  };

  const updateScope = (scope: RecursionScope) => {
    setCrawlOptions({
      ...crawlOptions,
      scope,
    });
  };

  const updateUrlPattern = (pattern: string) => {
    setCrawlOptions({
      ...crawlOptions,
      urlPattern: pattern.trim() === "" ? null : pattern.trim(),
    });
  };

  const updateScrollInterval = (interval: string) => {
    if (interval === "") {
      setCrawlOptions({
        ...crawlOptions,
        scrollInterval: null,
      });
      return;
    }

    // Parse the input value
    let parsedInterval = parseFloat(interval);

    // Round to the nearest multiple of 5
    parsedInterval = Math.round(parsedInterval / 5) * 5;

    // Ensure it's at least 5 if provided
    parsedInterval = Math.max(5, parsedInterval);

    setCrawlOptions({
      ...crawlOptions,
      scrollInterval: parsedInterval,
    });
  };

  const updateMaxChildren = (childrenCount: number) => {
    setCrawlOptions({
      ...crawlOptions,
      maxChildren: Math.max(10, childrenCount), // Ensure minimum of 10 children
    });
  };

  const handleSubmit = async () => {
    if (!url.trim()) {
      setError("Please enter a valid URL");
      return;
    }

    setButtonState("loading");
    try {
      const filterOptions: WebsiteFilterOptions = {
        removeScripts: true,
        removeStyles: true,
        removeNavigation: true,
        removeFooter: true,
        removeAds: true,
        keepMainContent: true,
        customSelectors: [],
      };

      const { chunkSize, chunkOverlap, label } =
        ENCODING_STRATEGIES[selectedStrategy];

      await knowledgeService.addWebsiteKnowledge(teamId, {
        url: url.trim(),
        filterOptions,
        encoding: {
          chunkSize,
          chunkOverlap,
          name: label,
        },
        crawlOptions, // Add crawl options to the request
      });

      setButtonState("ready");
      onSuccess();
      onClose();
    } catch (e) {
      setError(e instanceof Error ? e.message : "Failed to add website");
      setButtonState("error");
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose} title="Add Website">
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Website URL
          </label>
          <input
            type="url"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            placeholder="https://example.com"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setError(null);
            }}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Encoding Strategy
          </label>
          <div className="grid grid-cols-3 gap-4">
            {(
              Object.entries(ENCODING_STRATEGIES) as [
                StrategyKey,
                (typeof ENCODING_STRATEGIES)[StrategyKey]
              ][]
            ).map(([key, strategy]) => (
              <StrategyButton
                key={key}
                strategyKey={key}
                strategy={strategy}
                isSelected={selectedStrategy === key}
                onClick={() => setSelectedStrategy(key)}
              />
            ))}
          </div>
        </div>

        {/* Crawl Options Section */}
        <div className="border-t pt-4">
          <div className="flex items-center justify-between mb-4">
            <label className="text-sm font-medium text-gray-700">
              Website Crawler (Beta)
            </label>
            <CommonSwitch
              isOn={crawlOptions.enabled}
              onToggle={toggleCrawlEnabled}
              ariaLabel="Enable website crawler"
            />
          </div>

          {crawlOptions.enabled && (
            <div className="space-y-4 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Crawl Depth (1-2 while in beta)
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                    value={crawlOptions.maxDepth}
                    onChange={(e) => updateMaxDepth(parseFloat(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <span className="ml-2 text-gray-700 font-medium">
                    {crawlOptions.maxDepth}
                  </span>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Higher values will crawl more pages but take longer
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Maximum Pages to Crawl
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    min="10"
                    max="500"
                    step="10"
                    value={crawlOptions.maxChildren}
                    onChange={(e) =>
                      updateMaxChildren(parseInt(e.target.value))
                    }
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <span className="ml-2 text-gray-700 font-medium">
                    {crawlOptions.maxChildren}
                  </span>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Limits the total number of pages that will be crawled
                </p>
              </div>
              {/* JavaScript Rendering Option */}
              <div className="flex items-center justify-between mb-4">
                <div>
                  <label className="text-sm font-medium text-gray-700">
                    Use JavaScript
                  </label>
                  <p className="text-xs text-gray-500">
                    Enable to render JavaScript on crawled pages (improves
                    results for dynamic content)
                  </p>
                </div>
                <CommonSwitch
                  isOn={crawlOptions.useJS}
                  onToggle={toggleUseJS}
                  ariaLabel="Enable JavaScript rendering"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Crawl Scope
                </label>
                <div className="grid grid-cols-3 gap-4">
                  <ScopeButton
                    scope={RecursionScope.SAME_DOMAIN}
                    label="Same Domain"
                    description="Only follow links on the same domain (e.g., example.com)"
                    isSelected={
                      crawlOptions.scope === RecursionScope.SAME_DOMAIN
                    }
                    onClick={() => updateScope(RecursionScope.SAME_DOMAIN)}
                  />
                  <ScopeButton
                    scope={RecursionScope.SAME_ROOT_DOMAIN}
                    label="Same Root Domain"
                    description="Follow links on the same root domain (e.g., *.example.com)"
                    isSelected={
                      crawlOptions.scope === RecursionScope.SAME_ROOT_DOMAIN
                    }
                    onClick={() => updateScope(RecursionScope.SAME_ROOT_DOMAIN)}
                  />
                  <ScopeButton
                    scope={RecursionScope.ALL}
                    label="All Links"
                    description="Follow all links (use with caution)"
                    isSelected={crawlOptions.scope === RecursionScope.ALL}
                    onClick={() => updateScope(RecursionScope.ALL)}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  URL Pattern (Optional)
                </label>
                <input
                  id="urlPatternInput"
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Example: .*/docs/.*"
                  value={crawlOptions.urlPattern || ""}
                  onChange={(e) => updateUrlPattern(e.target.value)}
                />
                <p className="text-xs text-gray-500 mt-1">
                  Regular expression to match URLs for following (leave empty to
                  follow all links within scope)
                </p>
              </div>
              <div>
                <label
                  className={`block text-sm font-medium ${
                    crawlOptions.useJS ? "text-gray-700" : "text-gray-400"
                  } mb-2`}
                >
                  Scroll Interval (Optional, in seconds, requires JS to be
                  enabled)
                </label>
                <input
                  id="waitTimeInput"
                  type="number"
                  min="10"
                  step="5"
                  className={`w-full px-3 py-2 border ${
                    crawlOptions.useJS
                      ? "border-gray-300"
                      : "border-gray-200 bg-gray-100"
                  } rounded-md shadow-sm focus:outline-none ${
                    crawlOptions.useJS
                      ? "focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      : ""
                  }`}
                  placeholder="Example: 10"
                  value={crawlOptions.scrollInterval || ""}
                  onChange={(e) => updateScrollInterval(e.target.value)}
                  disabled={!crawlOptions.useJS}
                  onBlur={(e) => {
                    // Force update on blur to ensure display reflects actual value
                    if (e.target.value !== "") {
                      const value = crawlOptions.scrollInterval;
                      if (value !== null) {
                        e.target.value = value.toString();
                      }
                    }
                  }}
                />
                <p
                  className={`text-xs ${
                    crawlOptions.useJS ? "text-gray-500" : "text-gray-400"
                  } mt-1`}
                >
                  Time to wait while scrolling for dynamically loaded content
                  (leave empty for no scrolling, values are in multiples of 5
                  seconds)
                </p>
              </div>
            </div>
          )}
        </div>

        {error && <p className="text-sm text-red-600">{error}</p>}
      </div>

      <div className="flex justify-between gap-3 mt-6">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <AnimatedButton
          title="Add Website"
          onClick={handleSubmit}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          error={error}
          setError={setError}
          triggerKey="Enter"
          id="submitWebsiteButton"
        />
      </div>
    </CommonModal>
  );
};
