import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type KnowledgeSection = "websites" | "documents" | "databases";

interface Section {
  id: KnowledgeSection;
  title: string;
}

export const KnowledgeSections: React.FC<{
  selectedId: KnowledgeSection;
}> = ({ selectedId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sections: Section[] = [
    { id: "websites", title: "Websites" },
    { id: "documents", title: "Documents" },
  ];

  const handleNavigation = (sectionId: string) => {
    const newPath = location.pathname.replace(/[^/]*$/, sectionId);
    navigate(newPath);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {sections.map((section) => {
        const selected = section.id === selectedId;
        return (
          <button
            id={`${section.id}Button`}
            onClick={() => handleNavigation(section.id)}
            key={section.id}
            className={`${
              selected
                ? "bg-blue-50 border-blue-200 text-blue-600"
                : "bg-transparent border-gray-200 text-gray-500"
            } rounded-3xl border px-2.5 py-0.5 text-sm font-medium`}
          >
            {section.title}
          </button>
        );
      })}
    </div>
  );
};
