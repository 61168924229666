import React, { ReactNode } from "react";
import { AnalyticsService } from "../services/AnalyticsService";
import AnalyticsContext from "../contexts/AnalyticsContext";

interface AnalyticsProviderProps {
  analytics: AnalyticsService;
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  analytics,
  children,
}) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
