import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import AnimatedButton from "../AnimatedButton";
import { AppPath } from "../../models/AppPath";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { ManagePineconeDBsModal } from "./ManagePineconeDBsModal";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import FailureModal from "../FailureModal";

export const TeamSettingsVectorDBs: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamService = useTeams();

  const [showManageModal, setShowManageModal] = useState(false);
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");

  const observationRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    const setupObservation = async () => {
      try {
        observationRef.current = teamService.teamRepo.observe(
          teamService.teamPath(),
          teamId!,
          (fetchedTeam) => {
            setTeam(fetchedTeam);
          }
        );
      } catch (e) {
        setError("Failed to load team");
      }
    };

    setupObservation();

    return () => {
      if (observationRef.current) {
        observationRef.current();
      }
    };
  }, [teamId, teamService]);

  useEffect(() => {
    const showModal = searchParams.get("showModal");
    if (showModal === "true") {
      setShowManageModal(true);
    }
  }, [searchParams]);

  const handleManageClick = () => {
    setSearchParams({ showModal: "true" });
    setShowManageModal(true);
  };

  const handleModalClose = () => {
    setSearchParams({});
    setShowManageModal(false);
  };

  const isConfigured =
    team?.pineconeDBs && Object.keys(team.pineconeDBs).length > 0;

  return (
    <CommonContainer>
      <CommonHeader
        title="Vector Servers"
        subtitle="Configure your vector servers to be used by your datasets."
        sections={[
          { name: "Team Settings", link: AppPath.settings(teamId!) },
          { name: "Vector Database", link: AppPath.settings(teamId!) },
        ]}
        teamId={teamId!}
        actions={[]}
      />

      <FailureModal
        message={error}
        shows={error !== ""}
        closed={() => setError("")}
      />

      <TeamSettingsSections selectedId="vector-dbs" />

      <div className="mt-6">
        <div className="pl-8 pr-6 bg-white shadow h-28 rounded-lg flex">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center justify-between gap-10">
              <div className="flex items-center gap-3">
                <img
                  src="https://avatars.githubusercontent.com/u/54333248?s=200&v=4"
                  alt="Pinecone Logo"
                  className="w-8 h-8 rounded-lg"
                />
                <span className="text-xl font-medium text-gray-900">
                  Pinecone
                </span>
              </div>
              <div className="px-3 py-1 text-sm rounded-full bg-gray-100 text-gray-600">
                {isConfigured
                  ? `${Object.keys(team!.pineconeDBs).length} Server ${
                      Object.keys(team!.pineconeDBs).length == 1 ? "" : "s"
                    }`
                  : "Not Configured"}
              </div>
            </div>

            <AnimatedButton
              title="Manage"
              style="action"
              setButtonState={undefined}
              buttonState="ready"
              onClick={handleManageClick}
              classNameIn="w-48"
              leftIcon="share-nodes"
              font="font-sans"
              id="pineconeConfigButton"
            />
          </div>
        </div>
      </div>

      {team && (
        <ManagePineconeDBsModal
          shows={showManageModal}
          setShows={handleModalClose}
          team={team}
          teamId={teamId!}
        />
      )}
    </CommonContainer>
  );
};

export default TeamSettingsVectorDBs;
