import React, { useState } from "react";
import { Team } from "../../models/Team";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import Icon from "../Icon";
import { CommonModal } from "../Common/CommonModal";
import { AddPineconeDBModal } from "./AddPineconeDBModal";
import { useTeams } from "../../contexts/TeamContext";

interface ManagePineconeDBsModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  team: Team;
  teamId: string;
}

export const ManagePineconeDBsModal: React.FC<ManagePineconeDBsModalProps> = ({
  shows,
  setShows,
  team,
  teamId,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteState, setDeleteState] = useState<AnimationState>("ready");
  const teamService = useTeams();

  const handleDismiss = () => {
    setShows(false);
  };

  const handleDelete = async (dbId: string) => {
    setDeleteState("loading");
    try {
      const updatedDBs = { ...team.pineconeDBs };
      delete updatedDBs[dbId];

      await teamService.teamRepo.update(
        { pineconeDBs: updatedDBs },
        teamService.teamPath(),
        teamId
      );

      await teamService.removeSecret(teamId, dbId);

      setDeleteState("success");
    } catch (error) {
      console.error("Failed to delete database:", error);
      setDeleteState("error");
    }
  };

  if (!shows) return null;

  return (
    <>
      <CommonModal
        isOpen={shows}
        onDismiss={handleDismiss}
        title="Manage Pinecone Servers"
      >
        <div className="font-normal text-gray-500 mb-6">
          Configure and manage your Pinecone Vector Servers to connect to your
          Data Sets.
        </div>

        <div className="flex justify-end mb-4">
          <AnimatedButton
            title="Add Database"
            style="action"
            buttonState="ready"
            setButtonState={undefined}
            onClick={() => setShowAddModal(true)}
            leftIcon="plus"
            font="font-sans"
            id="addPineconeButton"
            classNameIn="w-40"
          />
        </div>

        <div className="border rounded-lg bg-white overflow-hidden mb-6">
          {Object.entries(team.pineconeDBs || {}).length > 0 ? (
            Object.entries(team.pineconeDBs).map(([id, db]) => (
              <div
                key={id}
                className="flex items-center justify-between p-4 border-b last:border-b-0"
              >
                <div className="flex items-center gap-4 min-w-0 flex-1 mr-4">
                  <Icon
                    type="database"
                    className="w-5 h-5 text-gray-500 flex-shrink-0"
                  />
                  <div className="flex flex-col min-w-0">
                    <span className="font-medium text-gray-900 truncate">
                      {db.name}
                    </span>
                    <span className="text-sm text-gray-500 truncate">
                      {db.baseURL}
                    </span>
                  </div>
                </div>
                <AnimatedButton
                  title="Delete"
                  style="destructive"
                  buttonState={deleteState}
                  setButtonState={setDeleteState}
                  onClick={() => handleDelete(id)}
                  leftIcon="trash"
                  font="font-sans"
                  id={`deletePinecone${id}Button`}
                  classNameIn="w-32 flex-shrink-0"
                />
              </div>
            ))
          ) : (
            <div className="p-6 text-center text-gray-500">
              No databases configured
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <AnimatedButton
            title="Close"
            onClick={handleDismiss}
            buttonState="ready"
            style="normal"
            font="font-sans"
            id="closeButton"
            classNameIn="w-32"
          />
        </div>
      </CommonModal>

      <AddPineconeDBModal
        shows={showAddModal}
        setShows={setShowAddModal}
        teamId={teamId}
      />
    </>
  );
};

export default ManagePineconeDBsModal;
