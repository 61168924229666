import React from "react";
import { WorkflowDemoResult } from "../../../../models/WorkflowDemoResult";
import { StartResultCard } from "./DemoResultCards/StartResultCard";
import { PortalResultCard } from "./DemoResultCards/PortalResultCard";
import { DecisionResultCard } from "./DemoResultCards/DecisionResultCard";
import { APIResultCard } from "./DemoResultCards/APIResultCard";
import { ActionResultCard } from "./DemoResultCards/ActionResultCard";
import { WebsiteResultCard } from "./DemoResultCards/WebsiteResultCard";
import { KnowledgeResultCard } from "./DemoResultCards/KnowledgeResultCard";
import { DatabaseResultCard } from "./DemoResultCards/DatabaseResultCard";

interface WorkflowResultCardProps {
  result: WorkflowDemoResult;
  teamId: string;
}

export const WorkflowResultCard: React.FC<WorkflowResultCardProps> = ({
  result,
  teamId,
}) => {
  if (result.type == "start") {
    return <StartResultCard key={result.id} result={result} />;
  } else if (result.type == "portal") {
    return <PortalResultCard result={result} key={result.id} />;
  } else if (result.type == "decision") {
    return <DecisionResultCard result={result} key={result.id} />;
  } else if (result.type == "api") {
    return <APIResultCard result={result} key={result.id} />;
  } else if (result.type == "action") {
    return <ActionResultCard result={result} key={result.id} />;
  } else if (result.type == "website") {
    return <WebsiteResultCard result={result} key={result.id} />;
  } else if (result.type == "knowledge") {
    return (
      <KnowledgeResultCard teamId={teamId} result={result} key={result.id} />
    );
  } else if (result.type == "database") {
    return (
      <DatabaseResultCard teamId={teamId} result={result} key={result.id} />
    );
  }
};
