import React, { useState, useEffect } from "react";
import FailureModal from "../FailureModal";
import TeamAddName from "./TeamAddName";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { InProgressTeam, User } from "../../models/User";
import { useUserEvent } from "../../contexts/UserEventContext";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { AppPath } from "../../models/AppPath";
import { TeamRole } from "../../models/Team";

export type EnteredMembers = { email: string; role: TeamRole }[];

export enum TeamOnboardingStep {
  Name,
}

const TeamOnboarding: React.FC = () => {
  const teamService = useTeams();
  const authService = useAuth();
  const events = useUserEvent();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [user, setUser] = useState<User>();

  const backAction = !user?.teams
    ? undefined
    : async () => {
        await authService.userRepo.update(
          { inProgressTeam: undefined },
          authService.userPath(),
          user.id
        );
        const lastTeamId = localStorage.getItem(
          STORAGE_KEYS.LAST_ACCESSED_TEAM
        );
        if (!lastTeamId) {
          navigate(AppPath.onboarding());
          return;
        }
        navigate(`/teams/${lastTeamId}/home`);
      };

  useEffect(() => {
    const loadData = async () => {
      const user = await authService.currentUser();
      setUser(user!);

      const inProgress = user?.inProgressTeam;
      if (inProgress) {
        setTeamId(inProgress.id ?? "");
        setTeamName(inProgress.name ?? "");
      }
    };
    loadData();
  }, [authService, teamService]);

  const processName = async (name: string) => {
    setTeamName(name);

    try {
      setIsLoading(true);
      let newTeamId = teamId;

      if (teamId) {
        await updateTeam();
      } else {
        const newTeam = await teamService.createTeam(teamName);
        await authService.userRepo.update(
          { currentTeam: newTeam.id! },
          authService.userPath(),
          authService.userId!
        );
        events.createEvent(authService, "TeamCreated");
        newTeamId = newTeam.id!;
        setTeamId(newTeamId);
      }

      // Skip subscription and members, complete the process
      await authService.refreshToken();

      const inProgressTeam: InProgressTeam = {
        id: newTeamId,
        name: teamName,
      };

      await authService.userRepo.update(
        { inProgressTeam },
        authService.userPath(),
        authService.userId!
      );

      // Navigate to the team home page since we're skipping the payment flow
      navigate(`/teams/${newTeamId}/home`);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went horribly wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateTeam = async () => {
    await teamService.teamRepo.update(
      { name: teamName },
      teamService.teamPath(),
      teamId
    );
  };

  return (
    <>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to onboarding"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <TeamAddName
        gotName={processName}
        progress={100}
        setStep={() => {}}
        name={teamName}
        setName={setTeamName}
        goBack={backAction}
        loading={isLoading}
      />
    </>
  );
};

export default TeamOnboarding;
