import React from "react";
import { PortalVersion } from "../../../../models/PortalVersion";
import { VersionDemoer } from "../../../Portals/Portal Detail/VersionDemor";
import { Team } from "../../../../models/Team";
import { CommonModal } from "../../../Common/CommonModal";

interface PortalDemoModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  version: PortalVersion;
  variables: { [id: string]: string };
  setVariables: (variables: { [id: string]: string }) => void;
  team: Team;
}

export const PortalDemoModal: React.FC<PortalDemoModalProps> = ({
  shows,
  version,
  team,
  variables,
  setVariables,
  setShows,
}) => {
  return (
    <CommonModal
      isOpen={shows}
      onDismiss={() => setShows(false)}
      title="Preview prompt"
    >
      <VersionDemoer
        data={{ version: version, type: "portal" }}
        teamId={team.id!}
        demoConfigVariables={variables}
        setDemoConfigVariables={setVariables}
        teamName={team.name}
      />
    </CommonModal>
  );
};
