import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";
import { CommonInputSegment } from "../Common/CommonInput";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useTeams } from "../../contexts/TeamContext";
import { v4 as uuidv4 } from "uuid";
import { AIProviderLogo } from "../Portals/Portal Detail/AIProvider/AiProviderLogo";
import { CommonErrorToast } from "../Common/CommonErrorToast";

interface AddPineconeDBModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  teamId: string;
}

export const AddPineconeDBModal: React.FC<AddPineconeDBModalProps> = ({
  shows,
  setShows,
  teamId,
}) => {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [baseURL, setBaseURL] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [saveState, setSaveState] = useState<AnimationState>("ready");

  const teamService = useTeams();

  const handleDismiss = () => {
    setName("");
    setBaseURL("");
    setSecretKey("");
    setSaveState("ready");
    setShows(false);
  };

  const handleSave = async () => {
    setSaveState("loading");
    try {
      const newId = uuidv4();

      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId
      );

      const configured = (team?.secretsUsed ?? {})["openai"] != undefined;

      if (!configured) {
        throw Error(
          "You've got to add Open AI as an AI Provider first! Go to Settings > AI Providers and input your key there"
        );
      }

      // Add the secret first
      await teamService.addSecrets(teamId, { key: secretKey }, newId);

      // Update the team with the new Pinecone DB
      const newDB = {
        name,
        baseURL,
        secretKey: newId,
        embeddingProvider: "openai",
      };

      const existingDBs = team?.pineconeDBs ?? {};

      await teamService.teamRepo.update(
        { pineconeDBs: { ...existingDBs, [newId]: newDB } },
        teamService.teamPath(),
        teamId
      );

      setSaveState("success");
      handleDismiss();
    } catch (error) {
      console.error("Failed to add Pinecone DB:", error);
      setSaveState("error");
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  if (!shows) return null;

  return (
    <CommonModal
      isOpen={shows}
      onDismiss={handleDismiss}
      title="Add New Pinecone Database"
      subtitle="Configure a new vector database connection for enhanced AI"
    >
      {error && (
        <CommonErrorToast message={error} onClose={() => setError("")} />
      )}

      <CommonInputSegment
        title="Database Name"
        onChange={setName}
        value={name}
        placeholder="Enter a name for this database"
        id="dbName"
        type="text"
      />

      <CommonInputSegment
        title="Base URL"
        onChange={setBaseURL}
        value={baseURL}
        placeholder="Enter the Pinecone database URL"
        id="dbURL"
        type="text"
      />

      <CommonInputSegment
        title="Secret Key"
        onChange={setSecretKey}
        value={secretKey}
        type="password"
        placeholder="Enter your Pinecone API key"
        id="dbKey"
      />

      <div className="space-y-2">
        <label className="block font-gooper text-sm font-medium text-gray-500">
          Embedding Provider
        </label>
        <div className="flex items-center gap-2 px-3 py-2 border border-gray-300 rounded-md bg-gray-50">
          <AIProviderLogo
            providerId="openai"
            className="fill-gray-500 bg-gray-100 rounded-lg p-1 h-6 w-12"
            id="openai_embedding_provider"
          />
          <span className="text-sm text-gray-600">
            OpenAI + text-embedding-3-small
          </span>
        </div>
      </div>

      <div className="flex flex-row justify-between mt-4">
        <AnimatedButton
          title="Cancel"
          onClick={handleDismiss}
          buttonState="ready"
          style="normal"
          font="font-sans"
          id="cancelButton"
          classNameIn="w-40"
        />
        <AnimatedButton
          title="Add Database"
          onClick={handleSave}
          buttonState={saveState}
          setButtonState={setSaveState}
          style="action"
          leftIcon="database"
          font="font-sans"
          id="saveButton"
          classNameIn="w-40"
        />
      </div>
    </CommonModal>
  );
};
