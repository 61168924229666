import { createContext, useContext } from "react";
import { AnalyticsService } from "../services/AnalyticsService";

const AnalyticsContext = createContext<AnalyticsService | undefined>(undefined);

export const useAnalytics = (): AnalyticsService => {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }

  return analytics;
};

export default AnalyticsContext;
