import React, { useState, useEffect } from "react";
import { DatabaseKnowledge } from "../../models/DatabaseKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { CommonModal } from "../Common/CommonModal";
import { formatDate } from "../../utils/DateUtils";

interface BrowseDataTableModalProps {
  dataTable: DatabaseKnowledge;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface DatabaseRecord {
  recordId: string;
  content: string;
  metadata: Record<string, string>;
}

export const BrowseDataTableModal: React.FC<BrowseDataTableModalProps> = ({
  dataTable,
  teamId,
  isOpen,
  onClose,
}) => {
  const knowledgeService = useKnowledgeService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [records, setRecords] = useState<DatabaseRecord[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<DatabaseRecord | null>(
    null
  );
  const [paginationToken, setPaginationToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [metadataFilters, setMetadataFilters] = useState<
    Record<string, string>
  >({});
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string>>(
    {}
  );
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  // Fetch records when modal opens or filters change
  useEffect(() => {
    if (isOpen) {
      fetchRecords();
    }
  }, [isOpen, appliedFilters]);

  const fetchRecords = async (reset: boolean = true) => {
    if (reset) {
      setPaginationToken(null);
      setRecords([]);
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await knowledgeService.browseDatabaseRecords(
        teamId,
        dataTable.id!,
        10,
        reset ? null : paginationToken,
        Object.keys(appliedFilters).length > 0 ? appliedFilters : undefined
      );

      if (reset) {
        setRecords(response.records);
      } else {
        setRecords((prev) => [...prev, ...response.records]);
      }

      setPaginationToken(response.nextPaginationToken || null);
      setHasMore(!!response.nextPaginationToken);
    } catch (error) {
      console.error("Error fetching database records:", error);
      setError("Failed to load data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (paginationToken) {
      fetchRecords(false);
    }
  };

  const handleSelectRecord = (record: DatabaseRecord) => {
    setSelectedRecord(record);
    setIsDetailModalOpen(true);
  };

  const handleCloseRecordDetail = () => {
    setIsDetailModalOpen(false);
    setSelectedRecord(null);
  };

  const handleFilterChange = (key: string, value: string) => {
    setMetadataFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClearFilters = () => {
    setMetadataFilters({});
    setAppliedFilters({});
  };

  const handleApplyFilters = () => {
    setAppliedFilters({ ...metadataFilters });
  };

  const renderFilterControls = () => {
    return (
      <div className="bg-gray-50 p-4 rounded-lg mb-4">
        <h4 className="text-sm font-medium text-gray-700 mb-3">
          Filter Records
        </h4>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {dataTable.metadataFields.map((field) => (
            <div key={field.name}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {field.name}
              </label>
              <input
                type="text"
                value={metadataFilters[field.name] || ""}
                onChange={(e) => handleFilterChange(field.name, e.target.value)}
                placeholder={`Filter by ${field.name}`}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-3">
          <button
            onClick={handleClearFilters}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            Clear Filters
          </button>
          <button
            onClick={handleApplyFilters}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700"
          >
            Apply Filters
          </button>
        </div>
      </div>
    );
  };

  const renderRecords = () => {
    if (isLoading && records.length === 0) {
      return (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin h-6 w-6 border-2 border-blue-500 border-t-transparent rounded-full"></div>
          <span className="ml-2">Loading records...</span>
        </div>
      );
    }

    if (error) {
      return (
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-600">{error}</p>
          <button
            onClick={() => fetchRecords()}
            className="mt-2 text-sm text-blue-600 hover:text-blue-700"
          >
            Try Again
          </button>
        </div>
      );
    }

    if (records.length === 0) {
      return (
        <div className="bg-gray-50 p-8 rounded-lg text-center">
          <p className="text-gray-500">No records found in this database.</p>
          {Object.keys(appliedFilters).length > 0 && (
            <button
              onClick={handleClearFilters}
              className="mt-2 text-sm text-blue-600 hover:text-blue-700"
            >
              Clear filters
            </button>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {records.map((record) => (
          <div
            key={record.recordId}
            className="bg-white border rounded-lg p-4 hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => handleSelectRecord(record)}
          >
            <div className="flex justify-between items-start">
              <h3 className="font-medium truncate">
                Record ID: {record.recordId}
              </h3>
              <div className="text-xs px-2 py-1 bg-gray-100 rounded">
                {formatDate(
                  new Date(record.metadata.timestampString || Date.now())
                )}
              </div>
            </div>
            <p className="text-gray-600 text-sm mt-2 line-clamp-3">
              {record.content}
            </p>
            <div className="flex flex-wrap gap-2 mt-3">
              {Object.entries(record.metadata)
                .filter(
                  ([key]) =>
                    key !== "content" &&
                    key !== "timestampString" &&
                    key !== "recordId" &&
                    key !== "sourceId" &&
                    key !== "teamId" &&
                    key !== "chunkIndex"
                )
                .map(([key, value], idx) => (
                  <span
                    key={idx}
                    className="inline-flex items-center px-2 py-1 text-xs rounded bg-gray-100 text-gray-800"
                  >
                    {key}: {value}
                  </span>
                ))}
            </div>
          </div>
        ))}

        {hasMore && (
          <div className="flex justify-center mt-4">
            <button
              onClick={handleLoadMore}
              disabled={isLoading}
              className="px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-300 rounded-md shadow-sm hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <span className="animate-spin h-4 w-4 border-2 border-blue-500 border-t-transparent rounded-full mr-2"></span>
                  Loading...
                </span>
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderRecordDetail = () => {
    if (!selectedRecord) return null;

    return (
      <CommonModal
        title="Record Detail"
        isOpen={isDetailModalOpen}
        onDismiss={handleCloseRecordDetail}
      >
        <div className="overflow-y-auto p-6 flex-grow">
          <div className="mb-6">
            <h3 className="text-sm font-medium text-gray-500 mb-2">
              Record ID
            </h3>
            <p className="font-medium">{selectedRecord.recordId}</p>
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-medium text-gray-500 mb-2">Content</h3>
            <div className="bg-gray-50 p-4 rounded-lg whitespace-pre-wrap">
              {selectedRecord.content}
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Metadata</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <table className="w-full">
                <tbody>
                  {Object.entries(selectedRecord.metadata).map(
                    ([key, value], idx) => (
                      <tr
                        key={idx}
                        className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="py-2 px-4 font-medium">{key}</td>
                        <td className="py-2 px-4">{value}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="border-t p-6 flex justify-end">
          <button
            onClick={handleCloseRecordDetail}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            Close
          </button>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        onDismiss={onClose}
        title={`Browse ${dataTable.name}`}
      >
        {renderFilterControls()}
        {renderRecords()}
      </CommonModal>

      {renderRecordDetail()}
    </>
  );
};

export default BrowseDataTableModal;
