import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ChatMessage } from "../../models/ChatMessage";
import { Easybeam } from "easybeam-react";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { SoloAgent as SoloAgentType } from "../../models/SoloAgent";
import { CommonSpinner } from "../Common/CommonLoading";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { ChatBubble } from "../Portals/Portal Detail/ChatBubble";
import Icon from "../Icon";
import { useFileUpload } from "../../contexts/FileUploadContext";
import { UploadState } from "../../services/FileUploadService";
import { CommonErrorToast } from "../Common/CommonErrorToast";

const SESSION_USER_ID_KEY = "session_user_id";

// Extracts variables from search params prefixed with 'variable'
const extractVariablesFromUrl = (
  searchParams: URLSearchParams
): Record<string, string> => {
  const variables: Record<string, string> = {};

  // Iterate through all search params
  searchParams.forEach((value, key) => {
    // Check if the param starts with 'variable'
    if (key.startsWith("variable")) {
      // Extract the variable name by removing the 'variable' prefix
      // e.g., 'variableteamName' becomes 'teamName'
      const variableName = key.replace("variable", "");

      // Only add if we have a valid variable name
      if (variableName) {
        // Convert first character to lowercase for consistent variable naming
        const formattedName =
          variableName.charAt(0).toLowerCase() + variableName.slice(1);
        variables[formattedName] = value;
      }
    }
  });

  return variables;
};

// Inline AgentChat component with fixed structure
const AgentChat: React.FC<{
  messages: ChatMessage[];
  running: boolean;
  sendMessage: (message: string, imageUrl?: string) => void;
  chatId: string | undefined;
  easybeam: Easybeam;
  userId?: string;
  allowImages?: boolean;
  soloAgentId: string;
}> = ({
  messages,
  running,
  sendMessage,
  chatId,
  easybeam,
  userId,
  allowImages,
  soloAgentId,
}) => {
  const [sendState, setSendState] = useState<AnimationState>("ready");
  const [newMessage, setNewMessage] = useState("");
  const [pendingImageUrl, setPendingImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileUpload = useFileUpload();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, running]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim().length === 0 && !pendingImageUrl) return;
    sendMessage(newMessage.trim(), pendingImageUrl || undefined);
    setNewMessage("");
    setPendingImageUrl(null);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      setError("Image size must be less than 10MB");
      return;
    }

    try {
      const url = await fileUpload.uploadFile(
        file,
        `soloAgents/${soloAgentId}/chat-images`
      );
      setPendingImageUrl(url);
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleReview = async (score: number) => {
    if (chatId) {
      setIsReviewing(true);
      try {
        await easybeam.review(chatId, userId, score, undefined);
        setHasReviewed(true);
      } catch (error) {
        console.error("Error submitting review:", error);
      } finally {
        setIsReviewing(false);
      }
    }
  };

  const [uploadState, setUploadState] = useState<UploadState>({
    isUploading: false,
    progress: null,
  });

  useEffect(() => {
    return fileUpload.subscribe(setUploadState);
  }, [fileUpload]);

  const { isUploading } = uploadState;
  const disableChat = newMessage.trim().length < 1 && !pendingImageUrl;

  return (
    <div className="h-full w-full rounded-xl shadow-2xl">
      <div className="h-full w-full flex flex-col bg-gray-50 rounded-xl overflow-hidden relative">
        {error && (
          <div className="absolute top-4 left-4 z-10">
            <CommonErrorToast message={error} onClose={() => setError(null)} />
          </div>
        )}

        {/* Messages area */}
        <div className="flex-1 overflow-y-auto">
          <div className="h-full max-w-4xl mx-auto p-4">
            {messages.map((message, index) => (
              <ChatBubble
                hideCost={true}
                teamName=""
                key={message.id}
                message={message}
                isLast={index === messages.length - 1}
                loading={
                  running &&
                  index === messages.length - 1 &&
                  message.role === "AI"
                }
                id={`message${index + 1}`}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input area */}
        <div className="p-4 border-t border-gray-100 bg-gray-50">
          {chatId && (
            <div className="flex justify-end items-center gap-2 mb-2 text-sm text-gray-500">
              {hasReviewed ? (
                <span>Thanks for the feedback!</span>
              ) : isReviewing ? (
                <>
                  <span>Submitting review</span>
                  <CommonSpinner size="xs" />
                </>
              ) : (
                <>
                  <span>Rate this agent:</span>
                  <button
                    onClick={() => handleReview(10)}
                    className="hover:text-gray-700 transition-colors"
                    aria-label="Rate positively"
                    id="thumbsUpButton"
                  >
                    <Icon type="thumbs-up" className="size-4" />
                  </button>
                  <button
                    onClick={() => handleReview(0)}
                    className="hover:text-gray-700 transition-colors"
                    aria-label="Rate negatively"
                    id="thumbsDownButton"
                  >
                    <Icon type="thumbs-down" className="size-4" />
                  </button>
                </>
              )}
            </div>
          )}
          <div className="bg-white shadow-sm rounded-lg p-4">
            <textarea
              className="w-full h-20 p-2 border border-gray-200 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              id="messageInput"
            />
            <div className="flex justify-end mt-2 gap-2 w-full">
              <AnimatedButton
                title="Send"
                onClick={handleSendMessage}
                buttonState={sendState}
                setButtonState={setSendState}
                style="action"
                classNameIn={
                  allowImages
                    ? "w-full flex justify-center"
                    : "w-full flex justify-center"
                }
                disabled={disableChat}
                leftChild={
                  <Icon type="paper-plane" className="rotate-90 size-4" />
                }
                font="font-sans font-medium"
                id="sendMessageButton"
              />

              {allowImages && (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                    ref={fileInputRef}
                  />
                  <button
                    onClick={() => {
                      if (pendingImageUrl) {
                        setPendingImageUrl(null);
                      } else {
                        fileInputRef.current?.click();
                      }
                    }}
                    className="px-3 py-2 rounded bg-gray-100 hover:bg-gray-200 transition-colors relative"
                    disabled={isUploading}
                    id="imageUploadButton"
                  >
                    {isUploading ? (
                      <CommonSpinner size="sm" />
                    ) : pendingImageUrl ? (
                      <Icon type="check" className="size-4 text-emerald-600" />
                    ) : (
                      <Icon type="camera" className="size-4" />
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Footer component
const EasybeamFooter: React.FC = () => {
  return (
    <div className="flex items-center justify-center py-4">
      <div className="flex items-center">
        <span className="font-sans text-white opacity-70 mr-1">powered by</span>
        <a href="https://www.easybeam.ai" className="font-gooper text-white">
          easybeam
        </a>
      </div>
    </div>
  );
};

export const SoloAgent: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const location = useLocation();
  const workflowService = useWorkflowService();

  // Extract variables from URL search params
  const urlVariables = extractVariablesFromUrl(
    new URLSearchParams(location.search)
  );

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [agent, setAgent] = useState<SoloAgentType | undefined>();
  const [easybeam, setEasybeam] = useState<Easybeam | undefined>();
  const [chatId, setChatId] = useState<string>();
  const [hasStartedConversation, setHasStartedConversation] =
    useState<boolean>(false);
  const [userId] = useState<string>(() => {
    const existingUserId = sessionStorage.getItem(SESSION_USER_ID_KEY);
    if (existingUserId) {
      return existingUserId;
    }
    const newUserId = "user-" + uuidv4().slice(0, 8);
    sessionStorage.setItem(SESSION_USER_ID_KEY, newUserId);
    return newUserId;
  });

  // Auto-start conversation
  useEffect(() => {
    if (
      agent?.published?.autoStart === true &&
      easybeam &&
      !hasStartedConversation &&
      !isLoading &&
      messages.length === 0
    ) {
      setHasStartedConversation(true);
      startConversation();
    }
  }, [agent, easybeam, isLoading, hasStartedConversation]);

  // Function to start a conversation with an empty message array
  const startConversation = async () => {
    if (!agent || !easybeam) return;

    setIsRunning(true);

    const aiMessage: ChatMessage = {
      id: uuidv4(),
      role: "AI",
      content: "",
      createdAt: new Date().toISOString(),
    };

    setMessages([aiMessage]);

    try {
      await easybeam.streamAgent(
        agentId!,
        userId,
        urlVariables,
        [], // Empty messages array to start a fresh conversation
        (response) => {
          setChatId(response.chatId);
          setMessages([
            {
              id: aiMessage.id,
              role: "AI",
              content: response.newMessage.content,
              createdAt: aiMessage.createdAt,
            },
          ]);
        },
        () => {
          setIsRunning(false);
        },
        (error) => {
          console.error("Error in auto-start stream:", error);
          setMessages([
            {
              id: aiMessage.id,
              role: "AI",
              content:
                "Sorry, something went wrong. Please try sending a message to start the conversation.",
              createdAt: aiMessage.createdAt,
            },
          ]);
          setIsRunning(false);
        }
      );
    } catch (error) {
      console.error("Failed to auto-start conversation:", error);
      setMessages([
        {
          id: aiMessage.id,
          role: "AI",
          content:
            "Sorry, something went wrong. Please try sending a message to start the conversation.",
          createdAt: aiMessage.createdAt,
        },
      ]);
      setIsRunning(false);
    }
  };

  useEffect(() => {
    const fetchAgent = async () => {
      if (!agentId) return;

      try {
        const fetchedAgent = await workflowService.agentRepo.get(
          workflowService.agentPath(),
          agentId
        );

        if (!fetchedAgent || !fetchedAgent.published) {
          throw new Error("This agent hasn't been published yet");
        }

        if (!fetchedAgent.published?.token) {
          throw new Error("Agent configuration is incomplete");
        }

        setAgent(fetchedAgent);
        setEasybeam(new Easybeam({ token: fetchedAgent.published?.token }));
      } catch (error) {
        console.error("Failed to fetch agent:", error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("Failed to load agent");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgent();
  }, [agentId, workflowService]);

  const sendMessage = async (messageContent: string, imageUrl?: string) => {
    if ((!messageContent.trim() && !imageUrl) || !agent || !easybeam) return;

    const userMessage: ChatMessage = {
      id: uuidv4(),
      role: "USER",
      content: messageContent,
      createdAt: new Date().toISOString(),
      imageURL: imageUrl,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsRunning(true);

    const aiMessage: ChatMessage = {
      id: uuidv4(),
      role: "AI",
      content: "",
      createdAt: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, aiMessage]);

    try {
      const formattedMessages = messages.map((msg) => ({
        content: msg.content,
        role: msg.role,
        createdAt: msg.createdAt,
        id: msg.id,
        imageURL: msg.imageURL,
      }));

      formattedMessages.push({
        content: userMessage.content,
        role: userMessage.role,
        createdAt: userMessage.createdAt,
        id: userMessage.id,
        imageURL: userMessage.imageURL,
      });

      await easybeam.streamAgent(
        agentId!,
        userId,
        urlVariables,
        formattedMessages,
        (response) => {
          setChatId(response.chatId);
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content = response.newMessage.content;
            }
            return newMessages;
          });
        },
        () => {
          setIsRunning(false);
        },
        (error) => {
          console.error("Error in stream:", error);
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.role === "AI") {
              lastMessage.content = `Sorry, something went wrong. Please try again.`;
            }
            return newMessages;
          });
          setIsRunning(false);
        }
      );
    } catch (error) {
      console.error("Failed to send message:", error);
      setMessages((prev) => {
        const newMessages = [...prev];
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage && lastMessage.role === "AI") {
          lastMessage.content =
            "Sorry, something went wrong. Please try again.";
        }
        return newMessages;
      });
      setIsRunning(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <CommonSpinner />
      </div>
    );
  }

  const themeColor = !!agent?.published?.themeColor
    ? agent?.published?.themeColor
    : "#3062FF";

  const fontColor = !!agent?.published?.titleColor
    ? agent?.published?.titleColor
    : "white";

  return (
    <>
      {agent && easybeam && (
        <div
          className="h-screen w-full flex flex-col lg:flex-row overflow-hidden"
          style={{ backgroundColor: themeColor }}
        >
          {/* Agent Info Section - Fixed height on mobile, left aligned on desktop */}
          <div className="h-[220px] lg:h-screen lg:w-1/2 flex flex-col items-center lg:items-start justify-start p-6 lg:p-32 relative">
            <div className="flex flex-col items-center lg:items-start max-w-md">
              {/* Agent Logo - with adjusted padding between logo and title */}
              {agent.published?.logoUrl && (
                <div className="w-14 h-14 lg:w-24 lg:h-24 mb-4 lg:mb-16 relative">
                  <img
                    src={agent.published.logoUrl}
                    alt={`${agent.published.title} logo`}
                    className="w-full h-full object-contain"
                  />
                </div>
              )}
              {agent.published?.logoUrl == undefined && (
                <svg
                  className="w-14 h-14 lg:w-24 lg:h-24 mb-4 lg:mb-16 relative"
                  width="96"
                  height="96"
                  viewBox="0 0 96 96"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="eb" clipPath="url(#clip0_4373_3552)">
                    <path
                      id="Vector"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M48.0116 0C21.4856 0 0 21.4856 0 48.0116C0 74.5376 21.4856 96.0232 48.0116 96.0232C74.5376 96.0232 96.0232 74.5376 96.0232 48.0116C96.0232 21.4856 74.5144 0 48.0116 0ZM47.8722 86.0585C28.3378 86.0585 12.241 71.3322 10.0808 52.3784H49.9163L39.789 62.5057C39.789 62.5057 39.7426 62.5521 39.7193 62.5754C38.0237 64.271 38.0237 67.0351 39.7193 68.7539C41.415 70.4496 44.179 70.4496 45.8979 68.7539L62.8773 51.7745C63.1328 51.6119 63.3651 51.4261 63.5974 51.1938C64.4568 50.3344 64.8749 49.2195 64.8749 48.1045C64.9214 46.9431 64.5033 45.7818 63.5974 44.8759C63.3651 44.6436 63.1328 44.4578 62.8541 44.2952L45.9444 27.3854C45.9444 27.3854 45.8979 27.339 45.8747 27.3158C44.179 25.6201 41.415 25.6201 39.6961 27.3158C38.0005 29.0114 38.0005 31.7755 39.6961 33.4943L49.8698 43.6913H10.0808C12.241 24.7375 28.3378 10.0111 47.8722 10.0111C68.8933 10.0111 85.9192 27.037 85.9192 48.0581C85.9192 69.0791 68.8933 86.105 47.8722 86.105V86.0585Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4373_3552">
                      <rect width="96" height="96" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}

              {/* Agent Title */}
              <h1
                style={{ color: fontColor }}
                className="text-2xl font-gooper md:text-4xl lg:text-5xl font-semibold text-center lg:text-left mb-3"
              >
                {agent.published?.title}
              </h1>

              {/* Agent Description/Subtitle */}
              <p
                style={{ color: fontColor }}
                className="text-base font-thin lg:text-2xl text-center lg:text-left"
              >
                {agent.published?.description || "Your AI assistant"}
              </p>
            </div>

            {/* Footer - visible only on desktop, positioned at bottom */}
            <div className="hidden lg:block absolute bottom-6">
              <EasybeamFooter />
            </div>
          </div>

          {/* Chat Section - Exactly fills remaining space */}
          <div className="h-[calc(100vh-220px-44px)] lg:h-screen lg:w-1/2 p-4 md:p-12 lg:p-12 relative">
            {error && (
              <CommonErrorToast message={error} onClose={() => setError("")} />
            )}
            <AgentChat
              messages={messages}
              running={isRunning}
              sendMessage={sendMessage}
              chatId={chatId}
              easybeam={easybeam}
              userId={userId}
              allowImages={agent.published?.allowImages === true}
              soloAgentId={agentId ?? ""}
            />
          </div>

          {/* Footer - visible only on mobile, after chat */}
          <div className="lg:hidden h-[44px]">
            <EasybeamFooter />
          </div>
        </div>
      )}
    </>
  );
};
