import React, { useEffect, useState } from "react";
import GetStartedCard from "./GetStartedCard";
import { usePortalService } from "../../contexts/PortalContext";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { CommonErrorToast } from "../Common/CommonErrorToast";
import { AppPath } from "../../models/AppPath";
import { useAnalytics } from "../../contexts/AnalyticsContext";

interface GetStartedPortalsProps {
  teamId: string;
}

const GetStartedPortals: React.FC<GetStartedPortalsProps> = ({ teamId }) => {
  const portalService = usePortalService();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const [completed, setCompleted] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true"
  );
  const [loading, setLoading] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) != "true"
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true") {
      return;
    }
    const loadData = async () => {
      try {
        const portals = await portalService.portalRepo.getList(
          portalService.portalPath(teamId),
          undefined,
          undefined,
          10
        );
        const completed = portals.filter((p) => p.isExample != true).length > 0;
        setCompleted(completed);
        if (completed) {
          localStorage.setItem(STORAGE_KEYS.COMPLETED_PORTALS, "true");
        }
      } catch (err) {
        setError("Failed to load portals. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [portalService, teamId]);

  const handlePortalAction = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      const result = await portalService.createPortal(teamId);
      analytics.track("createdPrompt");
      setCompleted(true);
      navigate(
        AppPath.portalVersion(
          teamId,
          result.portal.id,
          result.version.id!,
          true
        )
      );
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create portal. Please try again."
      );
      setLoading(false); // Ensure loading is set to false if there's an error
    }
  };

  return (
    <div className="relative">
      <GetStartedCard
        sectionTitle="Prompts"
        actionTitle="Create portal"
        title="Create Your First Prompt"
        subtitle="Version and manage your prompts"
        number="1"
        iconType="plus"
        completed={completed}
        completedMessage="Great job!"
        action={handlePortalAction}
        loading={loading}
      />
      {error && (
        <CommonErrorToast message={error} onClose={() => setError(null)} />
      )}
    </div>
  );
};

export default GetStartedPortals;
