import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import {
  DatabaseNodeData,
  DatabaseNodeType,
  DatabaseOperation,
  DeleteDatabaseData,
  GetDatabaseData,
  QueryDatabaseData,
  UpdateDatabaseData,
  WriteDatabaseData,
} from "../../Map/Nodes/DatabaseNode";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { useEffect, useState } from "react";
import { useKnowledgeService } from "../../../../contexts/KnowledgeContext";
import { DatabaseKnowledge } from "../../../../models/DatabaseKnowledge";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import { AppPath } from "../../../../models/AppPath";
import { QueryComponent } from "./QueryComponent";
import { WriteComponent } from "./WriteComponent";
import { UpdateComponent } from "./UpdateComponent";
import { DeleteComponent } from "./DeleteComponent";
import { GetComponent } from "./GetComponent";
import { AdvancedQuerySettingsModal } from "../Knowledge/KnowledgeAdvancedSettingsModal";
import InfoComponent from "../InfoComponent";

export const SidebarDatabaseDetail: React.FC<{
  data: DatabaseNodeData;
  team: Team;
  version: WorkflowVersion;
}> = ({ data, team, version }) => {
  const knowledgeService = useKnowledgeService();
  const { setNodes } = useReactFlow();
  const [availableDatabases, setAvailableDatabases] = useState<
    DatabaseKnowledge[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  useEffect(() => {
    const fetchDatabases = async () => {
      try {
        const databases = await knowledgeService.databaseKnowledgeRepo.getList(
          knowledgeService.databaseKnowledgePath(team.id!)
        );

        setAvailableDatabases(databases);

        // Clean up if selected database no longer exists
        if (
          data.databaseId &&
          !databases.some((db) => db.id === data.databaseId)
        ) {
          updateNode({ databaseId: "" });
        }
      } catch (error) {
        console.error("Error fetching databases:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDatabases();
  }, [team.id]);

  const updateNode = (newData: Partial<DatabaseNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          let updatedData;
          switch (data.operation) {
            case "write":
              updatedData = { ...(node.data as WriteDatabaseData), ...newData };
              break;
            case "update":
              updatedData = {
                ...(node.data as UpdateDatabaseData),
                ...newData,
              };
              break;
            case "query":
              updatedData = { ...(node.data as QueryDatabaseData), ...newData };
              break;
            case "delete":
              updatedData = {
                ...(node.data as DeleteDatabaseData),
                ...newData,
              };
              break;
            case "get":
              updatedData = { ...(node.data as GetDatabaseData), ...newData };
              break;
          }
          return { ...node, data: updatedData };
        }
        return node;
      })
    );
  };

  const selectedDatabase = availableDatabases.find(
    (db) => db.id === data.databaseId
  );
  const availableVariables = Object.keys(version.usedVariables ?? {});

  const operations: { value: DatabaseOperation; label: string }[] = [
    { value: "query", label: "Search Records" },
    { value: "get", label: "Get Record" },
    { value: "write", label: "Write Record" },
    { value: "delete", label: "Delete Record" },
  ];

  const handleOperationChange = (operation: DatabaseOperation) => {
    switch (operation) {
      case "query":
        updateNode({
          operation,
          searchQuery: { type: "text", value: "" },
          useMetadataFilters: false,
          metadataFilters: {},
          contextWindow: 1,
          resultCount: 3,
          similarityThreshold: 0.3,
        });
        break;
      case "write":
        updateNode({
          operation,
          content: { type: "text", value: "" },
          metadata: {},
          isAsync: false,
        });
        break;
      case "update":
        updateNode({
          operation,
          recordId: { type: "text", value: "" },
          content: { type: "text", value: "" },
          metadata: {},
          updateMetadata: false,
          isAsync: false,
          appendContent: false,
        });
        break;
      case "delete":
        updateNode({
          operation,
          recordId: { type: "text", value: "" },
          isAsync: false,
        });
        break;
      case "get":
        updateNode({
          operation,
          recordId: { type: "text", value: "" },
        });
        break;
    }
  };

  const renderOperationComponent = () => {
    if (
      !selectedDatabase &&
      data.operation !== "get" &&
      data.operation !== "delete"
    ) {
      return null;
    }

    switch (data.operation) {
      case "query":
        return (
          <QueryComponent
            data={data as QueryDatabaseData}
            selectedDatabase={selectedDatabase}
            availableVariables={availableVariables}
            updateNode={updateNode}
            setShowAdvancedSettings={setShowAdvancedSettings}
          />
        );
      case "write":
        return (
          <WriteComponent
            data={data as WriteDatabaseData}
            selectedDatabase={selectedDatabase!}
            availableVariables={availableVariables}
            updateNode={updateNode}
          />
        );
      case "update":
        return (
          <UpdateComponent
            data={data as UpdateDatabaseData}
            selectedDatabase={selectedDatabase!}
            availableVariables={availableVariables}
            updateNode={updateNode}
          />
        );
      case "delete":
        return (
          <DeleteComponent
            data={data as DeleteDatabaseData}
            availableVariables={availableVariables}
            updateNode={updateNode}
          />
        );
      case "get":
        return (
          <GetComponent
            data={data as GetDatabaseData}
            availableVariables={availableVariables}
            updateNode={updateNode}
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <CommonInputSegment
        title="Database Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="databaseTitle"
        className="font-sans text-gray-700"
      />

      {showAdvancedSettings && data.operation == "query" && (
        <AdvancedQuerySettingsModal
          isOpen={showAdvancedSettings}
          onClose={() => setShowAdvancedSettings(false)}
          settings={{
            resultCount: data.resultCount,
            similarityThreshold: data.similarityThreshold,
            contextWindow: data.contextWindow,
          }}
          onUpdate={(newSettings) => {
            updateNode({
              resultCount: newSettings.resultCount,
              similarityThreshold: newSettings.similarityThreshold,
              contextWindow: newSettings.contextWindow,
            });
          }}
          title="Database Query Settings"
        />
      )}

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Operation Type</div>
        <select
          value={data.operation}
          onChange={(e) =>
            handleOperationChange(e.target.value as DatabaseOperation)
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          {operations.map((op) => (
            <option key={op.value} value={op.value}>
              {op.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Select Database</div>
        {isLoading ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">Loading databases...</div>
          </div>
        ) : availableDatabases.length === 0 ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">No databases available</div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 p-3 bg-gray-50 rounded-md border border-gray-200">
            {availableDatabases.map((database) => (
              <label
                key={database.id}
                className="flex items-start gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
              >
                <input
                  type="radio"
                  checked={data.databaseId === database.id}
                  onChange={() =>
                    updateNode({
                      databaseId: database.id,
                      configId: database.pineconeDBId,
                      baseURL: team.pineconeDBs[database.pineconeDBId].baseURL,
                    })
                  }
                  className="mt-1 rounded-full border-gray-300 text-indigo-500 focus:ring-indigo-500"
                />
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-700">
                    {database.name}
                  </span>
                  <span className="text-xs text-gray-500">
                    {database.metadataFields.length} fields
                  </span>
                </div>
              </label>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-end">
          <CommonUnderlineButton
            title="Add database"
            onClick={() => window.open(AppPath.knowledge(team.id!), "_blank")}
          />
        </div>
      </div>

      {renderOperationComponent()}

      <InfoComponent howToId={DatabaseNodeType} howToName="Database Step" />
    </div>
  );
};
