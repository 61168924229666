import React, { useState, useEffect } from "react";
import { WebsiteKnowledge } from "../../models/WebsiteKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import AnimatedButton from "../AnimatedButton";
import Icon from "../Icon";
import { CommonModal } from "../Common/CommonModal";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { StatusBadge } from "../TestCenter/StatusBadge";
import { CommonSpinner } from "../Common/CommonLoading";
import { AddWebsiteKnowledgeRequest } from "../../services/KnowledgeService";
import { WebsiteContentModal } from "./WebsiteContentModal"; // Import the new component

interface ManageWebsiteModalProps {
  website: WebsiteKnowledge;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const ManageWebsiteModal: React.FC<ManageWebsiteModalProps> = ({
  website,
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingChild, setIsDeletingChild] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [childPages, setChildPages] = useState<WebsiteKnowledge[]>([]);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const [showChildPages, setShowChildPages] = useState(false);
  const [showAddChildForm, setShowAddChildForm] = useState(false);
  const [isAddingChild, setIsAddingChild] = useState(false);
  const [newChildUrl, setNewChildUrl] = useState("");
  const [addChildError, setAddChildError] = useState<string | null>(null);

  // State for content modal - now supports parent and child pages
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [selectedPageParentId, setSelectedPageParentId] = useState<
    string | null
  >(null);
  const [showContentModal, setShowContentModal] = useState(false);

  useEffect(() => {
    if (isOpen && website.hasChildren) {
      loadChildPages();
    }
  }, [isOpen, website.id]);

  const loadChildPages = async () => {
    if (!website.hasChildren) return;

    setIsLoadingChildren(true);
    try {
      const children = await knowledgeService.websiteKnowledgeRepo.getList(
        knowledgeService.websiteKnowledgeChildrenPath(teamId, website.id)
      );
      setChildPages(children.sort((a, b) => a.url.localeCompare(b.url)));
      setShowChildPages(true);
    } catch (e) {
      setError(e instanceof Error ? e.message : "Failed to load crawled pages");
    } finally {
      setIsLoadingChildren(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await knowledgeService.removeWebsiteKnowledge(teamId, website.id!);
      onSuccess?.();
      onClose();
    } catch (error) {
      setError("Failed to delete website knowledge");
      console.error("Failed to delete website:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteChild = async (childId: string) => {
    try {
      setIsDeletingChild(childId);
      await knowledgeService.removeWebsiteKnowledge(
        teamId,
        childId,
        website.id
      );
      // Update the list after deletion
      setChildPages(childPages.filter((page) => page.id !== childId));
      // Update the parent website's count if necessary
      if (website.successCount) {
        website.successCount -= 1;
      }
    } catch (error) {
      setError("Failed to delete child page");
      console.error("Failed to delete child page:", error);
    } finally {
      setIsDeletingChild(null);
    }
  };

  const handleAddChild = async () => {
    if (!newChildUrl.trim()) {
      setAddChildError("URL is required");
      return;
    }

    try {
      setIsAddingChild(true);
      setAddChildError(null);

      // Create a request based on your service structure
      const addRequest: AddWebsiteKnowledgeRequest = {
        url: newChildUrl,
        filterOptions: website.filterOptions || {
          includeSelectors: [],
          excludeSelectors: [],
        },
        encoding: website.encodingStrategy || {
          name: "Default",
          chunkSize: 1000,
          chunkOverlap: 200,
        },
        parentId: website.id,
      };

      console.log("Adding child with request:", addRequest);

      const newChild = await knowledgeService.addWebsiteKnowledge(
        teamId,
        addRequest
      );

      console.log("Child added successfully:", newChild);

      // Add the new child to the list
      setChildPages(
        [...childPages, newChild].sort((a, b) => a.url.localeCompare(b.url))
      );
      setNewChildUrl("");

      // Update the parent website's count if necessary
      if (website.successCount !== undefined) {
        website.successCount += 1;
      }

      // Close the form after successful addition
      setShowAddChildForm(false);
    } catch (error) {
      console.error("Failed to add child page:", error);
      setAddChildError(
        error instanceof Error ? error.message : "Failed to add child page"
      );
    } finally {
      setIsAddingChild(false);
    }
  };

  // Handler for viewing parent website content
  const handleViewParentContent = () => {
    setSelectedPageId(website.id!);
    setSelectedPageParentId(null); // Parent has no parent ID
    setShowContentModal(true);
  };

  // Handler for viewing child page content
  const handleViewChildContent = (childId: string) => {
    setSelectedPageId(childId);
    setSelectedPageParentId(website.id); // Set parent ID for child pages
    setShowContentModal(true);
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        onDismiss={onClose}
        title="Manage Website Knowledge"
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-start">
              <div className="flex flex-row gap-2">
                <h3 className="text-lg font-medium">
                  {website.title ?? website.url}
                </h3>
                <div className="flex h-fit">
                  <StatusBadge status={website.status} />
                </div>
              </div>
              {/* Add View Content button for parent website */}
              <button
                onClick={handleViewParentContent}
                className="text-blue-600 hover:text-blue-800 flex items-center gap-1 text-sm font-medium"
                title="View Content"
              >
                <Icon type="eye" />
                <span>View Content</span>
              </button>
            </div>

            <h3 className="text-gray-400 text-sm break-all">{website.url}</h3>

            <div className="flex gap-6 text-gray-600">
              <div className="flex items-center gap-2">
                <Icon type="clock" />
                <span>
                  Added:{" "}
                  {`${formatDate(website.lastUpdate)} ${formatTime(
                    website.lastUpdate
                  )}`}
                </span>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Embedding Details
            </h4>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm text-gray-500">Chunks</div>
                <div className="text-lg font-medium">
                  {website.chunkCount || 0}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Strategy</div>
                <div className="text-lg font-medium">
                  {website.encodingStrategy
                    ? `${website.encodingStrategy.name}`
                    : "Default"}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Status</div>
                <div className="text-lg font-medium capitalize">
                  {website.status.toLowerCase()}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Last Updated</div>
                <div className="text-lg font-medium">
                  {`${formatDate(website.lastUpdate)} ${formatTime(
                    website.lastUpdate
                  )}`}
                </div>
              </div>
              {website.hasChildren && (
                <div className="col-span-2">
                  <div className="text-sm text-gray-500">Crawled Pages</div>
                  <div className="flex items-center gap-2">
                    <div className="text-lg font-medium">
                      {website.successCount || "Loading..."}
                    </div>
                    {!showChildPages && (
                      <button
                        onClick={() => setShowChildPages(true)}
                        className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Show details
                      </button>
                    )}
                    {showChildPages && (
                      <button
                        onClick={() => setShowChildPages(false)}
                        className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Hide details
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Child Pages Section */}
          {website.hasChildren && showChildPages && (
            <div className="bg-white border rounded-lg overflow-hidden">
              <div className="p-4 border-b bg-gray-50 flex justify-between items-center">
                <h4 className="text-sm font-medium text-gray-700">
                  Crawled Pages
                </h4>
                <button
                  onClick={() => setShowAddChildForm(!showAddChildForm)}
                  className="text-sm text-blue-600 hover:text-blue-800 font-medium flex items-center gap-1"
                >
                  <Icon type={showAddChildForm ? "x" : "plus"} />
                  {showAddChildForm ? "Cancel" : "Add Subpage"}
                </button>
              </div>

              {/* Add Child Form */}
              {showAddChildForm && (
                <div className="p-4 bg-gray-50 border-b">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="childUrl"
                      className="text-sm font-medium text-gray-700"
                    >
                      Child Page URL
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        id="childUrl"
                        value={newChildUrl}
                        onChange={(e) => setNewChildUrl(e.target.value)}
                        placeholder="https://example.com/child-page"
                        className="flex-1 p-2 border rounded text-sm"
                      />
                      <AnimatedButton
                        title="Add Page"
                        onClick={handleAddChild}
                        buttonState={isAddingChild ? "loading" : "ready"}
                        setButtonState={() => {}}
                        style="action"
                        leftIcon="plus"
                        id="addChildButton"
                        disabled={isAddingChild}
                      />
                    </div>
                    {addChildError && (
                      <div className="text-red-600 text-sm mt-1">
                        {addChildError}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isLoadingChildren ? (
                <div className="p-8 flex justify-center">
                  <CommonSpinner />
                </div>
              ) : childPages.length > 0 ? (
                <div className="max-h-96 overflow-y-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          URL
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Chunks
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {childPages.map((page) => (
                        <tr key={page.id} className="hover:bg-gray-50">
                          <td
                            className="px-6 py-4 text-sm text-gray-900 cursor-pointer"
                            onClick={() => handleViewChildContent(page.id!)}
                          >
                            <div
                              className="truncate max-w-xs text-xs text-blue-600 hover:underline"
                              title={page.url}
                            >
                              {page.url}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <StatusBadge status={page.status} />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {page.chunkCount}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                            <div className="flex items-center justify-end gap-2">
                              <button
                                onClick={() => handleViewChildContent(page.id!)}
                                className="text-blue-600 hover:text-blue-900 flex items-center gap-1"
                                title="View Content"
                              >
                                <Icon type="eye" />
                              </button>
                              <button
                                onClick={() => handleDeleteChild(page.id!)}
                                className="text-red-600 hover:text-red-900 flex items-center gap-1"
                                disabled={isDeletingChild === page.id}
                                title="Delete"
                              >
                                {isDeletingChild === page.id ? (
                                  <CommonSpinner size="sm" />
                                ) : (
                                  <Icon type="trash" />
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-8 text-center text-gray-500">
                  No crawled pages available
                </div>
              )}
            </div>
          )}

          <div className="border-t pt-6">
            <h4 className="text-lg font-medium mb-4">Danger Zone</h4>
            <div className="bg-red-50 p-4 rounded-lg">
              <AnimatedButton
                title="Delete Website Knowledge"
                onClick={handleDelete}
                buttonState={isDeleting ? "loading" : "ready"}
                setButtonState={() => {}}
                style="destructive"
                leftIcon="trash"
                classNameIn="w-full"
                id="deleteWebsiteButton"
                disabled={isDeleting}
              />
              {error && (
                <div className="text-red-600 text-sm mt-2">{error}</div>
              )}
              <p className="text-sm text-gray-600 mt-2">
                This action cannot be undone. All indexed content and embeddings
                will be permanently removed.
              </p>
            </div>
          </div>
        </div>
      </CommonModal>

      {/* Content Modal - now supports both parent and child pages */}
      {selectedPageId && (
        <WebsiteContentModal
          isOpen={showContentModal}
          onClose={() => {
            setShowContentModal(false);
            setSelectedPageId(null);
            setSelectedPageParentId(null);
          }}
          websiteId={selectedPageId}
          teamId={teamId}
          parentId={selectedPageParentId ?? undefined}
        />
      )}
    </>
  );
};
