import React from "react";
import { KnowledgeService } from "../services/KnowledgeService";

const KnowledgeContext = React.createContext<KnowledgeService | undefined>(
  undefined
);

export const useKnowledgeService = (): KnowledgeService => {
  const context = React.useContext(KnowledgeContext);
  if (!context) {
    throw new Error(
      "useKnowledgeService must be used within a KnowledgeServiceProvider"
    );
  }
  return context;
};

export default KnowledgeContext;
