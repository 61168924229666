import React, { useState } from "react";
import { WorkflowDemoDatabaseResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { DatabaseBadge } from "../../../Map/Nodes/DatabaseNode";
import { DatabaseResultModal } from "./DatabaseResultModal";

export const DatabaseResultCard: React.FC<{
  result: WorkflowDemoDatabaseResult;
  teamId: string;
}> = ({ result, teamId }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      onClick={() => setShowModal(true)}
      key={result.id}
      className="h-24 shadow-sm cursor-pointer hover:shadow-md flex flex-col rounded-lg bg-gray-50 p-2 gap-2 border border-gray-200 transition-all"
    >
      <DatabaseResultModal
        result={result}
        shows={showModal}
        onClose={() => setShowModal(false)}
        teamId={teamId}
      />
      <div className="flex flex-row justify-between">
        <DatabaseBadge />
        <StatusBadge status={result.status} />
      </div>
      <div className="text-2xl font-gooper text-gray-900">{result.name}</div>
    </div>
  );
};
