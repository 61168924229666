import React, { useEffect } from "react";
import Icon from "../Icon";
import { motion } from "framer-motion";

export const NextButton: React.FC<{
  onClick: () => void;
  title?: string;
}> = ({ onClick, title }) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onClick();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClick]);

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 1.02 }}
      id="nextButton"
      type="button"
      onClick={onClick}
      className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-transparent font-medium rounded-full px-4 py-3 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      {title ?? `Next`}
      <Icon type="arrow-left" className="rotate-180" />
    </motion.button>
  );
};
