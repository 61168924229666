import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import CatchAll from "../CatchAll";
import ChatTemplatesSection from "./ChatTemplates/ChatTemplatesSection";
import { TeamsSection } from "./TeamsSection";
import { useParams } from "react-router-dom";
import { ExamplesSection } from "./ExamplesSection";
import { RemoteConfigSection } from "./RemoteConfigSection";

interface AdminProps {}

type AdminTab = "chatTemplates" | "teams" | "ExampleData" | "remoteConfig";

export const Admin: React.FC<AdminProps> = () => {
  const { teamId } = useParams<{
    teamId: string;
  }>();

  const authService = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState<AdminTab>("chatTemplates");

  useEffect(() => {
    const loadData = async () => {
      const isAdmin = await authService.isAdmin();
      setIsAdmin(isAdmin);
    };
    loadData();
  }, []);

  if (!isAdmin) {
    return <CatchAll />;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "chatTemplates":
        return <ChatTemplatesSection />;
      case "teams":
        return <TeamsSection />;
      case "ExampleData":
        return <ExamplesSection teamId={teamId!} />;
      case "remoteConfig":
        return <RemoteConfigSection />;
      default:
        return null;
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="Admin Page"
        subtitle="Manage Administration Settings"
        sections={[]}
        teamId={""}
        actions={[]}
      />
      <div className="mt-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {["chatTemplates", "teams", "ExampleData", "remoteConfig"].map(
              (tab) => (
                <button
                  key={tab}
                  className={`
                  ${
                    activeTab === tab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }
                  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                `}
                  onClick={() => setActiveTab(tab as AdminTab)}
                >
                  {tab === "chatTemplates"
                    ? "Chat Templates"
                    : tab === "ExampleData"
                    ? "Example Data"
                    : tab === "remoteConfig"
                    ? "Remote Config"
                    : tab}
                </button>
              )
            )}
          </nav>
        </div>
        <div className="mt-6">{renderTabContent()}</div>
      </div>
    </CommonContainer>
  );
};

export default Admin;
