import React, { useEffect, useState } from "react";
import { CommonModal } from "../../../Common/CommonModal";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import ConfigSlider from "../../../Portals/Portal Detail/AIProvider/ConfigSlider";

// Create a generic interface for query settings
export interface QueryAdvancedSettings {
  resultCount: number;
  similarityThreshold: number;
  contextWindow: number;
}

interface AdvancedQuerySettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  settings: QueryAdvancedSettings;
  onUpdate: (newSettings: QueryAdvancedSettings) => void;
  title?: string;
}

export const AdvancedQuerySettingsModal: React.FC<
  AdvancedQuerySettingsModalProps
> = ({
  isOpen,
  onClose,
  settings,
  onUpdate,
  title = "Advanced Query Settings",
}) => {
  const [resultCount, setResultCount] = useState(
    Math.round(settings.resultCount || 3)
  );
  const [similarityThreshold, setSimilarityThreshold] = useState(
    Math.round((settings.similarityThreshold || 0.7) * 100)
  );
  const [contextWindow, setContextWindow] = useState(
    Math.round(settings.contextWindow || 1)
  );
  const [buttonState, setButtonState] = useState<AnimationState>("ready");

  // Reset state when modal opens
  useEffect(() => {
    if (isOpen) {
      setResultCount(Math.round(settings.resultCount || 3));
      setSimilarityThreshold(
        Math.round((settings.similarityThreshold || 0.7) * 100)
      );
      setContextWindow(Math.round(settings.contextWindow || 1));
    }
  }, [isOpen, settings]);

  const handleResultCountChange = (value: number) => {
    setResultCount(Math.round(value));
  };

  const handleSimilarityChange = (value: number) => {
    setSimilarityThreshold(Math.round(value));
  };

  const handleContextWindowChange = (value: number) => {
    setContextWindow(Math.round(value));
  };

  const handleSubmit = async () => {
    setButtonState("loading");
    try {
      onUpdate({
        resultCount: Math.round(resultCount),
        similarityThreshold: similarityThreshold / 100, // Convert back to decimal
        contextWindow: Math.round(contextWindow),
      });
      setButtonState("success");
      onClose();
    } catch (error) {
      setButtonState("error");
    }
  };

  const resetAndClose = () => {
    onClose();
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={resetAndClose} title={title}>
      <div className="flex flex-col gap-6 mb-6">
        <ConfigSlider
          name="Result Count"
          shortDescription="Number of similar documents to retrieve (default is 3)"
          min={1}
          max={10}
          value={resultCount}
          rangeLabels={["Fewer results", "More results"]}
          onChange={handleResultCountChange}
          classNameIn="font-sans font-medium"
        />

        <ConfigSlider
          name="Similarity Threshold"
          shortDescription="Set the minimum similarity score required for results (default is 70)"
          min={1}
          max={100}
          value={similarityThreshold}
          rangeLabels={["Random", "Exact match"]}
          onChange={handleSimilarityChange}
          classNameIn="font-sans font-medium"
        />

        <ConfigSlider
          name="Context Window"
          shortDescription="Number of surrounding chunks to include for context (default is 1)"
          min={1}
          max={5}
          value={contextWindow}
          rangeLabels={["Focused", "Broad"]}
          onChange={handleContextWindowChange}
          classNameIn="font-sans font-medium"
        />
      </div>

      <div className="flex justify-end gap-3">
        <button
          onClick={resetAndClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <AnimatedButton
          title="Save Settings"
          onClick={handleSubmit}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          triggerKey="Enter"
        />
      </div>
    </CommonModal>
  );
};
