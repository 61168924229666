import React from "react";
import { DeleteDatabaseData } from "../../Map/Nodes/DatabaseNode";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";

interface DeleteComponentProps {
  data: DeleteDatabaseData;
  availableVariables: string[];
  updateNode: (newData: Partial<DeleteDatabaseData>) => void;
}

export const DeleteComponent: React.FC<DeleteComponentProps> = ({
  data,
  availableVariables,
  updateNode,
}) => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Data Point</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.recordId}
          setSelectedInput={(input) => updateNode({ recordId: input })}
          currentNodeId={data.id}
          id="deleteQuery"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Select the input that contains the ID of the record to delete
        </div>
      </div>

      <div className="flex items-center gap-2">
        <label className="flex items-center gap-2 text-sm text-gray-600">
          <input
            type="checkbox"
            checked={data.isAsync ?? false}
            onChange={(e) => updateNode({ isAsync: e.target.checked })}
            className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          />
          Run asynchronously
        </label>
      </div>
    </div>
  );
};
