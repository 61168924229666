import React, { useState } from "react";

import { Portal } from "../../../models/Portal";
import { CommonModal } from "../../Common/CommonModal";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import { ExternalPath } from "../../../models/ExternalPath";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { usePortalService } from "../../../contexts/PortalContext";
import { useNavigate } from "react-router-dom";
import { AppPath } from "../../../models/AppPath";

interface NoVersionsModalProps {
  portal: Portal;
  shows: boolean;
  teamId: string;
  setShows: (shows: boolean) => void;
}

export const NoVersionsModal: React.FC<NoVersionsModalProps> = ({
  portal,
  shows,
  teamId,
  setShows,
}) => {
  const portalService = usePortalService();
  const navigate = useNavigate();
  const handleDismiss = () => {};
  const [createState, setCreateState] = useState<AnimationState>("ready");

  const handleCreateVersion = async () => {
    setCreateState("loading");
    try {
      const newVersion = await portalService.createVersion(teamId, portal.id);
      setCreateState("success");
      navigate(AppPath.portalVersion(teamId, portal.id!, newVersion.id!));
      setShows(false);
    } catch {
      setCreateState("error");
    }
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss} title="No Versions!">
      <div className="flex justify-end text-gray-200 hover:text-gray-300"></div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between text-xs text-gray-500 gap-2">
          <span> Created</span>
          <span>{formatDate(portal.createdAt)}</span>
          <span>{formatTime(portal.createdAt)}</span>
        </div>
        <div
          id="portalId"
          className="flex justify-between text-xs text-gray-500 gap-2"
        >
          {`Prompt ID: ${portal.id}`}
        </div>
      </div>
      <div className="text-center flex flex-col gap-1 mt-4 mb-4">
        <>
          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs ">{portal.name}</div>
          </div>
          <div className="font-gooper text-2xl font-semibold text-gray-800">
            <span>{`No Version!`}</span>{" "}
          </div>
          <div className="font-normal text-gray-500">
            <span>
              Create a version for this Prompt. Time to get rocking & rolling.
              😎
            </span>{" "}
          </div>
        </>

        <a
          href={ExternalPath.promptDocs()}
          target="_blank"
          className="text-blue-400 hover:text-blue-500 underline mt-2"
        >
          Learn more about Prompts here.
        </a>
      </div>

      <AnimatedButton
        title={`Create Version`}
        onClick={handleCreateVersion}
        buttonState={createState}
        style={"normal"}
        key="createButton"
        font="font-sans"
        id="createButton"
      />
    </CommonModal>
  );
};
