import React, { useState, useEffect } from "react";
import "reactflow/dist/style.css";
import { WorkflowConversionProblem } from "../../../../services/WorkflowConverter";
import Icon from "../../../Icon";
import AnimatedButton from "../../../AnimatedButton";
import { useNodeZoom } from "../../../../utils/ZoomToNode";

interface ErrorListProps {
  problems: WorkflowConversionProblem[];
  onDismiss: (problem: WorkflowConversionProblem) => void;
  onTap: (problem: WorkflowConversionProblem) => void;
  clearAll: (problems: WorkflowConversionProblem[]) => void;
}

export const ProblemList: React.FC<ErrorListProps> = ({
  problems,
  onTap,
  clearAll,
}) => {
  const [isHiding, setIsHiding] = useState(false);

  const { zoomToNode, getNodeTitle } = useNodeZoom({
    duration: 800, // optional
    padding: 0.1, // optional
  });

  // Determine whether to hide the component
  const hide = isHiding || problems.length === 0;

  // Handle the "Clear all" button click
  const handleClearAll = () => {
    if (problems.length > 0 && !isHiding) {
      setIsHiding(true); // Start hide animation
      setTimeout(() => {
        clearAll(problems); // Clear problems after animation
      }, 300); // Duration should match the CSS transition duration
    }
  };

  // Reset isHiding based on problems prop
  useEffect(() => {
    if (problems.length > 0) {
      setIsHiding(false); // Show the component if there are problems
    } else {
      setIsHiding(true); // Hide the component if there are no problems
    }
  }, [problems.length]);

  return (
    <div
      id={`problemList`}
      aria-hidden={hide}
      className={`overflow-y-auto min-h-screen max-h-screen bg-blue-50 flex flex-col gap-y-2 px-2 absolute top-0 left-0 transition-transform duration-300 transform ${
        hide ? "-translate-x-full" : "translate-x-0"
      }`}
    >
      {/* Header */}
      <div className="flex flex-row bg-blue-50 justify-between pt-4 items-center sticky top-0 z-10 -mx-2 px-2">
        <div className="font-gooper text-gray-900 font-medium">
          Outstanding Issues
        </div>
        <button
          id={`problemListClearButton`}
          onClick={handleClearAll}
          className="h-10 text-blue-500 underline"
        >
          Clear
        </button>
      </div>

      {/* Problem Items */}
      <div className="flex flex-col gap-y-2 px-2 py-2">
        {problems.map((problem) => (
          <div
            key={problem.id}
            className="flex w-72 flex-col bg-white border border-gray-200 rounded-lg relative p-2 shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-200"
          >
            <div className="flex flex-row justify-between items-center">
              <div className="text-gray-700 font-gooper text-sm">
                {getNodeTitle(problem.nodeId)}
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onTap(problem);
                }}
                className="text-gray-400 hover:text-gray-600"
              >
                <Icon type="x-no-circle" className="size-5" />
              </button>
            </div>

            <div className="text-gray-700 text-sm pb-4">{problem.message}</div>
            <AnimatedButton
              title="See issue"
              onClick={() => {
                zoomToNode(problem.nodeId);
              }}
              buttonState="ready"
              setButtonState={() => {}}
              style="secondary"
              classNameIn={"min-h-6 max-h-6"}
              font="font-sans font-medium text-xs"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProblemList;
