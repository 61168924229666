import React from "react";
import VerticalNavLayout from "./Navigation/VerticalNavLayout";
import { CommonLoading } from "./Common/CommonLoading";

const TeamLoading: React.FC = () => {
  return (
    <VerticalNavLayout
      team={undefined}
      alerts={[]}
      currentUser={undefined}
      teamSubscription={undefined}
      featureFlags={[]}
    >
      <CommonLoading />
      <div className="bg-general-desktop bg-cover bg-center min-h-screen flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center pt-24 gap-4">
          <div className="text-center text-indigo-50 text-9xl font-semibold font-gooper leading-none">
            Loading
          </div>
          <div className="text-center text-blue-950 text-4xl font-bold font-gooper leading-none">
            just a sec
          </div>
        </div>
      </div>
    </VerticalNavLayout>
  );
};

export default TeamLoading;
