import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Icon, { IconType } from "../Icon";
import { CommonSpinner } from "../Common/CommonLoading";
import { CommonErrorToast } from "../Common/CommonErrorToast";

interface NavigationLinkProps {
  title: string;
  icon: IconType;
  destination?: string;
  link?: string;
  isActive: boolean;
  teamId: string;
  isCollapsed: boolean;
  actionLink?: string;
  id?: string;
  action?: () => Promise<void>;
  error?: string | null;
  setError?: (error: string | null) => void;
  alerts?: number;
}

const contentVariants = {
  expanded: {
    width: "auto",
    opacity: 1,
    transition: {
      width: { duration: 0.2, ease: "easeOut" },
      opacity: { duration: 0.1, delay: 0.1 },
    },
  },
  collapsed: {
    width: 0,
    opacity: 0,
    transition: {
      width: { duration: 0.2, ease: "easeOut" },
      opacity: { duration: 0.1 },
    },
  },
};

const NavigationLink: React.FC<NavigationLinkProps> = ({
  title,
  destination,
  icon,
  isActive,
  teamId,
  isCollapsed,
  actionLink,
  id,
  action,
  error,
  setError,
  alerts,
  link,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleActionClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (actionLink) {
      navigate(actionLink);
    } else if (action) {
      setLoading(true);
      try {
        await action();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMainClick = (event: React.MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault();
      if (destination) {
        navigate(`/teams/${teamId}/${destination}`);
      } else if (link) {
        window.open(link, "_blank", "noopener,noreferrer");
      }
    }
  };

  const url = `/teams/${teamId}/${destination}`;

  return (
    <motion.a
      href={url}
      layout
      className={`relative flex items-center ${
        isCollapsed ? "justify-center" : "justify-between"
      } gap-2 py-2 px-1 hover:bg-blue-50 rounded-lg font-gooper ${
        isActive ? "text-blue-500" : "text-gray-800"
      }`}
      onClick={handleMainClick}
      id={id}
    >
      {error && (
        <CommonErrorToast message={error} onClose={() => setError?.(null)} />
      )}
      <div className="flex justify-center relative items-center">
        <Icon
          type={icon}
          className={`size-5 ${isCollapsed ? "" : "ml-2"}  ${
            isCollapsed && isActive ? "text-blue-400" : "text-gray-300"
          }`}
        />
        <motion.span
          variants={contentVariants}
          initial={false}
          animate={isCollapsed ? "collapsed" : "expanded"}
          className={`${
            isCollapsed ? "" : "pl-2"
          } text-base  font-medium leading-normal overflow-hidden truncate`}
        >
          {title}
        </motion.span>
      </div>

      {loading && <CommonSpinner size="sm" />}

      {(actionLink || action) && !loading && !isCollapsed && (
        <motion.button
          variants={contentVariants}
          initial={false}
          animate={isCollapsed ? "collapsed" : "expanded"}
          onClick={handleActionClick}
          className="ml-auto relative z-10 overflow-hidden"
          type="button"
        >
          <Icon type="plus-inverted" className="text-blue-500 size-4" />
        </motion.button>
      )}

      {(alerts ?? 0) > 0 && (
        <motion.div
          initial={false}
          animate={{
            opacity: 1,
            x: isCollapsed ? -20 : 0,
            scale: isCollapsed ? 0.8 : 1,
            transition: {
              delay: isCollapsed ? 0 : 0.1,
              duration: 0.2,
              ease: "easeOut",
            },
          }}
          className={`w-5 absolute ${
            isCollapsed ? "-right-2" : "-right-0"
          }  z-0 h-5 px-2.5 py-0.5 bg-blue-50 rounded-3xl justify-center items-center inline-flex`}
        >
          <div className="text-center text-blue-600 text-xs font-medium leading-none">
            {alerts}
          </div>
        </motion.div>
      )}
    </motion.a>
  );
};

export default NavigationLink;
