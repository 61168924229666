import React, { useState, useRef } from "react";
import { CommonModal } from "../Common/CommonModal";
import Icon from "../Icon";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { DatabaseKnowledge } from "../../models/DatabaseKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { ENCODING_STRATEGIES, StrategyButton } from "./AddWebsiteModal";
import { ChunkEncodingStrategy } from "../../models/ChunkEncodingStrategy";

type StrategyKey = keyof typeof ENCODING_STRATEGIES;

interface ImportJsonModalProps {
  dataTable: DatabaseKnowledge;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const ImportJsonModal: React.FC<ImportJsonModalProps> = ({
  dataTable,
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [importState, setImportState] = useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);
  const [importResult, setImportResult] = useState<{
    successCount: number;
    message: string;
    failedItems?: Array<{ index: number; recordId?: string; reason: string }>;
  } | null>(null);
  const [appendContent, setAppendContent] = useState<boolean>(false);

  // Use the strategy selector from AddWebsiteModal
  const [selectedStrategy, setSelectedStrategy] =
    useState<StrategyKey>("balanced");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type !== "application/json") {
        setError("Please select a JSON file");
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleImport = async () => {
    if (!selectedFile) {
      setError("Please select a JSON file to import");
      return;
    }

    setImportState("loading");
    setError(null);

    try {
      // Read the file content
      const fileContent = await readFileAsText(selectedFile);

      // Validate JSON format
      try {
        JSON.parse(fileContent);
      } catch (e) {
        throw new Error("Invalid JSON format in the selected file");
      }

      // Get encoding strategy from the selected strategy
      const { chunkSize, chunkOverlap, label } =
        ENCODING_STRATEGIES[selectedStrategy];
      const encodingStrategy: ChunkEncodingStrategy = {
        chunkSize,
        chunkOverlap,
        name: label,
      };

      // Call the import API
      const result = await knowledgeService.importJsonToDatabase(
        teamId,
        dataTable.id!,
        fileContent,
        encodingStrategy,
        appendContent
      );

      setImportResult(result);
      setImportState("success");

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error("Import failed:", err);
      setError(err instanceof Error ? err.message : "Import failed");
      setImportState("error");
    }
  };

  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result as string);
        } else {
          reject(new Error("Failed to read file"));
        }
      };
      reader.onerror = () => reject(new Error("Failed to read file"));
      reader.readAsText(file);
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type !== "application/json") {
        setError("Please select a JSON file");
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const resetState = () => {
    setSelectedFile(null);
    setImportState("ready");
    setImportResult(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onClose}
      title={`Import JSON Data to ${dataTable.name}`}
    >
      <div className="p-6 overflow-y-auto">
        {importResult ? (
          <div className="flex flex-col gap-4">
            <div className="bg-green-50 border border-green-200 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-green-800 mb-2">
                Import Complete
              </h3>
              <p className="text-green-700">{importResult.message}</p>
            </div>

            {importResult.failedItems &&
              importResult.failedItems.length > 0 && (
                <div className="bg-yellow-50 border border-yellow-200 p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-yellow-800 mb-2">
                    Failed Items
                  </h3>
                  <div className="max-h-60 overflow-y-auto">
                    <ul className="list-disc pl-5">
                      {importResult.failedItems.map((item, index) => (
                        <li key={index} className="text-yellow-700 mb-2">
                          <span className="font-medium">
                            Item {item.index + 1}
                            {item.recordId ? ` (ID: ${item.recordId})` : ""}:
                          </span>{" "}
                          {item.reason}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

            <div className="flex justify-end gap-3 mt-4">
              <button
                onClick={resetState}
                className="px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm hover:bg-blue-50"
              >
                Import Another File
              </button>
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="text-sm font-medium text-gray-700 mb-3">
                JSON Format Requirements
              </h4>
              <p className="text-sm text-gray-600 mb-2">
                The JSON file should contain an array of objects with the
                following structure:
              </p>
              <pre className="bg-gray-100 p-3 rounded text-xs overflow-x-auto">
                {`[
  {
    "content": "Text content to be stored and embedded",
    "recordId": "optional-unique-id", // If not provided, a UUID will be generated
    "metadata": {
      "field1": "value1",
      "field2": "value2"
      // Metadata fields should match your data table configuration
    }
  },
  // More items...
]`}
              </pre>
            </div>

            <div
              className={`border-2 border-dashed rounded-lg p-8 text-center ${
                selectedFile
                  ? "border-green-300 bg-green-50"
                  : "border-gray-300 bg-gray-50"
              }`}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {selectedFile ? (
                <div className="flex flex-col items-center">
                  <Icon
                    type="import"
                    className="w-10 h-10 text-green-500 mb-3"
                  />
                  <p className="text-lg font-medium text-gray-900">
                    {selectedFile.name}
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                  </p>
                  <button
                    onClick={resetState}
                    className="mt-3 text-sm text-blue-600 hover:text-blue-700"
                  >
                    Change File
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <Icon
                    type="import"
                    className="w-10 h-10 text-gray-400 mb-3"
                  />
                  <p className="text-lg font-medium text-gray-900">
                    Drag and drop your JSON file here
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    or{" "}
                    <label className="text-blue-600 hover:text-blue-700 cursor-pointer">
                      browse
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".json"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </p>
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Encoding Strategy
              </label>
              <div className="grid grid-cols-3 gap-4">
                {(
                  Object.entries(ENCODING_STRATEGIES) as [
                    StrategyKey,
                    (typeof ENCODING_STRATEGIES)[StrategyKey]
                  ][]
                ).map(([key, strategy]) => (
                  <StrategyButton
                    key={key}
                    strategyKey={key}
                    strategy={strategy}
                    isSelected={selectedStrategy === key}
                    onClick={() => setSelectedStrategy(key)}
                  />
                ))}
              </div>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="text-sm font-medium text-gray-700 mb-3">
                Import Options
              </h4>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="appendContent"
                  checked={appendContent}
                  onChange={() => setAppendContent(!appendContent)}
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label
                  htmlFor="appendContent"
                  className="ml-2 text-sm text-gray-700"
                >
                  Append to existing records (if record IDs match)
                </label>
              </div>
            </div>

            {error && (
              <div className="bg-red-50 border border-red-200 p-3 rounded-lg text-red-600 text-sm">
                {error}
              </div>
            )}

            <div className="flex justify-end gap-3 mt-2">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
              <AnimatedButton
                title="Import Data"
                onClick={handleImport}
                buttonState={importState}
                setButtonState={setImportState}
                style="action"
                error={error}
                setError={setError}
                disabled={!selectedFile}
              />
            </div>
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default ImportJsonModal;
