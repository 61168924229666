import { FC, useState, useEffect } from "react";
import { Team } from "../../../../models/Team";
import { WebsiteNodeData } from "../../Map/Nodes/WebsiteNode";
import { CommonModal } from "../../../Common/CommonModal";
import { VariableInput } from "../../../Portals/Portal Detail/VersionDemor";
import { useTestService } from "../../../../contexts/TestContext";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface WebsiteTestModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  data: WebsiteNodeData;
  team: Team;
}

interface VariableMap {
  [key: string]: string;
}

interface VariableMention {
  display: string;
  id: string;
}

export const WebsiteTestModal: FC<WebsiteTestModalProps> = ({
  shows,
  data,
  setShows,
  team,
}) => {
  // State Management
  const [response, setResponse] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);

  // Variable States
  const [variables, setVariables] = useState<VariableMap>({});
  const [userSecrets, setUserSecrets] = useState<VariableMap>({});
  const [outputs, setOutputs] = useState<VariableMap>({});
  const [conversationalData, setConversationalData] = useState<VariableMap>({});

  const testService = useTestService();

  const extractMentions = (text: string) => {
    const mentions = {
      variables: new Set<VariableMention>(),
      userSecrets: new Set<VariableMention>(),
      outputs: new Set<VariableMention>(),
      conversationalData: new Set<VariableMention>(),
    };

    // Extract complex mentions with display text
    const mentionRegex =
      /@\[(?:(variables|userSecrets|outputs|conversationalData))\.([^\]]+)\]\((?:\1)\.([^)]+)\)/g;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const [_, type, display, id] = match;
      mentions[type as keyof typeof mentions].add({ display, id });
    }

    // Extract simple mentions without display text
    const simpleRegex = /(variables|userSecrets|conversationalData)\.([\w]+)/g;
    while ((match = simpleRegex.exec(text)) !== null) {
      const [_, type, key] = match;
      if (!text.includes(`@[${type}.${key}]`)) {
        mentions[
          type as "variables" | "userSecrets" | "conversationalData"
        ].add({
          display: key,
          id: key,
        });
      }
    }

    return mentions;
  };

  useEffect(() => {
    if (shows) {
      const mentions = extractMentions(data.url);

      setVariables(
        Object.fromEntries([...mentions.variables].map((v) => [v.display, ""]))
      );
      setUserSecrets(
        Object.fromEntries(
          [...mentions.userSecrets].map((v) => [v.display, ""])
        )
      );
      setOutputs(
        Object.fromEntries([...mentions.outputs].map((v) => [v.display, ""]))
      );
      setConversationalData(
        Object.fromEntries(
          [...mentions.conversationalData].map((v) => [v.display, ""])
        )
      );
    }
  }, [shows, data]);

  const renderVariableSection = (
    title: string,
    values: VariableMap,
    setValues: (values: VariableMap) => void
  ) => {
    if (Object.keys(values).length === 0) return null;

    return (
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">{title}</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(values).map(([key, value]) => (
            <VariableInput
              key={key}
              title={key}
              value={value}
              setNewValue={(newValue) => {
                setValues({
                  ...values,
                  [key]: newValue,
                });
              }}
              className="w-full"
            />
          ))}
        </div>
      </div>
    );
  };

  const testWebsite = async () => {
    setButtonState("loading");
    setError(null);
    setResponse(null);

    try {
      const response = await testService.runWorkflowWebsiteTest(
        team.id!,
        data.url,
        outputs,
        userSecrets,
        variables,
        conversationalData,
        data.filterOptions
      );

      setResponse(response);
      setButtonState("success");
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to process website. Please try again."
      );
      setButtonState("error");
    }
  };

  return (
    <CommonModal
      isOpen={shows}
      onDismiss={() => setShows(false)}
      title={`Preview: ${data.title}`}
    >
      <div className="grid grid-cols-1 gap-6 mb-6">
        {renderVariableSection("Variables", variables, setVariables)}
        {renderVariableSection("User Secrets", userSecrets, setUserSecrets)}
        {renderVariableSection("Previous Step Outputs", outputs, setOutputs)}
        {renderVariableSection(
          "Conversational Data",
          conversationalData,
          setConversationalData
        )}
      </div>

      <div className="space-y-4">
        <h3 className="font-medium mb-2">Request Details</h3>
        <div className="grid grid-cols-1 gap-4">
          <div className="bg-gray-50 p-4 rounded-md border border-gray-200">
            <div>
              <p className="text-sm text-gray-600">URL</p>
              <p className="font-mono break-all">{data.url}</p>
            </div>

            <div className="mt-4">
              <p className="text-sm text-gray-600 mb-2">Filter Options</p>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(data.filterOptions).map(([key, value]) => (
                  <div key={key} className="flex items-center gap-2">
                    <span className="text-sm text-gray-600">
                      {key.replace(/([A-Z])/g, " $1").trim()}:
                    </span>
                    <span className="font-mono">
                      {Array.isArray(value)
                        ? value.join(", ")
                        : value.toString()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="p-4 bg-red-50 text-red-500 rounded-md border border-red-200 mt-4">
          <p className="font-medium">Error</p>
          <p className="mt-1">{error}</p>
        </div>
      )}

      {response && (
        <div className="flex flex-col gap-2 mt-4">
          <p className="font-medium">Processed Content:</p>
          <div className="p-4 bg-gray-50 rounded-md border border-gray-200 font-mono text-sm overflow-auto max-h-[600px] whitespace-pre-wrap">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              className={`prose text-sm text-slate-900`}
            >
              {response}
            </ReactMarkdown>
          </div>
        </div>
      )}

      <div className="flex-none px-6 py-4 border-t border-gray-200">
        <AnimatedButton
          title="Process Website"
          onClick={testWebsite}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          font="font-sans"
          classNameIn="w-full"
          after={(success) => {
            if (!success) {
              setButtonState("ready");
            }
          }}
        />
      </div>
    </CommonModal>
  );
};
