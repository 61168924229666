import React, { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationLink from "./NavigationLink";
import TeamSelector from "./TeamSelector";
import NavigationUpsellButton from "./NavigationUpsellButton";
import NavigationHeader from "./NavigationHeader";
import { NavigationResubButton } from "./NavigationResubButton";
import { usePortalService } from "../../contexts/PortalContext";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { AppPath } from "../../models/AppPath";
import { Team } from "../../models/Team";
import { TeamAlert } from "../../models/TeamAlert";
import { User } from "../../models/User";
import { TeamSubscription } from "../../models/TeamSubscription";
import { useAnalytics } from "../../contexts/AnalyticsContext";
import { ExternalPath } from "../../models/ExternalPath";

interface VerticalNavLayoutProps {
  children: ReactNode;
  team: Team | undefined;
  alerts: TeamAlert[];
  currentUser: User | undefined;
  teamSubscription: TeamSubscription | undefined;
  featureFlags: string[];
}

const COLLAPSED_WIDTH = "w-16";
const ANIMATION_DURATION = 0.2;

const VerticalNavLayout: React.FC<VerticalNavLayoutProps> = ({
  children,
  team,
  alerts,
  currentUser,
  teamSubscription,
}) => {
  const location = useLocation();
  const portalService = usePortalService();
  const workflowService = useWorkflowService();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const [portalError, setPortalError] = useState<string | null>(null);
  const [workflowError, setWorkflowError] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const isActive = (path: string) => location.pathname.includes(path);

  const handleCreateWorkflow = async () => {
    try {
      const newWorkflow = await workflowService.createWorkflow(team!.id!);
      analytics.track("createdAgent");
      window.location.replace(AppPath.workflow(team!.id!, newWorkflow.id!));
    } catch (e) {
      setWorkflowError(
        e instanceof Error
          ? e.message
          : "Failed to create agent. Please try again."
      );
    }
  };

  const handleCreatePortal = async () => {
    setPortalError(null);
    try {
      const result = await portalService.createPortal(team!.id!);
      analytics.track("createdPrompt");

      navigate(
        AppPath.portalVersion(
          team!.id!,
          result.portal.id,
          result.version.id!,
          true
        )
      );
    } catch (e) {
      setPortalError(
        e instanceof Error
          ? e.message
          : "Failed to create prompt. Please try again."
      );
    }
  };

  return (
    <div className="flex h-[calc(100dvh)] overflow-y-hidden overflow-x-hidden overscroll-none">
      <div className={COLLAPSED_WIDTH} />

      <div
        className="fixed left-0 top-0 h-full z-20"
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
        onClick={() => setIsExpanded(false)}
      >
        <div
          style={{
            width: isExpanded ? "12rem" : "4rem",
            paddingLeft: isExpanded ? "0.5rem" : "0",
            paddingRight: isExpanded ? "0.5rem" : "0",
            transition: `all ${ANIMATION_DURATION}s ease-out`,
          }}
          className="flex flex-col gap-y-4 pt-3 bg-gray-100 border-r border-gray-200 h-full"
        >
          <NavigationHeader isCollapsed={!isExpanded} />

          <TeamSelector
            isCollapsed={!isExpanded}
            currentTeam={team}
            currentUser={currentUser}
          />

          <div className="flex-grow pt-2">
            <div className="list-none">
              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="home"
                destination="home"
                title="Home"
                isActive={isActive("home")}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="workflows"
                destination="agents"
                title="Agents"
                id="agentsButton"
                isActive={isActive("agents")}
                action={handleCreateWorkflow}
                error={workflowError}
                setError={setWorkflowError}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="portals"
                destination="prompts"
                title="Prompts"
                isActive={isActive("prompts")}
                action={handleCreatePortal}
                error={portalError}
                setError={setPortalError}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="book-open"
                destination="knowledge"
                title="Knowledge"
                isActive={isActive("knowledge")}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="database"
                destination="data"
                title="Data"
                isActive={isActive("data")}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="logs"
                destination="logs"
                title="Logs"
                isActive={isActive("logs")}
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="chart-pie"
                destination="analytics"
                title="Analytics"
                isActive={isActive("analytics")}
                id="analyticsButton"
              />

              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="flask"
                destination="test-center"
                title="Test Center"
                isActive={isActive("test-center")}
              />
            </div>
          </div>

          <div className="w-full flex flex-col gap-y-2 pb-4">
            <div>
              <NavigationLink
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="profile"
                destination="profile"
                title="Profile"
                isActive={isActive("/profile")}
              />
              <NavigationLink
                id="notificationsButton"
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="bell"
                destination="notifications"
                title="Notifications"
                isActive={isActive("notifications")}
                alerts={alerts.filter((alert) => !alert.isRead).length}
              />
              <NavigationLink
                id="teamSettingsButton"
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="settings"
                destination="settings"
                title="Settings"
                isActive={isActive("settings")}
              />
              <NavigationLink
                id="docsButton"
                isCollapsed={!isExpanded}
                teamId={team?.id!}
                icon="book"
                link={ExternalPath.docs()}
                title="Docs"
                isActive={false}
              />
            </div>

            <div className="px-2 hidden sm:block">
              {teamSubscription?.currentEvent?.willRenew === false ? (
                <NavigationResubButton
                  teamId={team?.id ?? ""}
                  isCollapsed={!isExpanded}
                />
              ) : (
                <NavigationUpsellButton
                  isCollapsed={!isExpanded}
                  teamId={team?.id ?? ""}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full" id="page-content-wrapper">
        <div>{children}</div>
      </div>
    </div>
  );
};

export default VerticalNavLayout;
