import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import { KnowledgeSections } from "./KnowledgeSections";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import { AppPath } from "../../models/AppPath";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { WebsiteKnowledge } from "../../models/WebsiteKnowledge";
import { AddWebsiteModal } from "./AddWebsiteModal";
import { ManageWebsiteModal } from "./ManageWebsiteModal";
import { StatusBadge } from "../TestCenter/StatusBadge";
import { formatDate, formatTime } from "../../utils/DateUtils";

export const KnowledgeWebsites: React.FC = () => {
  const knowledgeService = useKnowledgeService();

  const { teamId } = useParams<{ teamId: string }>();
  const teamService = useTeams();

  const [websites, setWebsites] = useState<WebsiteKnowledge[]>([]);
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAddWebsite, setShowAddWebsite] = useState(false);
  const [addWebsiteState, setAddWebsiteState] =
    useState<AnimationState>("ready");

  const [searchParams, setSearchParams] = useSearchParams();
  const managingWebsiteId = searchParams.get("manage");
  const managingWebsite = websites.find((w) => w.id === managingWebsiteId);
  const observationRef = useRef<(() => void) | null>(null);

  const handleManageClick = (websiteId: string) => {
    setSearchParams({ manage: websiteId });
  };

  const handleModalClose = () => {
    setSearchParams({});
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedTeam = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );

        if (fetchedTeam) {
          setTeam(fetchedTeam);
        }

        // Set up the observation of the websites list
        observationRef.current?.();
        const observation = knowledgeService.websiteKnowledgeRepo.observeList(
          knowledgeService.websiteKnowledgePath(teamId!),
          (updatedWebsites) => {
            const sortedWebsites = updatedWebsites.sort((a, b) => {
              // Handle cases where lastUpdate might be undefined
              if (!a.lastUpdate) return 1; // Move items without dates to the end
              if (!b.lastUpdate) return -1; // Move items without dates to the end

              // Convert dates to timestamps for comparison
              const dateA = new Date(a.lastUpdate).getTime();
              const dateB = new Date(b.lastUpdate).getTime();

              // Sort descending (newest first)
              return dateB - dateA;
            });
            setWebsites(sortedWebsites);
          }
        );
        observationRef.current = observation;
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went wrong while loading website sources");
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();

    // Cleanup function to remove the observation when the component unmounts
    return () => {
      observationRef.current?.();
    };
  }, [teamService, knowledgeService, teamId]);

  return (
    <CommonContainer>
      {managingWebsite && (
        <ManageWebsiteModal
          website={managingWebsite}
          teamId={teamId!}
          isOpen={!!managingWebsiteId}
          onClose={handleModalClose}
          onSuccess={() => console.log()}
        />
      )}
      <CommonHeader
        title="Knowledge Base"
        subtitle="Add and manage website that you can enrich your AI with."
        sections={[
          { name: "Knowledge", link: AppPath.settings(teamId ?? "") },
          { name: "Websites", link: AppPath.knowledge(teamId ?? "") },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Add Website"
            onClick={() => setShowAddWebsite(true)}
            buttonState={addWebsiteState}
            setButtonState={setAddWebsiteState}
            style="action"
            key="addWebsite"
            error={error}
            setError={setError}
            id="addWebsiteButton"
            leftIcon="plus"
          />,
        ]}
      />
      <FailureModal
        message={error ?? undefined}
        shows={error !== null}
        closed={() => setError("")}
      />
      <KnowledgeSections selectedId="websites" />
      {showAddWebsite && (
        <AddWebsiteModal
          teamId={teamId!}
          isOpen={showAddWebsite}
          onClose={() => setShowAddWebsite(false)}
          onSuccess={() => console.log()}
        />
      )}
      {loading && <CommonSpinner />}
      {team && (
        <div className="flex flex-col gap-2 mt-4">
          {websites.map((website) => (
            <WebsiteRow
              key={website.id}
              website={website}
              onManageClick={() => handleManageClick(website.id!)}
            />
          ))}
          {websites.length === 0 && (
            <div className="text-center text-gray-500 py-8">
              No websites added yet. Click "Add Website" to get started.
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};

interface WebsiteRowProps {
  website: WebsiteKnowledge;
  onManageClick: () => void;
}

export const WebsiteRow: React.FC<WebsiteRowProps> = ({
  website,
  onManageClick,
}) => {
  return (
    <div className="px-6 bg-white shadow h-28 rounded-lg grid grid-cols-[1fr_auto] items-center w-full">
      <div className="min-w-0 flex gap-2 flex-col">
        <div className="flex row">
          <div className="text-lg font-medium truncate">
            {website.title ?? website.url}
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center text-xs text-gray-500">
          <StatusBadge status={website.status} />
          {`${formatDate(website.lastUpdate)} ${formatTime(
            website.lastUpdate
          )}`}
        </div>
      </div>
      <div className="flex items-center gap-6 pl-4">
        <div className="text-gray-400 text-sm">
          {website.encodingStrategy.name}
          {website.successCount && (
            <div className="text-xs font-medium truncate">
              {`${website.successCount} subpage${
                website.successCount === 1 ? "" : "s"
              }`}
            </div>
          )}
        </div>
        <AnimatedButton
          title="Manage"
          style="action"
          setButtonState={undefined}
          buttonState="ready"
          onClick={onManageClick}
          classNameIn="w-32"
          leftIcon="share-nodes"
          font="font-sans"
          id={`manage${website.id}Button`}
        />
      </div>
    </div>
  );
};
