import React, { useEffect, useRef, useState } from "react";
import { VersionDemoer } from "../../../Portals/Portal Detail/VersionDemor";
import { Team } from "../../../../models/Team";
import Icon from "../../../Icon";
import { Workflow, WorkflowVersion } from "../../../../models/Workflow";
import { useWorkflowService } from "../../../../contexts/WorkflowContext";
import { WorkflowDemoResult } from "../../../../models/WorkflowDemoResult";
import { AwaitingResultCard } from "./DemoResultCards/AwaitingResultCard";
import { WorkflowResultCard } from "./WorkflowResultCard";

interface WorkflowDemoModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  workflow: Workflow;
  version: WorkflowVersion;
  variables: { [id: string]: string };
  setVariables: (variables: { [id: string]: string }) => void;
  team: Team;
  sessionId: string;
  setSessionId: (sessionId: string) => void;
}

export const WorkflowDemoModal: React.FC<WorkflowDemoModalProps> = ({
  shows,
  workflow,
  team,
  variables,
  setVariables,
  setShows,
  sessionId,
  setSessionId,
  version,
}) => {
  const workflowService = useWorkflowService();
  const [results, setResults] = useState<WorkflowDemoResult[]>([]);
  const observationRef = useRef<() => void>();

  useEffect(() => {
    if (shows && sessionId) {
      observationRef.current?.();
      setResults([]);
      const observation = workflowService.workflowDemoResultRepo.observeList(
        workflowService.workflowDemoResultPath(
          team.id!,
          workflow.id!,
          version.id!
        ),
        (results) => {
          setResults(results);
        },
        { name: "createdAt", descending: false },
        undefined,
        undefined,
        [{ key: "sessionId", filter: "==", value: sessionId }]
      );
      observationRef.current = observation;
    }
  }, [shows, team.id, workflow.id, workflowService, sessionId]);

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShows(false);
    }
  };

  if (!shows) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
      onClick={handleBackgroundClick}
    >
      <div className="flex flex-row p-2 h-full">
        <button
          onClick={() => setShows(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close modal"
        >
          <Icon type="close" />
        </button>
        <div className="w-[500px]">
          <VersionDemoer
            data={{
              type: "workflow",
              workflowId: workflow.id!,
              versionId: version.id!,
            }}
            teamId={team.id!}
            demoConfigVariables={variables}
            setDemoConfigVariables={setVariables}
            teamName={team.name}
            setSessionId={setSessionId}
          />
        </div>
        <div className="bg-gray-0 w-[406px] flex flex-col p-4 gap-2 rounded-lg overflow-y-scroll">
          {results.length == 0 && <AwaitingResultCard />}
          {results.map((result) => (
            <WorkflowResultCard result={result} teamId={team.id!} />
          ))}
        </div>
      </div>
    </div>
  );
};
