import React, { useMemo } from "react";
import { TeamSubscription } from "../../models/TeamSubscription";

// Cost in dollars per 1 million tokens
const MODEL_INPUT_TOKEN_VALUE: Record<string, number> = {
  "gpt-4o": 5.0,
  "gpt-4o-2024-08-06": 5.0,
  "gpt-4-turbo": 10.0,
  "gpt-4": 30.0,
  "gpt-3.5-turbo-0125": 0.5,
  "gemini-1.5-pro": 3.5,
  "gemini-1.5-flash": 0.35,
  "gemini-1.0-pro": 0.5,
  "gemini-2.0-flash": 0.1,
  "gemini-2.0-flash-lite": 0.075,
  "mistral-large-latest": 3.0,
  "mistral-small-latest": 1.0,
  "mistral-nemo": 0.3,
  "mistral-small": 1.0,
  "mistral-large": 3.0,
  "claude-3-5-sonnet-20240620": 3.0,
  "claude-3-opus-20240229": 15.0,
  "claude-3-haiku": 0.25,
  "llama-3.1-70b-versatile": 0.59,
  "llama-3.1-8b-instant": 0.05,
  "gpt-4.5-preview": 75.0,
  "gpt-4.5-preview-2025-02-27": 75.0,
  "gpt-4o-audio-preview": 2.5,
  "gpt-4o-audio-preview-2024-12-17": 2.5,
  "gpt-4o-realtime-preview": 5.0,
  "gpt-4o-realtime-preview-2024-12-17": 5.0,
  "gpt-4o-mini": 0.15,
  "gpt-4o-mini-2024-07-18": 0.15,
  "gpt-4o-mini-audio-preview": 0.15,
  "gpt-4o-mini-audio-preview-2024-12-17": 0.15,
  "gpt-4o-mini-realtime-preview": 0.6,
  "gpt-4o-mini-realtime-preview-2024-12-17": 0.6,
  o1: 15.0,
  "o1-2024-12-17": 15.0,
  "o1-pro": 150.0,
  "o1-pro-2025-03-19": 150.0,
  "o3-mini": 1.1,
  "o3-mini-2025-01-31": 1.1,
  "o1-mini": 1.1,
  "o1-mini-2024-09-12": 1.1,
};

// Cost in dollars per 1 million tokens
const MODEL_OUTPUT_TOKEN_VALUE: Record<string, number> = {
  "gpt-4o": 15.0,
  "gpt-4o-2024-08-06": 15.0,
  "gpt-4-turbo": 30.0,
  "gpt-4": 60.0,
  "gpt-3.5-turbo-0125": 1.5,
  "gemini-2.0-flash": 0.4,
  "gemini-2.0-flash-lite": 0.3,
  "gemini-1.5-pro": 10.5,
  "gemini-1.5-flash": 1.05,
  "gemini-1.0-pro": 1.5,
  "mistral-large-latest": 9.0,
  "mistral-small-latest": 3.0,
  "mistral-nemo": 0.3,
  "mistral-small": 3.0,
  "mistral-large": 9.0,
  "claude-3-5-sonnet-20240620": 15.0,
  "claude-3-opus-20240229": 75.0,
  "claude-3-haiku-20240307": 1.25,
  "llama-3.1-70b-versatile": 0.79,
  "llama-3.1-8b-instant": 0.08,
  "gpt-4.5-preview": 150.0,
  "gpt-4.5-preview-2025-02-27": 150.0,
  "gpt-4o-audio-preview": 10.0,
  "gpt-4o-audio-preview-2024-12-17": 10.0,
  "gpt-4o-realtime-preview": 20.0,
  "gpt-4o-realtime-preview-2024-12-17": 20.0,
  "gpt-4o-mini": 0.6,
  "gpt-4o-mini-2024-07-18": 0.6,
  "gpt-4o-mini-audio-preview": 0.6,
  "gpt-4o-mini-audio-preview-2024-12-17": 0.6,
  "gpt-4o-mini-realtime-preview": 2.4,
  "gpt-4o-mini-realtime-preview-2024-12-17": 2.4,
  o1: 60.0,
  "o1-2024-12-17": 60.0,
  "o1-pro": 600.0,
  "o1-pro-2025-03-19": 600.0,
  "o3-mini": 4.4,
  "o3-mini-2025-01-31": 4.4,
  "o1-mini": 4.4,
  "o1-mini-2024-09-12": 4.4,
};

interface TokenUsageProps {
  subscription: TeamSubscription | null;
}

interface ModelUsage {
  model: string;
  input: number;
  output: number;
  inputCost: number;
  outputCost: number;
  totalCost: number;
}

interface UsageSummary {
  totalInputTokens: number;
  totalOutputTokens: number;
  totalCost: number;
  modelUsage: ModelUsage[];
}

const TokenUsageCalculator: React.FC<TokenUsageProps> = ({ subscription }) => {
  // Calculate costs based on token usage
  const { totalUsage, lastMonthUsage, apiRequests } = useMemo(() => {
    if (!subscription) {
      return { totalUsage: null, lastMonthUsage: null, apiRequests: null };
    }

    // Helper function to calculate costs from demo token usage
    const calculateCosts = (
      demoUseData: Record<
        string,
        Record<string, { input: number; output: number }>
      >
    ) => {
      const result: UsageSummary = {
        totalInputTokens: 0,
        totalOutputTokens: 0,
        totalCost: 0,
        modelUsage: [],
      };

      // Process each date's data
      Object.values(demoUseData).forEach((dateData) => {
        // Process each model's usage for this date
        Object.entries(dateData).forEach(([model, tokens]) => {
          // Find existing model usage or create new entry
          let modelEntry = result.modelUsage.find((m) => m.model === model);

          if (!modelEntry) {
            modelEntry = {
              model,
              input: 0,
              output: 0,
              inputCost: 0,
              outputCost: 0,
              totalCost: 0,
            };
            result.modelUsage.push(modelEntry);
          }

          // Add tokens
          modelEntry.input += tokens.input;
          modelEntry.output += tokens.output;

          // Calculate costs (per million tokens)
          const inputCost =
            (tokens.input / 1000000) * (MODEL_INPUT_TOKEN_VALUE[model] || 0);
          const outputCost =
            (tokens.output / 1000000) * (MODEL_OUTPUT_TOKEN_VALUE[model] || 0);

          modelEntry.inputCost += inputCost;
          modelEntry.outputCost += outputCost;
          modelEntry.totalCost += inputCost + outputCost;

          // Update totals
          result.totalInputTokens += tokens.input;
          result.totalOutputTokens += tokens.output;
          result.totalCost += inputCost + outputCost;
        });
      });

      // Sort models by cost (highest first)
      result.modelUsage.sort((a, b) => b.totalCost - a.totalCost);

      return result;
    };

    // Calculate API request usage from tokenWindows
    const calculateApiRequests = () => {
      // Calculate total API requests
      const totalRequests = subscription.tokenWindows.reduce(
        (sum, window) => sum + window.requestCount,
        0
      );

      // Group by month to separate last month's usage
      const now = new Date();
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

      const lastMonthRequests = subscription.tokenWindows
        .filter((window) => {
          const windowDate = new Date(window.startTime);
          return (
            windowDate >= lastMonth &&
            windowDate < new Date(now.getFullYear(), now.getMonth(), 1)
          );
        })
        .reduce((sum, window) => sum + window.requestCount, 0);

      return {
        totalRequests,
        lastMonthRequests,
      };
    };

    // Calculate total usage (all dates)
    const totalUsage = calculateCosts(subscription.demoUse ?? {});

    // Calculate last month's usage
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastMonthStr = lastMonth.toISOString().slice(0, 7); // Format: YYYY-MM

    const lastMonthData: Record<
      string,
      Record<string, { input: number; output: number }>
    > = {};

    // Filter for dates in the last month
    Object.entries(subscription.demoUse ?? {}).forEach(([date, usage]) => {
      if (date.startsWith(lastMonthStr)) {
        lastMonthData[date] = usage;
      }
    });

    const lastMonthUsage = calculateCosts(lastMonthData);

    // Calculate API requests
    const apiRequests = calculateApiRequests();

    return { totalUsage, lastMonthUsage, apiRequests };
  }, [subscription]);

  if (!subscription) return null;

  return (
    <div className="mt-4">
      <h3 className="text-sm font-medium mb-2">Usage Summary</h3>

      {/* API Requests Section */}
      <div className="bg-gray-50 p-3 rounded-md mb-3">
        <h4 className="text-sm font-medium mb-1">API Requests</h4>
        {apiRequests ? (
          <>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Total Requests:</span>{" "}
              {apiRequests.totalRequests.toLocaleString()}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Last Month's Requests:</span>{" "}
              {apiRequests.lastMonthRequests.toLocaleString()}
            </p>
          </>
        ) : (
          <p className="text-sm text-gray-600">No API request data available</p>
        )}
      </div>

      {/* Token Usage Last Month */}
      <div className="bg-gray-50 p-3 rounded-md mb-3">
        <h4 className="text-sm font-medium mb-1">Last Month's Token Usage</h4>
        {lastMonthUsage && lastMonthUsage.totalInputTokens > 0 ? (
          <>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Cost:</span> $
              {lastMonthUsage.totalCost.toFixed(2)}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Input Tokens:</span>{" "}
              {lastMonthUsage.totalInputTokens.toLocaleString()}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Output Tokens:</span>{" "}
              {lastMonthUsage.totalOutputTokens.toLocaleString()}
            </p>
          </>
        ) : (
          <p className="text-sm text-gray-600">
            No token usage data for last month
          </p>
        )}
      </div>

      {/* Total Token Usage */}
      <div className="bg-gray-50 p-3 rounded-md">
        <h4 className="text-sm font-medium mb-1">All-Time Token Usage</h4>
        {totalUsage && totalUsage.totalInputTokens > 0 ? (
          <>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Total Cost:</span> $
              {totalUsage.totalCost.toFixed(2)}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Total Input Tokens:</span>{" "}
              {totalUsage.totalInputTokens.toLocaleString()}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Total Output Tokens:</span>{" "}
              {totalUsage.totalOutputTokens.toLocaleString()}
            </p>

            {/* Top Models */}
            {totalUsage.modelUsage.length > 0 && (
              <div className="mt-2">
                <h5 className="text-xs font-medium mb-1">Top Models</h5>
                <ul className="text-xs">
                  {totalUsage.modelUsage.slice(0, 3).map((model) => (
                    <li key={model.model} className="mb-1">
                      <span className="font-medium">{model.model}:</span> $
                      {model.totalCost.toFixed(2)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <p className="text-sm text-gray-600">No token usage data available</p>
        )}
      </div>
    </div>
  );
};

export default TokenUsageCalculator;
