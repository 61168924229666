import React, { ReactNode } from "react";
import { FileUploadService } from "../services/FileUploadService";
import FileUploadContext from "../contexts/FileUploadContext";

interface FileUploadProviderProps {
  fileUploadService: FileUploadService;
  children: ReactNode;
}

export const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
  fileUploadService,
  children,
}) => {
  return (
    <FileUploadContext.Provider value={fileUploadService}>
      {children}
    </FileUploadContext.Provider>
  );
};
