import React, { useState, useEffect } from "react";
import { RemoteConfig } from "../../models/RemoteConfig";
import { useTeams } from "../../contexts/TeamContext";

interface RemoteConfigSectionProps {}

export const RemoteConfigSection: React.FC<RemoteConfigSectionProps> = () => {
  const teamService = useTeams();
  const [jsonError, setJsonError] = useState<string | null>(null);
  const [configJson, setConfigJson] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    const loadRemoteConfig = async () => {
      try {
        const remoteConfig = await teamService.remoteConfigRepo.get(
          teamService.remoteConfigPath(),
          "version1"
        );
        setConfigJson(JSON.stringify(remoteConfig, null, 2));
      } catch (error) {
        console.error("Error loading remote config:", error);
      }
    };

    loadRemoteConfig();
  }, [teamService]);

  const handleJsonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConfigJson(e.target.value);
    setSaveSuccess(false);

    try {
      JSON.parse(e.target.value);
      setJsonError(null);
    } catch (error) {
      if (error instanceof Error) {
        setJsonError(error.message);
      } else {
        setJsonError("Invalid JSON");
      }
    }
  };

  const handleSaveConfig = async () => {
    if (jsonError) return;

    try {
      setIsSaving(true);
      const parsedConfig = JSON.parse(configJson) as RemoteConfig;
      await teamService.remoteConfigRepo.update(
        parsedConfig,
        teamService.remoteConfigPath(),
        "version1"
      );
      setSaveSuccess(true);
    } catch (error) {
      console.error("Error saving remote config:", error);
      if (error instanceof Error) {
        setJsonError(error.message);
      } else {
        setJsonError("Failed to save configuration");
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Remote Configuration
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Edit the remote configuration JSON directly. This includes prompt
              agent IDs, tokens, and how-to guides.
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="space-y-6">
              <div>
                <textarea
                  rows={20}
                  className={`shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                    jsonError ? "border-red-300" : "border-gray-300"
                  } rounded-md font-mono`}
                  placeholder="Loading configuration..."
                  value={configJson}
                  onChange={handleJsonChange}
                ></textarea>
                {jsonError && (
                  <p className="mt-2 text-sm text-red-600">{jsonError}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleSaveConfig}
                  disabled={!!jsonError || isSaving}
                  className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                    jsonError || isSaving
                      ? "bg-indigo-300 cursor-not-allowed"
                      : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  }`}
                >
                  {isSaving ? "Saving..." : "Save Configuration"}
                </button>
              </div>

              {saveSuccess && (
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        Configuration saved successfully!
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoteConfigSection;
