import React, { useEffect, useState } from "react";
import { WorkflowDemoKnowledgeResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { CommonModal } from "../../../../Common/CommonModal";
import { useKnowledgeService } from "../../../../../contexts/KnowledgeContext";
import { WebsiteKnowledge } from "../../../../../models/WebsiteKnowledge";
import { DocumentKnowledge } from "../../../../../models/DocumentKnowledge";

export const KnowledgeResultModal: React.FC<{
  result: WorkflowDemoKnowledgeResult;
  shows: boolean;
  onClose: () => void;
  teamId: string;
}> = ({ result, shows, onClose, teamId }) => {
  const knowledgeService = useKnowledgeService();
  const [websiteDetails, setWebsiteDetails] = useState<
    Record<string, WebsiteKnowledge>
  >({});
  const [documentDetails, setDocumentDetails] = useState<
    Record<string, DocumentKnowledge>
  >({});
  const [isLoading, setIsLoading] = useState(true);
  const [parentChildMap, setParentChildMap] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    const fetchSourceDetails = async () => {
      try {
        setIsLoading(true);

        // Create map of child to parent IDs
        const childToParentMap: Record<string, string> = {};
        if (result.websiteChildren && result.websiteChildren.length > 0) {
          result.websiteChildren.forEach((child) => {
            childToParentMap[child.id] = child.parentId;
          });
        }
        setParentChildMap(childToParentMap);

        // Fetch website details
        const websites = await knowledgeService.websiteKnowledgeRepo.getList(
          knowledgeService.websiteKnowledgePath(teamId)
        );

        let websiteMap: Record<string, WebsiteKnowledge> = {};

        // Map parent websites first
        websites.forEach((website) => {
          websiteMap[website.id] = website;
        });

        // Fetch child websites that were used
        const childFetchPromises = [];
        const websiteIds =
          result.usedWebsiteKnowledgeIds?.map((id) => id.trim()) || [];

        // For each website ID, check if it's a child and fetch its details if not in main list
        for (const websiteId of websiteIds) {
          if (childToParentMap[websiteId] && !websiteMap[websiteId]) {
            const parentId = childToParentMap[websiteId];
            childFetchPromises.push(
              knowledgeService.websiteKnowledgeRepo
                .getList(
                  knowledgeService.websiteKnowledgeChildrenPath(
                    teamId,
                    parentId
                  )
                )
                .then((children) => {
                  const childMap = children.reduce((acc, child) => {
                    acc[child.id] = child;
                    return acc;
                  }, {} as Record<string, WebsiteKnowledge>);
                  return childMap;
                })
            );
          }
        }

        // Merge all child website details
        if (childFetchPromises.length > 0) {
          const childMaps = await Promise.all(childFetchPromises);
          childMaps.forEach((childMap) => {
            websiteMap = { ...websiteMap, ...childMap };
          });
        }

        setWebsiteDetails(websiteMap);

        // Fetch document details
        const documents = await knowledgeService.documentKnowledgeRepo.getList(
          knowledgeService.documentKnowledgePath(teamId)
        );

        const documentMap = documents.reduce((acc, document) => {
          acc[document.id] = document;
          return acc;
        }, {} as Record<string, DocumentKnowledge>);

        setDocumentDetails(documentMap);
      } catch (error) {
        console.error("Error fetching knowledge source details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (shows) {
      fetchSourceDetails();
    }
  }, [
    shows,
    teamId,
    knowledgeService,
    result.websiteChildren,
    result.usedWebsiteKnowledgeIds,
  ]);

  const websiteIds =
    result.usedWebsiteKnowledgeIds?.map((id) => id.trim()) || [];
  const documentIds =
    result.usedDocumentKnowledgeIds?.map((id) => id.trim()) || [];

  // Calculate total sources used
  const totalSourcesUsed = websiteIds.length + documentIds.length;

  // Group child websites under their parents for display
  const organizeWebsites = () => {
    // First, identify all parent websites
    const parentWebsites: WebsiteKnowledge[] = [];
    const childrenByParent: Record<string, WebsiteKnowledge[]> = {};
    const standaloneWebsites: WebsiteKnowledge[] = [];

    websiteIds.forEach((id) => {
      const website = websiteDetails[id];
      if (!website) return;

      const parentId = parentChildMap[id];

      if (parentId && websiteIds.includes(parentId)) {
        // This is a child and its parent is also in the list
        if (!childrenByParent[parentId]) {
          childrenByParent[parentId] = [];
        }
        childrenByParent[parentId].push(website);
      } else if (parentId) {
        // This is a child but parent isn't in the list - show as standalone
        standaloneWebsites.push(website);
      } else if (Object.values(parentChildMap).includes(id)) {
        // This is a parent with children in the list
        parentWebsites.push(website);
      } else {
        // This is a standalone website
        standaloneWebsites.push(website);
      }
    });

    return { parentWebsites, childrenByParent, standaloneWebsites };
  };

  const { parentWebsites, childrenByParent, standaloneWebsites } =
    organizeWebsites();

  return (
    <CommonModal isOpen={shows} onDismiss={onClose} title={`${result.name}`}>
      <div className="flex justify-end items-center mb-6 pt-2">
        <StatusBadge status={result.status} />
      </div>

      <div
        className="space-y-6 overflow-y-auto pr-1"
        style={{ maxHeight: "calc(80vh - 100px)" }}
      >
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Queried sources
          </h3>
          <div className="text-sm text-gray-600">
            {isLoading ? (
              <div className="animate-pulse bg-gray-100 h-20 rounded-md" />
            ) : (
              <>
                {/* Website sources */}
                {websiteIds.length > 0 && (
                  <div className="mb-4">
                    <div className="text-xs text-gray-500 mb-2">
                      Website Sources
                    </div>

                    {/* Parent websites with their children */}
                    {parentWebsites.map((parent) => (
                      <div key={parent.id} className="mb-3">
                        <div className="p-2 bg-gray-50 rounded-md">
                          <div className="font-medium">
                            {parent.title || "Website"}
                            {childrenByParent[parent.id]?.length > 0 && (
                              <span className="ml-2 text-xs font-normal text-gray-500">
                                ({childrenByParent[parent.id].length} child
                                pages used)
                              </span>
                            )}
                          </div>
                          <div className="text-xs text-gray-500">
                            {parent.url}
                          </div>
                        </div>

                        {/* Child websites */}
                        {childrenByParent[parent.id]?.length > 0 && (
                          <div className="ml-4 pl-2 border-l border-gray-200 mt-1">
                            {childrenByParent[parent.id].map((child) => (
                              <div
                                key={child.id}
                                className="p-2 mt-1 bg-gray-50 rounded-md"
                              >
                                <div className="font-medium text-xs">
                                  {child.title || "Child page"}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {child.url}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Standalone websites */}
                    {standaloneWebsites.map((website) => (
                      <div
                        key={website.id}
                        className="mb-2 p-2 bg-gray-50 rounded-md"
                      >
                        <div className="font-medium">
                          {website.title || "Website"}
                        </div>
                        <div className="text-xs text-gray-500">
                          {website.url || website.id}
                        </div>
                      </div>
                    ))}

                    {/* Missing websites */}
                    {websiteIds
                      .filter((id) => !websiteDetails[id])
                      .map((id, index) => (
                        <div
                          key={`unknown-website-${index}`}
                          className="mb-2 p-2 bg-gray-50 rounded-md"
                        >
                          <div className="font-medium">Unknown Website</div>
                          <div className="text-xs text-gray-500">{id}</div>
                        </div>
                      ))}
                  </div>
                )}

                {/* Document sources */}
                {documentIds.length > 0 && (
                  <div>
                    <div className="text-xs text-gray-500 mb-2">
                      Document Sources
                    </div>
                    {documentIds.map((id, index) => (
                      <div
                        key={`document-${index}`}
                        className="mb-2 p-2 bg-gray-50 rounded-md"
                      >
                        <div className="font-medium">
                          {documentDetails[id]?.title || "Unknown Document"}
                        </div>
                        <div className="text-xs text-gray-500">
                          {documentDetails[id]?.title || id}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Usage Metrics
          </h3>
          <div className="bg-gray-50 p-4 rounded-lg grid grid-cols-2 gap-4">
            <div>
              <div className="text-xs text-gray-500">Total Chunks Used</div>
              <div className="text-lg font-medium">
                {result.usedChunks || 0}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Sources Used</div>
              <div className="text-lg font-medium">{totalSourcesUsed}</div>
            </div>
          </div>
        </div>

        {result.processedContent && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Retrieved Content
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg text-sm whitespace-pre-wrap break-words">
              {result.processedContent}
            </div>
          </div>
        )}

        {result.error && (
          <div className="text-red-500 text-sm">Error: {result.error}</div>
        )}
      </div>
    </CommonModal>
  );
};
