import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { ENCODING_STRATEGIES } from "./AddWebsiteModal";

type StrategyKey = keyof typeof ENCODING_STRATEGIES;

interface AddDocumentModalProps {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface StrategyButtonProps {
  strategyKey: StrategyKey;
  strategy: (typeof ENCODING_STRATEGIES)[StrategyKey];
  isSelected: boolean;
  onClick: () => void;
}

const StrategyButton: React.FC<StrategyButtonProps> = ({
  strategy,
  isSelected,
  onClick,
}) => (
  <button
    onClick={onClick}
    className={`flex flex-col p-4 rounded-lg border-2 transition-all w-full
      ${
        isSelected
          ? "border-blue-500 bg-blue-50"
          : "border-gray-200 hover:border-gray-300 bg-white"
      }`}
  >
    <span className="font-medium text-left mb-1">{strategy.label}</span>
    <span className="text-sm text-gray-600 text-left">
      {strategy.description}
    </span>
  </button>
);

export const AddDocumentModal: React.FC<AddDocumentModalProps> = ({
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [selectedStrategy, setSelectedStrategy] =
    useState<StrategyKey>("balanced");

  const handleSubmit = async () => {
    if (!file) {
      setError("Please select a document to upload");
      return;
    }

    setButtonState("loading");
    try {
      const formData = new FormData();
      formData.append("file", file);

      const { chunkSize, chunkOverlap, label } =
        ENCODING_STRATEGIES[selectedStrategy];

      formData.append(
        "encoding",
        JSON.stringify({
          chunkSize,
          chunkOverlap,
          name: label,
        })
      );

      await knowledgeService.addDocumentKnowledge(teamId, formData);
      setButtonState("ready");
      onSuccess();
      onClose();
    } catch (e) {
      setError(e instanceof Error ? e.message : "Failed to upload document");
      setButtonState("error");
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose} title={"Add Document"}>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Upload Document
          </label>
          <div
            className={`w-full px-3 py-8 border-2 border-dashed rounded-md text-center cursor-pointer transition-colors
                ${
                  file
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-300 hover:border-gray-400"
                }`}
            onClick={() => document.getElementById("fileInput")?.click()}
          >
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept="text/*,.pdf,.docx,.js,.jsx,.ts,.tsx,.py,.java,.cpp,.c,.cs,.rb,.php,.go,.rs,.swift,.kt,.scala"
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setFile(selectedFile);
                  setError(null);
                }
              }}
            />
            {file ? (
              <div className="text-sm text-gray-700">
                <span className="font-medium">{file.name}</span>
                <br />
                <span className="text-gray-500">
                  Click to choose a different file
                </span>
              </div>
            ) : (
              <div className="text-sm text-gray-600">
                Click to choose a file or drag and drop
                <br />
                <span className="text-gray-500">
                  PDF, DOCX, TXT, or MD files
                </span>
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Encoding Strategy
          </label>
          <div className="grid grid-cols-3 gap-4">
            {(
              Object.entries(ENCODING_STRATEGIES) as [
                StrategyKey,
                (typeof ENCODING_STRATEGIES)[StrategyKey]
              ][]
            ).map(([key, strategy]) => (
              <StrategyButton
                key={key}
                strategyKey={key}
                strategy={strategy}
                isSelected={selectedStrategy === key}
                onClick={() => setSelectedStrategy(key)}
              />
            ))}
          </div>
        </div>

        {error && <p className="text-sm text-red-600">{error}</p>}
      </div>

      <div className="flex justify-between gap-3 mt-6">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <AnimatedButton
          title="Upload Document"
          onClick={handleSubmit}
          buttonState={buttonState}
          setButtonState={setButtonState}
          style="action"
          error={error}
          setError={setError}
        />
      </div>
    </CommonModal>
  );
};
