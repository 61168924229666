import React, { useState, useEffect } from "react";
import { WebsiteKnowledge } from "../../models/WebsiteKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { CommonModal } from "../Common/CommonModal";
import { CommonSpinner } from "../Common/CommonLoading";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { StatusBadge } from "../TestCenter/StatusBadge";

interface WebsiteContentModalProps {
  websiteId: string;
  teamId: string;
  parentId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const WebsiteContentModal: React.FC<WebsiteContentModalProps> = ({
  websiteId,
  teamId,
  parentId,
  isOpen,
  onClose,
}) => {
  const knowledgeService = useKnowledgeService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [website, setWebsite] = useState<WebsiteKnowledge | null>(null);

  useEffect(() => {
    if (isOpen && websiteId) {
      loadWebsiteContent();
    }
  }, [isOpen, websiteId, teamId]);

  const loadWebsiteContent = async () => {
    setLoading(true);
    setError(null);

    try {
      const path = parentId
        ? knowledgeService.websiteKnowledgeChildrenPath(teamId, parentId)
        : knowledgeService.websiteKnowledgePath(teamId);

      const websiteData = await knowledgeService.websiteKnowledgeRepo.get(
        path,
        websiteId
      );

      if (websiteData) {
        setWebsite(websiteData);
      } else {
        setError("Website content not found");
      }
    } catch (e) {
      console.error("Failed to load website content:", e);
      setError(
        e instanceof Error ? e.message : "Failed to load website content"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose} title="Website Content">
      {loading ? (
        <div className="flex justify-center items-center p-10">
          <CommonSpinner />
        </div>
      ) : error ? (
        <div className="p-6 text-red-500">{error}</div>
      ) : website ? (
        <div className="flex flex-col gap-6 p-6 max-h-[80vh] overflow-y-auto">
          {/* Header with website information */}
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-start">
              <div className="flex flex-row gap-2">
                <h3 className="text-lg font-medium">
                  {website.title ?? website.url}
                </h3>
                <div className="flex h-fit">
                  <StatusBadge status={website.status} />
                </div>
              </div>
            </div>

            <h3 className="text-gray-400 text-sm break-all">{website.url}</h3>

            <div className="flex gap-6 text-gray-600 text-sm">
              <div>
                Last updated: {formatDate(website.lastUpdate)}{" "}
                {formatTime(website.lastUpdate)}
              </div>
              <div>Chunks: {website.chunkCount || 0}</div>
            </div>
          </div>

          {/* Divider */}
          <div className="border-t"></div>

          {/* Content display */}
          <div className="flex flex-col gap-4">
            <h4 className="text-lg font-medium">Processed Content</h4>
            {website.processedContent ? (
              <div className="border rounded-lg p-4 bg-gray-50">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  className="prose max-w-none text-sm text-slate-900"
                >
                  {website.processedContent}
                </ReactMarkdown>
              </div>
            ) : (
              <div className="text-gray-500 italic">
                No processed content available
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="p-6 text-gray-500">No website data available</div>
      )}
    </CommonModal>
  );
};
