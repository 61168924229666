import { useCallback } from "react";
import { useReactFlow } from "reactflow";

interface UseNodeZoomOptions {
  duration?: number;
  padding?: number;
}

export function useNodeZoom(options: UseNodeZoomOptions = {}) {
  const { fitView, getNodes } = useReactFlow();

  const { duration = 800, padding = 0.1 } = options;

  const zoomToNode = useCallback(
    (nodeId: string) => {
      const nodes = getNodes();
      const node = nodes.find((n) => n.id === nodeId);

      if (node) {
        fitView({
          nodes: [node],
          duration,
          padding,
        });
      }
    },
    [fitView, getNodes, duration, padding]
  );

  const getNodeTitle = useCallback(
    (nodeId: string): string | undefined => {
      const nodes = getNodes();
      const node = nodes.find((n) => n.id === nodeId);
      return node?.data.title;
    },
    [getNodes]
  );

  return {
    zoomToNode,
    getNodeTitle,
  };
}
