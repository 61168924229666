import React from "react";
import { WorkflowDemoStartResult } from "../../../../../models/WorkflowDemoResult";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import { MessagesCard } from "./MessagesCard";
import { CommonModal } from "../../../../Common/CommonModal";

interface StartResultModalProps {
  result: WorkflowDemoStartResult;
  shows: boolean;
  onClose: () => void;
}

export const StartResultModal: React.FC<StartResultModalProps> = ({
  result,
  shows,
  onClose,
}) => {
  const getStatusStyles = () => {
    const styles = {
      COMPLETED: "bg-green-50 text-green-400",
      ERROR: "bg-yellow-50 text-yellow-400",
      IN_PROGRESS: "bg-red-50 text-red-400",
    };
    return styles[result.status] || "";
  };

  const getStatusTitle = () => {
    const titles = {
      COMPLETED: "success",
      ERROR: "error",
      IN_PROGRESS: "security",
    };
    return titles[result.status] || "";
  };

  return (
    <CommonModal isOpen={shows} onDismiss={onClose} title="Start Step">
      <div className="flex justify-between items-center">
        <div className="flex justify-between text-xs text-gray-500 gap-2">
          <span>Created</span>
          <span>{formatDate(result.createdAt)}</span>
          <span>{formatTime(result.createdAt)}</span>
        </div>
        <div
          className={`text-xs font-medium px-2 py-1 rounded ${getStatusStyles()}`}
        >
          {getStatusTitle()}
        </div>
      </div>

      <MessagesCard messages={result.messages} />

      <div className="flex flex-col gap-4">
        {Object.entries(result.parameters).map(([key, value]) => (
          <VariableBlock key={key} keyValue={key} value={value} />
        ))}
      </div>
    </CommonModal>
  );
};

const VariableBlock: React.FC<{
  keyValue: string;
  value: string;
}> = ({ keyValue, value }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex flex-col">
      <div className="font-gooper text-gray-700 text-sm w-full border-b border-gray-200 px-5 py-2">
        {keyValue}
      </div>
      <div className="text-gray-700 text-base leading-normal p-4 break-words whitespace-pre-wrap">
        {value}
      </div>
    </div>
  );
};

export default StartResultModal;
