import React, { useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import Icon from "../Icon";

interface CommonModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  subItem?: React.ReactNode;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  onDismiss,
  children,
  title,
  subtitle,
  subItem,
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss();
      }
    },
    [onDismiss]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  const handleBackgroundClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  if (!isOpen) return null;

  const modalContent = (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-auto"
      onClick={handleBackgroundClick}
    >
      <div
        className="relative flex flex-col gap-2 p-6 overflow-y-auto max-h-[calc(100vh-220px-44px)] w-full max-w-4xl  lg:max-h-[90vh] bg-white rounded-xl shadow-2xl overflow-hidden mx-4 md:mx-12 lg:mx-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row justify-between border-b border-gray-200 mb-4 pb-2">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-gooper text-gray-900 font-semibold">
              {title}
            </h2>
            <h3 className="text-sm font-sans text-gray-700">{subtitle}</h3>
            {subItem}
          </div>
          <div className="pt-2">
            <button onClick={onDismiss}>
              <Icon type="x" className="text-gray-400 hover:text-gray-600" />
            </button>
          </div>
        </div>

        {children}
      </div>
    </div>
  );

  // Create portal to render the modal at the document body level
  return createPortal(modalContent, document.body);
};
