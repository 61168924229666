import React from "react";
import { CommonModal } from "../../../Common/CommonModal";
import { WriteDatabaseData } from "../../Map/Nodes/DatabaseNode";
import {
  ENCODING_STRATEGIES,
  StrategyButton,
} from "../../../Knowledge/AddWebsiteModal";

interface WriteAdvancedSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  data: WriteDatabaseData;
  updateNode: (newData: Partial<WriteDatabaseData>) => void;
}

export const WriteAdvancedSettings: React.FC<WriteAdvancedSettingsProps> = ({
  isOpen,
  onClose,
  data,
  updateNode,
}) => {
  const handleStrategySelect = (key: keyof typeof ENCODING_STRATEGIES) => {
    const strategy = ENCODING_STRATEGIES[key];
    updateNode({
      encodingStrategy: {
        name: strategy.label,
        chunkSize: strategy.chunkSize,
        chunkOverlap: strategy.chunkOverlap,
      },
    });
  };

  // Helper function to find if a strategy is selected
  const isStrategySelected = (key: keyof typeof ENCODING_STRATEGIES) => {
    return data.encodingStrategy?.name === ENCODING_STRATEGIES[key].label;
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onClose}
      title="Write: Advanced Settings"
    >
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Encoding Strategy
          </label>
          <div className="grid grid-cols-3 gap-4">
            {(
              Object.entries(ENCODING_STRATEGIES) as [
                keyof typeof ENCODING_STRATEGIES,
                (typeof ENCODING_STRATEGIES)[keyof typeof ENCODING_STRATEGIES]
              ][]
            ).map(([key, strategy]) => (
              <StrategyButton
                key={key}
                strategyKey={key}
                strategy={strategy}
                isSelected={isStrategySelected(key)}
                onClick={() => handleStrategySelect(key)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Close
        </button>
      </div>
    </CommonModal>
  );
};
