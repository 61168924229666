import React, { useState } from "react";
import { DocumentKnowledge } from "../../models/DocumentKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import AnimatedButton from "../AnimatedButton";
import Icon from "../Icon";
import { CommonModal } from "../Common/CommonModal";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { StatusBadge } from "../TestCenter/StatusBadge";

interface ManageDocumentModalProps {
  document: DocumentKnowledge;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const ManageDocumentModal: React.FC<ManageDocumentModalProps> = ({
  document,
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await knowledgeService.removeDocumentKnowledge(teamId, document.id!);
      onSuccess?.();
      onClose();
    } catch (error) {
      setError("Failed to delete document knowledge");
      console.error("Failed to delete document:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onClose}
      title="Manage Document Knowledge"
    >
      <div className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-start">
            <div className="flex flex-row gap-2 justify-between w-full">
              <h3 className="text-lg font-medium">{document.title}</h3>
              <div className="flex h-fit">
                <StatusBadge status={document.status} />
              </div>
            </div>
          </div>

          <h3 className="text-gray-400 text-sm">
            {document.fileType?.toUpperCase() ?? "Document"}
          </h3>

          <div className="flex gap-6 text-gray-600">
            <div className="flex items-center gap-2">
              <Icon type="clock" />
              <span>
                Added:{" "}
                {`${formatDate(document.lastUpdate)} ${formatTime(
                  document.lastUpdate
                )}`}
              </span>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <h4 className="text-sm font-medium text-gray-700 mb-3">
            Embedding Details
          </h4>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="text-sm text-gray-500">Chunks</div>
              <div className="text-lg font-medium">
                {document.chunkCount || 0}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Strategy</div>
              <div className="text-lg font-medium">
                {document.encodingStrategy
                  ? `${document.encodingStrategy.name}`
                  : "Default"}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Status</div>
              <div className="text-lg font-medium capitalize">
                {document.status.toLowerCase()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Last Updated</div>
              <div className="text-lg font-medium">
                {`${formatDate(document.lastUpdate)} ${formatTime(
                  document.lastUpdate
                )}`}
              </div>
            </div>
          </div>
        </div>

        <div className="border-t pt-6">
          <h4 className="text-lg font-medium mb-4">Danger Zone</h4>
          <div className="bg-red-50 p-4 rounded-lg">
            <AnimatedButton
              title="Delete Document Knowledge"
              onClick={handleDelete}
              buttonState={isDeleting ? "loading" : "ready"}
              setButtonState={() => {}}
              style="destructive"
              leftIcon="trash"
              classNameIn="w-full"
              id="deleteDocumentButton"
              disabled={isDeleting}
            />
            {error && <div className="text-red-600 text-sm mt-2">{error}</div>}
            <p className="text-sm text-gray-600 mt-2">
              This action cannot be undone. All indexed content and embeddings
              will be permanently removed.
            </p>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};
