import { ChunkEncodingStrategy } from "./ChunkEncodingStrategy";
import { WebsiteFilterOptions } from "./Workflow";
export type KnowledgeStatus = "IN_PROGRESS" | "COMPLETED" | "ERROR";

export interface WebsiteKnowledge {
  id: string;
  url: string;
  filterOptions: WebsiteFilterOptions;
  status: KnowledgeStatus;
  lastUpdate?: Date;
  pageCount?: number;
  chunkCount: number;
  encodingStrategy: ChunkEncodingStrategy;
  title?: string;
  hasChildren?: boolean;
  successCount?: number;
  processedContent?: string;
}

export enum RecursionScope {
  SAME_DOMAIN = "SAME_DOMAIN",
  SAME_ROOT_DOMAIN = "SAME_ROOT_DOMAIN",
  ALL = "ALL",
}

export interface CrawlOptions {
  enabled: boolean;
  maxDepth: number;
  scope: RecursionScope;
  urlPattern: string | null;
  scrollInterval: number | null;
  maxChildren?: number;
  useJS: boolean;
}
