import React, { useState, useEffect } from "react";
import { TeamSubscription } from "../../models/TeamSubscription";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton from "../AnimatedButton";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import {
  SubscriptionOption,
  PaymentOption,
} from "../../models/SubscriptionOption";
import SubscriptionCard from "../Teams/SubscriptionCard";
import { Team } from "../../models/Team";

interface SubscriptionBlockedModalProps {
  subscription?: TeamSubscription;
  teamId: string;
  team?: Team;
  isAdmin: boolean;
}

export const SubscriptionBlockedModal: React.FC<
  SubscriptionBlockedModalProps
> = ({ subscription, teamId, team, isAdmin }) => {
  const teamService = useTeams();
  const auth = useAuth();

  // Check if trial has expired (more than 14 days since team creation)
  const isTrialExpired = () => {
    if (!team?.createdAt) return false;

    const creationDate = new Date(team.createdAt);
    const trialEndDate = new Date(
      creationDate.getTime() + 14 * 24 * 60 * 60 * 1000
    ); // 14 days after creation
    const currentDate = new Date();

    return currentDate > trialEndDate;
  };

  // Add incomplete_expired to the list of blocked statuses and also check for trial expiration
  const isBlocked =
    (subscription?.currentEvent?.status === "canceled" ||
      subscription?.currentEvent?.status === "paused" ||
      subscription?.currentEvent?.status === "incomplete_expired" ||
      (!subscription?.currentEvent && isTrialExpired())) &&
    !isAdmin;

  const [error, setError] = useState<string | null>(null);
  const [subscriptions, setSubscriptions] = useState<SubscriptionOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [yearly, setYearly] = useState(false);

  // Fetch subscription options
  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const options = await teamService.subscriptionOptionRepo.getList(
          teamService.subscriptionOptionPath()
        );
        setSubscriptions(options.filter((s) => !s.isHidden));
      } catch (e) {
        console.error("Error fetching subscription options:", e);
        setError("Failed to load subscription options");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [teamService.subscriptionOptionRepo]);

  const handlePurchaseSelection = async (
    _: SubscriptionOption,
    price: PaymentOption
  ) => {
    setError(null);

    try {
      // Get user email from auth context
      const user = await auth.currentUser();
      const userEmail = user?.email;
      if (!userEmail) {
        throw new Error("User email not found");
      }

      // Direct purchase with immediate payment (no trial)
      const checkoutUrl = await teamService.purchase(
        price.priceId,
        teamId,
        `${window.location.origin}`,
        userEmail
      );

      // Redirect to checkout
      window.location.href = checkoutUrl;
    } catch (e) {
      console.error("Error purchasing subscription:", e);
      setError(
        e instanceof Error ? e.message : "Failed to purchase subscription"
      );

      if (window.Intercom) {
        window.Intercom(
          "showNewMessage",
          `I'm having trouble purchasing a subscription. Error: ${
            e instanceof Error ? e.message : "Failed to purchase"
          }`
        );
      }
    }
  };

  const handleSupport = async () => {
    if (window.Intercom) {
      window.Intercom(
        "showNewMessage",
        `I need help with my subscription${error ? `. Error: ${error}` : ""}`
      );
    } else {
      console.warn("Intercom is not available");
    }
  };

  const handleLogOut = async () => {
    await auth.signOut();
  };

  // Get appropriate title and message based on subscription status
  const getModalContent = () => {
    // If the trial has expired (no subscription and past 14 days)
    if (!subscription?.currentEvent && isTrialExpired()) {
      return {
        title: "Your free trial has ended",
        message:
          "Your 14-day free trial has ended. Please select a plan below and add your payment details to continue using our service.",
      };
    }

    return {
      title: "Your subscription has expired",
      message:
        "Please select a plan below and add your payment details to continue using our service. If you've already paid, try hitting refresh!",
    };
  };

  const content = getModalContent();

  return (
    <CommonModal isOpen={isBlocked} title={content.title} onDismiss={() => {}}>
      {/* Content */}
      <div className="space-y-6">
        <p className="text-gray-600">{content.message}</p>

        {/* Billing toggle */}
        <div className="flex justify-center items-center gap-2">
          <div className="text-gray-900 text-sm font-medium">Monthly</div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={yearly}
              onChange={() => setYearly(!yearly)}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <div className="text-gray-900 text-sm font-medium">Yearly</div>
          <div className="text-gray-500 text-xs">Save 25%</div>
        </div>

        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="overflow-x-auto bg-gray-50 -ml-6 -mr-6 px-6 py-4">
            <div className="flex gap-2 p-1 pb-4 w-max">
              {subscriptions
                .sort((a, b) => a.index - b.index)
                .map((sub) => (
                  <SubscriptionCard
                    key={sub.id}
                    features={sub.features}
                    subscription={sub}
                    yearly={yearly}
                    selected={handlePurchaseSelection}
                    highlightFirstFeature={sub.highlightFirstFeature}
                    trial={false}
                  />
                ))}
            </div>
          </div>
        )}

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}
      </div>

      {/* Actions */}
      <div className="flex flex-col gap-4 pt-4 border-t border-gray-200 mt-4">
        <AnimatedButton
          title="Contact Support"
          onClick={handleSupport}
          style="transparent-black"
          buttonState="ready"
        />

        <AnimatedButton
          title="Log out"
          onClick={handleLogOut}
          style="transparent-destructive"
          buttonState="ready"
        />
      </div>
    </CommonModal>
  );
};

export default SubscriptionBlockedModal;
