import React, { useState } from "react";
import { DatabaseKnowledge } from "../../models/DatabaseKnowledge";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import Icon from "../Icon";
import { CommonModal } from "../Common/CommonModal";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { Team } from "../../models/Team";
import ImportJsonModal from "./ImportJsonModal";
import BrowseDataTableModal from "./BrowseDataTableModal";

interface ManageDataTableModalProps {
  dataTable: DatabaseKnowledge;
  team: Team;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const ManageDataTableModal: React.FC<ManageDataTableModalProps> = ({
  dataTable,
  team,
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isBrowseModalOpen, setIsBrowseModalOpen] = useState(false);

  // Add handlers for the browse modal
  const handleOpenBrowseModal = () => {
    setIsBrowseModalOpen(true);
  };

  const handleCloseBrowseModal = () => {
    setIsBrowseModalOpen(false);
  };

  // Editable state
  const [name, setName] = useState(dataTable.name);
  const [selectedPineconeDB, setSelectedPineconeDB] = useState(
    dataTable.pineconeDBId
  );
  const [metadataFields, setMetadataFields] = useState([
    ...dataTable.metadataFields,
  ]);
  const [isEditing, setIsEditing] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await knowledgeService.databaseKnowledgeRepo.delete(
        knowledgeService.databaseKnowledgePath(teamId),
        dataTable.id!
      );
      onSuccess?.();
      onClose();
    } catch (error) {
      setError("Failed to delete data set");
      console.error("Failed to delete data set:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async () => {
    if (!name.trim()) {
      setSaveError("Data set name cannot be empty");
      return;
    }

    if (!selectedPineconeDB) {
      setSaveError("Please select a Pinecone database");
      return;
    }

    if (metadataFields.some((field) => !field.name.trim())) {
      setSaveError("All metadata field names must be filled");
      return;
    }

    setIsSaving("loading");
    try {
      await knowledgeService.databaseKnowledgeRepo.update(
        {
          ...dataTable,
          name: name.trim(),
          pineconeDBId: selectedPineconeDB,
          metadataFields,
        },
        knowledgeService.databaseKnowledgePath(teamId),
        dataTable.id!
      );
      setIsSaving("ready");
      setIsEditing(false);
      onSuccess?.();
    } catch (error) {
      setSaveError("Failed to update data set");
      setIsSaving("error");
      console.error("Failed to update data set:", error);
    }
  };

  // Handlers for import modal
  const handleOpenImportModal = () => {
    setIsImportModalOpen(true);
  };

  const handleCloseImportModal = () => {
    setIsImportModalOpen(false);
  };

  const handleImportSuccess = () => {
    // Optionally refresh data or show a success message
    onSuccess?.();
  };

  const handleAddField = () => {
    setMetadataFields([...metadataFields, { name: "", type: "string" }]);
  };

  const handleRemoveField = (index: number) => {
    setMetadataFields(metadataFields.filter((_, i) => i !== index));
  };

  const handleFieldChange = (
    index: number,
    field: Partial<{ name: string; type: string }>
  ) => {
    const newFields = [...metadataFields];
    newFields[index] = { ...newFields[index], ...field };
    setMetadataFields(newFields);
  };

  return (
    <>
      <CommonModal isOpen={isOpen} onDismiss={onClose} title="Manage Data Set">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-2 w-full">
              {isEditing ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="text-lg font-medium px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium">{dataTable.name}</h3>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="text-blue-600 hover:text-blue-700 text-sm"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-6 text-gray-600">
            <div className="flex items-center gap-2">
              <Icon type="clock" />
              <span>
                Created:{" "}
                {`${formatDate(dataTable.createdAt)} ${formatTime(
                  dataTable.createdAt
                )}`}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="bg-gray-50 p-4 rounded-lg flex-1">
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Configuration
            </h4>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm text-gray-500">Metadata Fields</div>
                <div className="text-lg font-medium">
                  {metadataFields.length}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Server</div>
                <div className="text-lg font-medium">
                  {(team.pineconeDBs ?? {})[dataTable?.pineconeDBId]?.name ||
                    "Not set"}
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 flex flex-col gap-2">
            <button
              onClick={handleOpenBrowseModal}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2 h-fit"
            >
              <Icon type="eye" className="w-4 h-4" />
              Browse Data
            </button>
            <button
              onClick={handleOpenImportModal}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2 h-fit"
            >
              <Icon type="import" className="w-4 h-4" />
              Import Data
            </button>
          </div>
        </div>

        {isEditing && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Pinecone Database
            </h4>
            <select
              value={selectedPineconeDB}
              onChange={(e) => setSelectedPineconeDB(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select a database</option>
              {Object.entries(team.pineconeDBs || {}).map(([id, db]) => (
                <option key={id} value={id}>
                  {db.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex justify-between items-center mb-3">
            <h4 className="text-sm font-medium text-gray-700">
              Metadata Fields
            </h4>
            {isEditing && (
              <button
                onClick={handleAddField}
                className="text-sm text-blue-600 hover:text-blue-700"
              >
                Add Field
              </button>
            )}
          </div>
          <div className="grid grid-cols-1 gap-2">
            {metadataFields.map((field, index) => (
              <div
                key={index}
                className="flex items-center justify-between py-2 px-3 bg-white rounded-md"
              >
                {isEditing ? (
                  <>
                    <input
                      type="text"
                      value={field.name}
                      onChange={(e) =>
                        handleFieldChange(index, { name: e.target.value })
                      }
                      className="flex-1 px-2 py-1 border rounded mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Field name"
                    />
                    <select
                      value={field.type}
                      onChange={(e) =>
                        handleFieldChange(index, { type: e.target.value })
                      }
                      className="w-32 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="string">String</option>
                      <option value="number">Number</option>
                      <option value="date">Date</option>
                      <option value="boolean">Boolean</option>
                    </select>
                    {metadataFields.length > 1 && (
                      <button
                        onClick={() => handleRemoveField(index)}
                        className="ml-2 text-gray-400 hover:text-gray-600"
                      >
                        <Icon type="x" />
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <span className="font-medium">{field.name}</span>
                    <span className="text-gray-500">{field.type}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        {isEditing && (
          <div className="flex justify-end gap-3">
            <button
              onClick={() => {
                setIsEditing(false);
                setName(dataTable.name);
                setSelectedPineconeDB(dataTable.pineconeDBId);
                setMetadataFields([...dataTable.metadataFields]);
                setSaveError(null);
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <AnimatedButton
              title="Save Changes"
              onClick={handleSave}
              buttonState={isSaving}
              setButtonState={setIsSaving}
              style="action"
              error={saveError}
              setError={setSaveError}
            />
          </div>
        )}

        {saveError && <div className="text-red-600 text-sm">{saveError}</div>}

        <div className="border-t pt-6">
          <h4 className="text-lg font-medium mb-4">Danger Zone</h4>
          <div className="bg-red-50 p-4 rounded-lg">
            <AnimatedButton
              title="Delete Data Set"
              onClick={handleDelete}
              buttonState={isDeleting ? "loading" : "ready"}
              setButtonState={() => {}}
              style="destructive"
              leftIcon="trash"
              classNameIn="w-full"
              id="deleteTableButton"
              disabled={isDeleting}
            />
            {error && <div className="text-red-600 text-sm mt-2">{error}</div>}
            <p className="text-sm text-gray-600 mt-2">
              This action cannot be undone. All table configuration and any
              stored records will be permanently removed.
            </p>
          </div>
        </div>
      </CommonModal>

      {/* Import JSON Modal */}
      <ImportJsonModal
        dataTable={dataTable}
        teamId={teamId}
        isOpen={isImportModalOpen}
        onClose={handleCloseImportModal}
        onSuccess={handleImportSuccess}
      />
      <BrowseDataTableModal
        dataTable={dataTable}
        teamId={teamId}
        isOpen={isBrowseModalOpen}
        onClose={handleCloseBrowseModal}
      />
    </>
  );
};

export default ManageDataTableModal;
