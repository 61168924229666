import React from "react";
import { Team } from "../../models/Team";
import { formatDate } from "../../utils/DateUtils";
import { TeamSubscription } from "../../models/TeamSubscription";
import { CommonModal } from "../Common/CommonModal";
import { AppPath } from "../../models/AppPath";
import TokenUsageCalculator from "./TokenUseCalculator";

interface TeamDetailModalProps {
  team: Team | null;
  subscription: TeamSubscription | null;
  isOpen: boolean;
  onClose: () => void;
}

const TeamDetailModal: React.FC<TeamDetailModalProps> = ({
  team,
  subscription,
  isOpen,
  onClose,
}) => {
  if (!team) return null;

  const openInNewTab = () => {
    // Get the team URL using AppPath utility
    const teamUrl = AppPath.team(team.id!);

    // Determine if we need the full URL or just the path
    // If AppPath.team returns a relative URL, we need to prepend the origin
    const fullUrl = teamUrl.startsWith("http")
      ? teamUrl
      : `${window.location.origin}${teamUrl}`;

    // Open in new tab
    window.open(fullUrl, "_blank");
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onClose}
      title={team.name}
      subtitle={`Created: ${formatDate(team.createdAt)}`}
    >
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-1 gap-2">
          <p className="text-sm text-gray-600">
            <span className="font-medium">Plan:</span>{" "}
            {subscription?.currentEvent?.name || "trialing"}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Status:</span>{" "}
            {subscription?.currentEvent?.status || "N/A"}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Members:</span>{" "}
            {Object.keys(team.members).length}
          </p>

          {subscription && <TokenUsageCalculator subscription={subscription} />}

          {subscription?.currentEvent && (
            <>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Daily Requests:</span>{" "}
                {subscription.currentEvent.dailyRequests}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Period:</span>{" "}
                {formatDate(subscription.currentEvent.currentPeriodStart)} -{" "}
                {formatDate(subscription.currentEvent.currentPeriodEnd)}
              </p>
            </>
          )}
        </div>

        {/* Team Members Section */}
        <div className="mt-4">
          <h3 className="text-sm font-medium mb-2">
            Team Members ({Object.keys(team.members).length})
          </h3>
          <div className="bg-gray-50 p-3 rounded-md max-h-60 overflow-y-auto">
            <table className="w-full text-sm">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                  <th className="px-2 py-1 text-left">Email</th>
                  <th className="px-2 py-1 text-left">Role</th>
                  <th className="px-2 py-1 text-left">ID</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(team.members).map(([userId, member]) => (
                  <tr key={userId} className="border-b border-gray-200">
                    <td className="px-2 py-1">{member.email}</td>
                    <td className="px-2 py-1 capitalize">{member.role}</td>
                    <td className="px-2 py-1 text-xs text-gray-500 truncate max-w-[120px]">
                      {userId}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={openInNewTab}
          >
            View Team
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default TeamDetailModal;
