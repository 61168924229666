import React from "react";
import { KnowledgeService } from "../services/KnowledgeService";
import KnowledgeContext from "../contexts/KnowledgeContext";

type Props = {
  children: React.ReactNode;
  knowledgeService: KnowledgeService;
};

export const KnowledgeServiceProvider: React.FC<Props> = ({
  children,
  knowledgeService,
}) => {
  return (
    <KnowledgeContext.Provider value={knowledgeService}>
      {children}
    </KnowledgeContext.Provider>
  );
};

export default KnowledgeServiceProvider;
