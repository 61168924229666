import React from "react";
import { GetDatabaseData } from "../../Map/Nodes/DatabaseNode";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";

interface GetComponentProps {
  data: GetDatabaseData;
  availableVariables: string[];
  updateNode: (newData: Partial<GetDatabaseData>) => void;
}

export const GetComponent: React.FC<GetComponentProps> = ({
  data,
  availableVariables,
  updateNode,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm font-medium text-gray-700">Data Point ID</div>
      <VariableInputSelector
        availableVariables={availableVariables}
        selectedInput={data.recordId}
        setSelectedInput={(input) => updateNode({ recordId: input })}
        currentNodeId={data.id}
        id="getRecordId"
        includeMessages={true}
      />
      <div className="text-xs text-gray-500">
        Select the input that contains the ID of the Data Point to retrieve
      </div>
    </div>
  );
};
