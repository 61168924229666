import React from "react";
import { UpdateDatabaseData } from "../../Map/Nodes/DatabaseNode";
import { DatabaseKnowledge } from "../../../../models/DatabaseKnowledge";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";

interface UpdateComponentProps {
  data: UpdateDatabaseData;
  selectedDatabase: DatabaseKnowledge;
  availableVariables: string[];
  updateNode: (newData: Partial<UpdateDatabaseData>) => void;
}

export const UpdateComponent: React.FC<UpdateComponentProps> = ({
  data,
  selectedDatabase,
  availableVariables,
  updateNode,
}) => {
  return (
    <div className="space-y-6">
      {/* Record ID field */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Data Point ID</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.recordId}
          setSelectedInput={(input) => updateNode({ recordId: input })}
          currentNodeId={data.id}
          id="recordId"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Select the input that contains the ID of the Data Point to update
        </div>
      </div>

      {/* Content field */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Record Content</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.content ?? { type: "text", value: "" }}
          setSelectedInput={(input) => updateNode({ content: input })}
          currentNodeId={data.id}
          id="content"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          The content that will be encoded and stored in the vector database
        </div>
      </div>

      {/* Operation toggles */}
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-4 items-center">
          <label className="flex items-center gap-2 text-sm text-gray-600">
            <input
              type="checkbox"
              checked={data.updateMetadata}
              onChange={(e) => updateNode({ updateMetadata: e.target.checked })}
              className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
            />
            Update metadata
          </label>
          <label className="flex items-center gap-2 text-sm text-gray-600">
            <input
              type="checkbox"
              checked={data.appendContent ?? false}
              onChange={(e) => updateNode({ appendContent: e.target.checked })}
              className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
            />
            Append content
          </label>
        </div>
        <label className="flex items-center gap-2 text-sm text-gray-600">
          <input
            type="checkbox"
            checked={data.isAsync ?? false}
            onChange={(e) => updateNode({ isAsync: e.target.checked })}
            className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          />
          Run asynchronously
        </label>
      </div>

      {/* Metadata fields */}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Metadata Fields</div>
        <div className="p-3 bg-gray-50 rounded-md border border-gray-200">
          {!data.updateMetadata ? (
            <div className="text-sm text-gray-500 italic">
              Enable "Update metadata" to modify these fields
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              {selectedDatabase.metadataFields.map((field, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <div className="text-sm font-medium text-gray-700">
                    {field.name}
                  </div>
                  <VariableInputSelector
                    availableVariables={availableVariables}
                    selectedInput={
                      data.metadata?.[field.name] ?? { type: "text", value: "" }
                    }
                    setSelectedInput={(input) => {
                      const newFields = {
                        ...data.metadata,
                        [field.name]: input,
                      };
                      updateNode({ metadata: newFields });
                    }}
                    currentNodeId={data.id}
                    id={`field_${field.name}`}
                    includeMessages={true}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="text-xs text-gray-500">
          Additional fields to store alongside the vector-encoded content
        </div>
      </div>
    </div>
  );
};
