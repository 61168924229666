import { createContext, useContext } from "react";
import { FileUploadService } from "../services/FileUploadService";

const FileUploadContext = createContext<FileUploadService | undefined>(
  undefined
);

export const useFileUpload = (): FileUploadService => {
  const fileUpload = useContext(FileUploadContext);

  if (!fileUpload) {
    throw new Error("useFileUpload must be used within an FileUploadProvider");
  }

  return fileUpload;
};

export default FileUploadContext;
