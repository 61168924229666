import React, { useEffect, useState } from "react";
import { WorkflowDemoDatabaseResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { CommonModal } from "../../../../Common/CommonModal";
import { useKnowledgeService } from "../../../../../contexts/KnowledgeContext";
import { DatabaseKnowledge } from "../../../../../models/DatabaseKnowledge";

export const DatabaseResultModal: React.FC<{
  result: WorkflowDemoDatabaseResult;
  shows: boolean;
  onClose: () => void;
  teamId: string;
}> = ({ result, shows, onClose, teamId }) => {
  const knowledgeService = useKnowledgeService();
  const [databaseDetails, setDatabaseDetails] =
    useState<DatabaseKnowledge | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDatabaseDetails = async () => {
      try {
        setIsLoading(true);
        const databases = await knowledgeService.databaseKnowledgeRepo.getList(
          knowledgeService.databaseKnowledgePath(teamId)
        );

        const database = databases.find((db) => db.id === result.databaseId);
        setDatabaseDetails(database || null);
      } catch (error) {
        console.error("Error fetching database details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (shows) {
      fetchDatabaseDetails();
    }
  }, [shows, teamId, knowledgeService, result.databaseId]);

  const getOperationLabel = (operation: string) => {
    switch (operation) {
      case "query":
        return "Search";
      case "write":
        return "Write";
      case "update":
        return "Update";
      case "delete":
        return "Delete";
      case "get":
        return "Get";
      default:
        return operation;
    }
  };

  return (
    <CommonModal isOpen={shows} onDismiss={onClose} title={result.name}>
      <div className="flex justify-end items-center mb-6">
        <StatusBadge status={result.status} />
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Database Details
          </h3>
          <div className="text-sm text-gray-600">
            {isLoading ? (
              <div className="animate-pulse bg-gray-100 h-20 rounded-md" />
            ) : (
              <div className="mb-2 p-2 bg-gray-50 rounded-md">
                <div className="font-medium">
                  {databaseDetails?.name || "Unknown Database"}
                </div>
                <div className="text-xs text-gray-500">
                  {databaseDetails?.metadataFields.length || 0} metadata fields
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Operation Details
          </h3>
          <div className="bg-gray-50 p-4 rounded-lg grid grid-cols-2 gap-4">
            <div>
              <div className="text-xs text-gray-500">Operation Type</div>
              <div className="text-lg font-medium">
                {getOperationLabel(result.operation)}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Mode</div>
              <div className="text-lg font-medium">
                {result.isAsync ? "Asynchronous" : "Synchronous"}
              </div>
            </div>
          </div>
        </div>

        {result.recordId && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Record Details
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg text-sm font-mono">
              {result.recordId}
            </div>
          </div>
        )}

        {result.processedContent && (
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Operation Result
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg text-sm whitespace-pre-wrap overflow-auto max-h-72">
              {result.processedContent}
            </div>
          </div>
        )}

        {result.error && (
          <div>
            <h3 className="text-sm font-medium text-red-700 mb-2">
              Error Details
            </h3>
            <div className="bg-red-50 p-4 rounded-lg text-sm text-red-600">
              {result.error}
            </div>
          </div>
        )}
      </div>
    </CommonModal>
  );
};
