import {
  ConversationalRequirement,
  RequirementType,
} from "../../../../models/WorkflowAction";
import { CommonInputSegment } from "../../../Common/CommonInput";
import Icon from "../../../Icon";

interface RequirementCardProps {
  requirement: ConversationalRequirement;
  onUpdate: (updates: Partial<ConversationalRequirement>) => void;
  onDelete: () => void;
}

export const ConversationalRequirementCard: React.FC<RequirementCardProps> = ({
  requirement,
  onUpdate,
  onDelete,
}) => {
  const isPhotoType = requirement.type === "mostRecentPhoto";

  const handleTypeChange = (newType: RequirementType) => {
    if (newType === "mostRecentPhoto") {
      onUpdate({
        type: newType,
        name: "userImageURL",
        description: "the context's userImageURL",
      });
    } else {
      onUpdate({
        type: newType,
        name: "",
        description: "",
      });
    }
  };

  return (
    <div className="mt-2 p-3 border-gray-200 border rounded-md">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <select
            value={requirement.type}
            onChange={(e) =>
              handleTypeChange(e.target.value as RequirementType)
            }
            className="text-sm bg-gray-50 border border-gray-200 rounded-md px-2 py-1"
          >
            <option value="text">Conversational</option>
            <option value="mostRecentPhoto">Most Recent Photo</option>
          </select>
          <button
            onClick={onDelete}
            className="p-1 text-gray-400 hover:text-gray-600"
          >
            <Icon type="x" className="size-4" />
          </button>
        </div>

        {isPhotoType ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Name</span>
              <span className="mt-1 text-sm text-gray-600 bg-gray-50 px-3 py-2 rounded-md">
                userImageURL
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                Description
              </span>
              <span className="mt-1 text-sm text-gray-600 bg-gray-50 px-3 py-2 rounded-md">
                The context's userImageURL
              </span>
            </div>
          </div>
        ) : (
          <>
            <CommonInputSegment
              title="Name"
              onChange={(name) => onUpdate({ name })}
              value={requirement.name}
              placeholder="Requirement name"
              error={undefined}
              setError={undefined}
              id={`req_name_${requirement.id}`}
              className="font-sans text-gray-700 bg-transparent"
            />

            <CommonInputSegment
              title="Description"
              onChange={(description) => onUpdate({ description })}
              value={requirement.description}
              placeholder="Describe what information is needed"
              error={undefined}
              setError={undefined}
              id={`req_desc_${requirement.id}`}
              className="font-sans text-gray-700"
            />
          </>
        )}
      </div>
    </div>
  );
};
