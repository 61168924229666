import { useState } from "react";
import {
  PaymentOption,
  SubscriptionOption,
} from "../../models/SubscriptionOption";
import { CommonSpinner } from "../Common/CommonLoading";

const SubscriptionCard: React.FC<{
  subscription: SubscriptionOption;
  yearly: boolean;
  selected: (
    subscription: SubscriptionOption,
    price: PaymentOption,
    enterprise: boolean
  ) => void;
  enterprise?: boolean;
  features: string[];
  highlightFirstFeature: boolean;
  trial: boolean;
}> = ({
  subscription,
  yearly,
  selected,
  enterprise,
  features,
  highlightFirstFeature,
  trial,
}) => {
  const [loading, setLoading] = useState(false);
  const paymentOptions = subscription.paymentOptions.filter(
    (p) => p.active == true
  );
  const month = paymentOptions.filter((p) => p.interval == "month")[0];
  const year = paymentOptions.filter((p) => p.interval == "year")[0];
  const calculatePrice = () => {
    if (yearly) {
      const monthPrice = Number(year.price) / 12;
      const p = Math.pow(10, 2);
      return `${Math.round(monthPrice * p) / p}`;
    } else {
      return `${month.price}`;
    }
  };
  const calculatePriceDescription = () => {
    if (yearly) {
      return `per month / $${year.price} yearly`;
    } else {
      const yearPrice = Number(month.price) * 0.12;
      const p = Math.pow(10, 2);
      return `per month / $${Math.round(yearPrice * p)} yearly`;
    }
  };
  const handleSelected = () => {
    setLoading(true);
    selected(subscription, yearly ? year : month, enterprise == true);
  };
  return (
    <div
      key={subscription.id}
      className={`w-[304px] p-8 bg-white rounded-lg shadow-sm border ${
        subscription.mostPopular ? "border-fuchsia-300" : "border-gray-200"
      } flex flex-col`}
    >
      <div className="flex-grow flex flex-col gap-8">
        <div className="flex-grow flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <div className="text-gray-800 text-2xl font-gooper">
                {subscription.name}
              </div>
              {subscription.mostPopular && (
                <div className="px-2.5 py-1 bg-fuchsia-300 gap-1 rounded-md flex items-center justify-center">
                  <svg
                    className="w-4 h-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeWidth="1"
                      d="M11.083 5.104c.35-.8 1.485-.8 1.834 0l1.752 4.022a1 1 0 0 0 .84.597l4.463.342c.9.069 1.255 1.2.556 1.771l-3.33 2.723a1 1 0 0 0-.337 1.016l1.03 4.119c.214.858-.71 1.552-1.474 1.106l-3.913-2.281a1 1 0 0 0-1.008 0L7.583 20.8c-.764.446-1.688-.248-1.474-1.106l1.03-4.119A1 1 0 0 0 6.8 14.56l-3.33-2.723c-.698-.571-.342-1.702.557-1.771l4.462-.342a1 1 0 0 0 .84-.597l1.753-4.022Z"
                    />
                  </svg>

                  <div className="text-center text-white text-xs font-medium leading-none">
                    Most popular
                  </div>
                </div>
              )}
            </div>
            <div className="text-gray-500 text-sm font-normal leading-relaxed text-left tracking-tight">
              {subscription.description}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center gap-2">
              <div className="text-gray-800 text-5xl font-semibold font-gooper">
                {enterprise ? "Call" : calculatePrice()}
              </div>
              <div className="font-gooper font-medium">
                {enterprise ? "Us" : "USD"}
              </div>
            </div>
            {!enterprise && (
              <div className="text-center text-gray-500 text-sm font-normal">
                {calculatePriceDescription()}
              </div>
            )}
          </div>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          {features.map((feature, index) => (
            <div key={feature} className="flex items-center gap-2">
              <input
                disabled
                checked
                type="checkbox"
                className="w-4 h-4 text-green-500 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <div
                className={`text-gray-500 text-sm font-normal ${
                  index == 0 && highlightFirstFeature ? "font-gooper" : ""
                } text-left leading-tight`}
              >
                {feature}
              </div>
            </div>
          ))}
        </div>
        <button
          id={`select${subscription.name}`}
          onClick={() => handleSelected()}
          className="px-5 py-3 bg-blue-500 hover:bg-blue-600 rounded-full flex gap-2 items-center justify-center shadow transition-all hover:shadow-lg"
        >
          {loading ? (
            <CommonSpinner size="sm" />
          ) : (
            <div className="text-white text-sm font-normal font-gooper">
              {trial ? `Start free trial` : `purchase ${subscription.name}`}
            </div>
          )}
          {!loading && (
            <svg
              className="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
