import React, { useEffect, useState } from "react";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { Workflow } from "../../models/Workflow";
import Icon from "../Icon";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { CommonErrorToast } from "../Common/CommonErrorToast";
import { AppPath } from "../../models/AppPath";
import { useTeams } from "../../contexts/TeamContext";
import { filterExampleWorkflows } from "../../utils/FilterUtils";
import { useAnalytics } from "../../contexts/AnalyticsContext";

interface AgentsCardProps {
  teamId: string;
  providerId?: string;
}

export const AgentsCard: React.FC<AgentsCardProps> = ({
  teamId,
  providerId,
}) => {
  const workflowService = useWorkflowService();
  const navigate = useNavigate();
  const teamService = useTeams();
  const analytics = useAnalytics();

  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWorkflows = async () => {
      setLoading(true);
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      const workflows = await workflowService.workflowRepo.getList(
        workflowService.workflowPath(teamId!),
        { name: "modifiedAt", descending: true },
        undefined,
        5
      );
      setWorkflows(
        workflows.filter((w) => filterExampleWorkflows(team ?? undefined, w))
      );
      if (providerId) {
        const getVersions = workflows.map((workflow) =>
          workflowService.workflowVersionRepo
            .getList(
              workflowService.workflowVersionPath(teamId!, workflow.id!),
              undefined,
              [{ key: "configId", filter: "==", value: providerId }]
            )
            .then((versions) => {
              return { workflow: workflow, versions: versions };
            })
        );
        const fetchedData = await Promise.all(getVersions);
        const filtered = fetchedData
          .filter((d) => d.versions.length)
          .sort((d) => d.versions.length);
        const fetchedWorkflows = filtered.map((d) => d.workflow);
        setWorkflows(fetchedWorkflows);
      } else {
        setWorkflows(workflows);
      }
      setLoading(false);
    };

    fetchWorkflows();
  }, []);

  const handleCreateNew = async () => {
    setLoading(true);
    setError(null);
    try {
      const newWorkflow = await workflowService.createWorkflow(teamId!);
      analytics.track("createdAgent");
      navigate(
        AppPath.workflow(
          teamId!,
          newWorkflow.id!,
          newWorkflow.currentVersionId,
          true
        )
      );
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create agent. Please try again."
      );
      setLoading(false);
    }
  };

  const handleOpen = (workflow: Workflow) => {
    navigate(AppPath.workflow(teamId, workflow.id!));
  };

  return (
    <div className="bg-gray-0 w-full min-h-52 rounded-lg border border-gray-200 flex flex-col">
      <div className="flex flex-row px-4 pt-4 pb-2 border-b justify-between">
        <div className="text-gray-700 text-base font-medium font-gooper">
          Agents
        </div>
        <button
          type="submit"
          id="createAgentButton"
          className={`relative flex fill-blue-500 gap-1 items-center justify-center text-sm font-gooper text-blue-500`}
          onClick={() => handleCreateNew()}
        >
          Add new
          <Icon type={"plus"} className="size-4" />
          {error && (
            <CommonErrorToast message={error} onClose={() => setError(null)} />
          )}
        </button>
      </div>
      {loading ? (
        <div className="w-full h-full items-center flex justify-center">
          <Spinner className="fill-pink-500" size="xl" light={true} />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-2 border-0 p-4">
          {workflows.map((workflow) => {
            return (
              <div
                key={workflow.id}
                className="flex flex-row w-full justify-between bg-green"
              >
                <div className="flex flex-row w-full gap-2 items-center">
                  <Icon type={"workflows"} className="size-4 text-gray-200" />
                  <div className="text-gray-700 font-gooper text-xs font-medium">
                    {workflow.name}
                  </div>
                </div>
                <div className="flex justify-end flex-row w-full gap-1 text-xs font-normal items-center">
                  <div className="text-gray-400">
                    {`${workflow.currentVersionData?.name ?? ""}`}
                  </div>
                  <button
                    type="submit"
                    className={`flex items-center justify-center text-xs font-gooper text-blue-500 underline`}
                    onClick={() => handleOpen(workflow)}
                  >
                    Open Agent
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
