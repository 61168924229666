import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import { KnowledgeSections } from "./KnowledgeSections";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import { AppPath } from "../../models/AppPath";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { DocumentKnowledge } from "../../models/DocumentKnowledge";
import { AddDocumentModal } from "./AddDocumentModal";
import { ManageDocumentModal } from "./ManageDocumentModal";
import { StatusBadge } from "../TestCenter/StatusBadge";
import { formatDate, formatTime } from "../../utils/DateUtils";

export const KnowledgeDocuments: React.FC = () => {
  const knowledgeService = useKnowledgeService();
  const { teamId } = useParams<{ teamId: string }>();
  const teamService = useTeams();

  const [documents, setDocuments] = useState<DocumentKnowledge[]>([]);
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [addDocumentState, setAddDocumentState] =
    useState<AnimationState>("ready");

  const [searchParams, setSearchParams] = useSearchParams();
  const managingDocumentId = searchParams.get("manage");
  const managingDocument = documents.find((d) => d.id === managingDocumentId);
  const observationRef = useRef<(() => void) | null>(null);

  const handleManageClick = (documentId: string) => {
    setSearchParams({ manage: documentId });
  };

  const handleModalClose = () => {
    setSearchParams({});
  };

  const loadDocuments = async () => {
    const fetchedDocuments =
      await knowledgeService.documentKnowledgeRepo.getList(
        knowledgeService.documentKnowledgePath(teamId!)
      );
    setDocuments(fetchedDocuments);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedTeam = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );

        if (fetchedTeam) {
          setTeam(fetchedTeam);
        }

        observationRef.current?.();
        const observation = knowledgeService.documentKnowledgeRepo.observeList(
          knowledgeService.documentKnowledgePath(teamId!),
          (updatedDocuments) => {
            setDocuments(
              updatedDocuments.sort((a, b) =>
                (a.title ?? "").localeCompare(b.title ?? "")
              )
            );
            setLoading(false);
          }
        );
        observationRef.current = observation;
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went wrong while loading document sources");
        }
      }
    };

    loadData();
    return () => {
      observationRef.current?.();
    };
  }, [teamService, knowledgeService, teamId]);

  return (
    <CommonContainer>
      {managingDocument && (
        <ManageDocumentModal
          document={managingDocument}
          teamId={teamId!}
          isOpen={!!managingDocumentId}
          onClose={handleModalClose}
          onSuccess={loadDocuments}
        />
      )}
      <CommonHeader
        title="Knowledge Base"
        subtitle="Upload and manage document sources for your AI agents to learn from."
        sections={[
          { name: "Knowledge", link: AppPath.settings(teamId ?? "") },
          { name: "Documents", link: AppPath.knowledgeDocuments(teamId ?? "") },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Add Document"
            onClick={() => setShowAddDocument(true)}
            buttonState={addDocumentState}
            setButtonState={setAddDocumentState}
            style="action"
            key="addDocument"
            error={error}
            setError={setError}
            id="addDocumentButton"
            leftIcon="plus"
          />,
        ]}
      />
      <FailureModal
        message={error ?? undefined}
        shows={error !== null}
        closed={() => setError("")}
      />
      <KnowledgeSections selectedId="documents" />
      {showAddDocument && (
        <AddDocumentModal
          teamId={teamId!}
          isOpen={showAddDocument}
          onClose={() => setShowAddDocument(false)}
          onSuccess={loadDocuments}
        />
      )}
      {loading && <CommonSpinner />}
      {team && (
        <div className="flex flex-col gap-2 mt-4">
          {documents.map((document) => (
            <DocumentRow
              key={document.id}
              document={document}
              onManageClick={() => handleManageClick(document.id!)}
            />
          ))}
          {documents.length === 0 && (
            <div className="text-center text-gray-500 py-8">
              No documents uploaded yet. Click "Upload Document" to get started.
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};

interface DocumentRowProps {
  document: DocumentKnowledge;
  onManageClick: () => void;
}

const DocumentRow: React.FC<DocumentRowProps> = ({
  document,
  onManageClick,
}) => {
  return (
    <div className="px-6 bg-white shadow h-28 rounded-lg grid grid-cols-[1fr_auto] items-center w-full">
      <div className="min-w-0 flex gap-2 flex-col">
        <div className="text-lg font-medium truncate">{document.title}</div>
        <div className="flex flex-row gap-2 items-center text-xs text-gray-500">
          <StatusBadge status={document.status} />
          {`${formatDate(document.lastUpdate)} ${formatTime(
            document.lastUpdate
          )}`}
        </div>
      </div>
      <div className="flex items-center gap-6 pl-4">
        <div className="text-gray-400 text-sm">
          {document.encodingStrategy.name}
        </div>
        <div className="text-gray-400 text-sm"></div>
        <AnimatedButton
          title="Manage"
          style="action"
          setButtonState={undefined}
          buttonState="ready"
          onClick={onManageClick}
          classNameIn="w-32"
          leftIcon="share-nodes"
          font="font-sans"
          id={`manage${document.id}Button`}
        />
      </div>
    </div>
  );
};
