import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";

interface CopyWorkflowModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: (teamId: string) => Promise<void>;
  workflowName: string;
}

export const CopyWorkflowModal: React.FC<CopyWorkflowModalProps> = ({
  isOpen,
  onDismiss,
  onSubmit,
  workflowName,
}) => {
  const [teamId, setTeamId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onSubmit(teamId);
      onDismiss();
    } catch (error) {
      console.error("Error copying workflow:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={`Copy Workflow to Team`}
      subtitle={` Copy "${workflowName}" to another team`}
    >
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="teamId"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Team ID
          </label>
          <input
            type="text"
            id="teamId"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter team ID"
            required
          />
        </div>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={onDismiss}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {isLoading ? "Copying..." : "Copy"}
          </button>
        </div>
      </form>
    </CommonModal>
  );
};
