import React, { useEffect, useState } from "react";
import { CommonSpinner } from "../Common/CommonLoading";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import { WorkflowLog } from "../../models/WorkflowLog";
import Icon from "../Icon";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { MessagesCard } from "../Workflows/Sidebar/Meta/DemoResultCards/MessagesCard";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton from "../AnimatedButton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { WorkflowResultCard } from "../Workflows/Sidebar/Meta/WorkflowResultCard";

interface WorkflowLogModalProps {
  workflowLog?: WorkflowLog;
  logId?: string;
  teamId?: string;
  onClose: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  isOpen: boolean;
  loading?: boolean;
}

export const WorkflowLogModal: React.FC<WorkflowLogModalProps> = ({
  workflowLog,
  logId,
  teamId,
  onClose,
  onNext,
  onPrevious,
  isOpen,
  loading = false,
}) => {
  const logsService = usePortalLogService();
  const [log, setLog] = useState<WorkflowLog | undefined>(undefined);

  useEffect(() => {
    if (workflowLog) {
      setLog(workflowLog);
    } else if (logId && teamId) {
      const loadData = async () => {
        const fetchedLog = await logsService.workflowLogRepo.get(
          logsService.workflowLogPath(teamId),
          logId
        );
        setLog(fetchedLog ?? undefined);
      };
      loadData();
    } else {
      setLog(undefined);
    }
  }, [logsService, workflowLog, logId, teamId]);

  const statusColor = (): string => {
    switch (log?.status) {
      case "SUCCESS":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "SECURITY_WARNING":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (log?.status) {
      case "SUCCESS":
        return "success";
      case "ERROR":
        return "error";
      case "SECURITY_WARNING":
        return "security";
      default:
        return "";
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose} title="Agent Log">
      <div className="flex justify-between items-center gap-2">
        <AnimatedButton
          title=""
          onClick={() => onPrevious?.()}
          buttonState={loading ? "loading" : "ready"}
          style="normal"
          leftChild={<Icon type="chevron-up" />}
          disabled={loading || !onPrevious}
        />
        <AnimatedButton
          title=""
          onClick={() => onNext?.()}
          buttonState={loading ? "loading" : "ready"}
          style="normal"
          leftChild={<Icon type="chevron-up" className="rotate-180" />}
          disabled={loading || !onNext}
        />
      </div>

      {loading && <CommonSpinner />}

      {log && !loading && (
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span>Created</span>
              <span>{formatDate(log.createdAt)}</span>
              <span>{formatTime(log.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs">{log.workflowName}</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {log.versionName}
            </span>
          </div>

          {log.errorMessage && (
            <div className="w-full p-2 bg-red-50 rounded-lg flex-col justify-center items-center gap-2 inline-flex">
              <div className="self-stretch text-center text-red-500 text-xs font-normal leading-none tracking-wide">
                Error Message
              </div>
              <div className="self-stretch text-red-900 text-base font-normal leading-normal">
                {log.errorMessage}
              </div>
            </div>
          )}

          <MessagesCard messages={log.messages} />

          {log.steps && log.steps.length > 0 && (
            <div className="flex flex-col gap-4">
              <div className="text-sm font-medium text-gray-600">
                Execution Steps
              </div>
              <div className="flex flex-col gap-2">
                {log.steps.map((result) => (
                  <WorkflowResultCard result={result} teamId={teamId!} />
                ))}
              </div>
            </div>
          )}

          {log.response && (
            <div className="border border-gray-200 w-full p-2 bg-gray-50 rounded-lg flex-col justify-center items-center gap-2 inline-flex">
              <div className="self-stretch text-center text-gray-500 text-xs font-normal leading-none tracking-wide">
                Final Response
              </div>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={`prose text-sm  ${"text-slate-900"}`}
              >
                {log.response?.content}
              </ReactMarkdown>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <InfoComponent
              title="Tokens used"
              info={`${log.tokensUsed ?? 0}`}
            />
            <InfoComponent title="Steps" info={`${log.steps?.length ?? 0}`} />
            <InfoComponent
              title="Duration"
              info={`${log.duration?.toFixed(0) ?? 0}ms`}
            />
            <InfoComponent
              title="Latency"
              info={`${log.latency?.toFixed(0) ?? 0}ms`}
            />
            <InfoComponent title="User ID" info={log.userId ?? "--"} />
            <InfoComponent title="IP Address" info={log.ipAddress} />
          </div>
        </div>
      )}
    </CommonModal>
  );
};

const InfoComponent: React.FC<{
  title: string;
  info: string;
}> = ({ title, info }) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="text-sm text-gray-600">{title}</div>
      <div className="text-lg font-gooper text-gray-900">{info}</div>
    </div>
  );
};

export default WorkflowLogModal;
