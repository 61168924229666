import React from "react";
import { useReactFlow, Position } from "reactflow";
import Icon from "../../../Icon";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { createSelectorNode } from "./SelectorNode";

export const AsyncBranchNodeType = "workflowAsyncBranchNodeType";

export interface AsyncBranchNodeData {
  id: string;
  teamId: string;
  type: typeof AsyncBranchNodeType;
  title: string;
}

export interface AsyncBranchNodeParams {
  data: AsyncBranchNodeData;
}

export const ASYNC_BRANCH_POSTFIX = "_right_source";

export const AsyncBranchNode: React.FC<AsyncBranchNodeParams> = ({ data }) => {
  const { getNode, getEdges, setNodes } = useReactFlow();

  const rightHandleId = `${data.id}${ASYNC_BRANCH_POSTFIX}`;

  const sourceConnected = (id: string) => {
    const edges = getEdges();
    return edges.some((edge) => {
      return edge.sourceHandle === id;
    });
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  const handleAdd = (source: string) => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(
      currentNode,
      data.teamId,
      undefined,
      undefined,
      source
    );
    setNodes((nds) => nds.concat(selectionNode));
  };

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <AsyncBranchBadge />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full">
        <AddNodeHandler
          type="source"
          isConnected={sourceConnected(rightHandleId)}
          onClick={() => handleAdd(rightHandleId)}
          id={rightHandleId}
          position={Position.Right}
        />
      </div>
      <AddNodeHandler
        onClick={() => handleAdd(`${data.id}_source`)}
        id={`${data.id}_source`}
        type="source"
        isConnected={sourceConnected(`${data.id}_source`)}
      />
    </div>
  );
};

export const AsyncBranchBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 rounded justify-center items-center gap-1 flex bg-violet-50 text-violet-400">
      <Icon type="arrow-up-right" className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        Async
      </div>
    </div>
  );
};

// Helper function to create an AsyncBranchNode (similar to createSelectorNode)
export const createAsyncBranchNode = (
  parentNode: any,
  teamId: string,
  title: string = "Async Branch",
  sourceHandle?: string
) => {
  const position = { x: 0, y: 0 };
  if (parentNode) {
    position.x = parentNode.position.x + 250;
    position.y = parentNode.position.y + 100;
  }

  return {
    id: `async_branch_${Date.now()}`,
    type: AsyncBranchNodeType,
    position,
    data: {
      id: `async_branch_${Date.now()}`,
      teamId,
      type: AsyncBranchNodeType,
      title,
      sourceHandle,
    },
  };
};
