import React from "react";
import { useReactFlow } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { SelectedInput } from "../../Sidebar/VariableInputSelector";
import { ChunkEncodingStrategy } from "../../../../models/ChunkEncodingStrategy";

export const DatabaseNodeType = "workflowDatabaseNodeType";

export type DatabaseOperation = "query" | "write" | "update" | "delete" | "get";

export interface GetDatabaseData extends BaseDatabaseNodeData {
  operation: "get";
  recordId: SelectedInput; // Required for get
}

// Base interface for common properties
export interface BaseDatabaseNodeData {
  id: string;
  teamId: string;
  type: typeof DatabaseNodeType;
  title: string;
  databaseId: string;
  configId: string;
  baseURL: string;
}

// Query-specific data
export interface QueryDatabaseData extends BaseDatabaseNodeData {
  operation: "query";
  searchQuery: SelectedInput;
  contextWindow: number;
  resultCount: number;
  similarityThreshold: number;
  metadataFilters?: {
    [key: string]: SelectedInput | undefined;
  };
  useMetadataFilters: boolean; // Toggle for enabling metadata filtering
}

// Write-specific data
export interface WriteDatabaseData extends BaseDatabaseNodeData {
  operation: "write";
  recordId?: SelectedInput; // Optional for write (will auto-generate if not provided)
  content: SelectedInput; // The actual content to be encoded
  metadata: {
    [key: string]: SelectedInput | undefined;
  };
  isAsync?: boolean;
  appendContent?: boolean;
  encodingStrategy: ChunkEncodingStrategy;
}

// Update-specific data
export interface UpdateDatabaseData extends BaseDatabaseNodeData {
  operation: "update";
  recordId: SelectedInput; // Required for update
  content?: SelectedInput; // Optional for update
  metadata?: {
    // Optional for update
    [key: string]: SelectedInput | undefined;
  };
  updateMetadata: boolean; // Whether to update metadata fields
  isAsync?: boolean;
  appendContent?: boolean;
}

// Delete-specific data
export interface DeleteDatabaseData extends BaseDatabaseNodeData {
  operation: "delete";
  recordId: SelectedInput;
  isAsync?: boolean;
}

export type DatabaseNodeData =
  | QueryDatabaseData
  | WriteDatabaseData
  | UpdateDatabaseData
  | DeleteDatabaseData
  | GetDatabaseData;

export interface DatabaseNodeParams {
  data: DatabaseNodeData;
}

export const DatabaseNode: React.FC<DatabaseNodeParams> = ({ data }) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(currentNode, data.teamId);
    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === data.id);
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  const getOperationLabel = (operation: DatabaseOperation) => {
    switch (operation) {
      case "query":
        return "Search";
      case "write":
        return "Write";
      case "update":
        return "Update";
      case "delete":
        return "Delete";
      case "get":
        return "Get";
      default:
        return "Unknown";
    }
  };

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <DatabaseBadge />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500 truncate">
          {getOperationLabel(data.operation)} Operation
        </div>
        {data.operation === "query" && (
          <div className="text-xs text-gray-400 truncate">
            {data.resultCount} Results
          </div>
        )}
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${data.id}_source`}
      />
    </div>
  );
};

export const DatabaseBadge: React.FC<{}> = ({}) => {
  return (
    <div
      className={`px-2.5 py-0.5 rounded justify-center items-center gap-1 flex bg-gray-50 text-gray-400`}
    >
      <Icon type={"database"} className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        Database
      </div>
    </div>
  );
};
