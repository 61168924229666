import React from "react";
import AnimatedButton from "../../../AnimatedButton";
import Icon from "../../../Icon";
import { PromptInput } from "./PromptInput";
import { extractVariables } from "../../../../utils/PromptUtil";

interface PromptSuggestion {
  prompt: string;
  timestamp: string;
}

interface PromptSuggestionBoxProps {
  suggestions: PromptSuggestion[];
  currentIndex: number;
  onNavigate: (index: number) => void;
  onSelectPrompt: (prompt: string) => void;
  teamId: string;
}

export const PromptSuggestionBox: React.FC<PromptSuggestionBoxProps> = ({
  suggestions,
  currentIndex,
  onNavigate,
  onSelectPrompt,
  teamId,
}) => {
  const variables = [
    ...new Set(extractVariables(suggestions[currentIndex]?.prompt)),
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-1 min-h-0 p-6">
        {suggestions.length > 0 ? (
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto">
              <div className="bg-white rounded-lg border border-gray-200 p-4">
                <div className="flex flex-col">
                  <div className="text-gray-700 font-gooper pb-2 pt-2">
                    Variables
                  </div>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {variables.map((v) => (
                      <div
                        id={`agentVariableItem_${v}`}
                        key={v}
                        className="h-6 px-1.5 py-0.5 bg-gray-100 rounded justify-start items-center gap-1 inline-flex"
                      >
                        <div className="text-center text-gray-500 text-xs font-medium font-['General Sans'] leading-[18px]">
                          {v}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-gray-700 font-gooper pt-2 pb-2">
                  Prompt
                </div>
                <PromptInput
                  variables={variables}
                  prompt={suggestions[currentIndex]?.prompt}
                  disabled={true}
                  updated={() => {}}
                  teamId={teamId}
                />

                <div className="mt-2 text-xs text-gray-500">
                  {new Date(
                    suggestions[currentIndex]?.timestamp
                  ).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No prompt suggestions yet. Start a conversation to get suggestions.
          </div>
        )}
      </div>

      <div className="flex-none p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <button
              onClick={() => onNavigate(currentIndex - 1)}
              disabled={currentIndex <= 0}
              className={`p-1 rounded hover:bg-gray-100 ${
                currentIndex <= 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <Icon
                type="chevron"
                className="size-4 text-gray-600 -rotate-90"
              />
            </button>
            <span className="text-sm text-gray-600">
              {suggestions.length > 0
                ? `${currentIndex + 1} of ${suggestions.length}`
                : "No suggestions"}
            </span>
            <button
              onClick={() => onNavigate(currentIndex + 1)}
              disabled={currentIndex >= suggestions.length - 1}
              className={`p-1 rounded hover:bg-gray-100 ${
                currentIndex >= suggestions.length - 1
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <Icon type="chevron" className="size-4 text-gray-600 rotate-90" />
            </button>
          </div>
          <AnimatedButton
            title="Use This Prompt"
            onClick={() => onSelectPrompt(suggestions[currentIndex]?.prompt)}
            buttonState="ready"
            style="action"
            leftChild={<Icon type="check" className="size-4" />}
          />
        </div>
      </div>
    </div>
  );
};
