import { useState, useEffect, useRef } from "react";
import { ChatMessage } from "../../../models/ChatMessage";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import Icon from "../../Icon";
import { ChatBubble } from "./ChatBubble";
import { useFileUpload } from "../../../contexts/FileUploadContext";
import { UploadState } from "../../../services/FileUploadService";
import { CommonSpinner } from "../../Common/CommonLoading";
import { CommonErrorToast } from "../../Common/CommonErrorToast";

export const DemoChat: React.FC<{
  messages: ChatMessage[];
  running: boolean;
  teamName: string;
  sendMessage: (message: string, imageUrl?: string) => void;
  teamId: string;
}> = ({ messages, running, teamName, sendMessage, teamId }) => {
  const [sendState, setSendState] = useState<AnimationState>("ready");
  const [newMessage, setNewMessage] = useState("");
  const [pendingImageUrl, setPendingImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileUpload = useFileUpload();

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      setError("Image size must be less than 10MB");
      return;
    }

    try {
      const path = `teams/${teamId}/demo-chat-images`;
      const url = await fileUpload.uploadFile(file, path);
      setPendingImageUrl(url);
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() || pendingImageUrl) {
      sendMessage(newMessage.trim(), pendingImageUrl || undefined);
      setNewMessage("");
      setPendingImageUrl(null);
    }
  };

  const [uploadState, setUploadState] = useState<UploadState>({
    isUploading: false,
    progress: null,
  });

  useEffect(() => {
    return fileUpload.subscribe(setUploadState);
  }, [fileUpload]);

  const { isUploading } = uploadState;
  const disableChat = newMessage.length < 1 && !pendingImageUrl;

  return (
    <div className="flex flex-col h-full relative">
      {error && (
        <div className="absolute top-0 left-0 -translate-x-1/2">
          <CommonErrorToast message={error} onClose={() => setError(null)} />
        </div>
      )}
      <div
        className="flex-grow rounded-t flex flex-col gap-4 p-4 overflow-y-auto bg-gray-50 shadow-[inset_0_-4px_4px_-2px_rgba(0,0,0,0.1),_inset_0_-2px_4px_-1px_rgba(0,0,0,0.06)]"
        ref={messagesContainerRef}
      >
        {messages.length === 0 && (
          <div className="flex flex-col gap-2 p-2 text-sm">
            <div className="text-gray-500">
              Press Preview to see your Prompt in action and see what your
              prompt will generate.
            </div>
            <div className="text-gray-500">
              Respond with a reply below to keep the conversation going.
            </div>
          </div>
        )}
        {messages.map((message, index) => (
          <ChatBubble
            loading={running}
            teamName={teamName}
            message={message}
            key={message.id}
            isLast={index + 1 === messages.length}
            id={`message${index + 1}`}
          />
        ))}
      </div>
      <div className="flex flex-col bg-gray-50 border-t border-gray-200 rounded-b">
        <textarea
          className="bg-gray-50 placeholder-gray-500 border-0 h-20 focus:border-transparent focus:ring-0 focus:outline-none resize-none"
          id="message"
          placeholder="Write a reply ..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="flex flex-row gap-2 pt-2">
          <input
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageUpload}
            ref={fileInputRef}
          />
          <AnimatedButton
            title={`Send message`}
            onClick={() => handleSendMessage()}
            buttonState={sendState}
            setButtonState={setSendState}
            style={"action"}
            key="send"
            classNameIn="flex-grow"
            disabled={disableChat}
            leftChild={<Icon type="paper-plane" className="rotate-90 size-4" />}
            font="font-sans font-medium"
            id="sendMessageButton"
          />
          <button
            onClick={() => {
              if (pendingImageUrl) {
                setPendingImageUrl(null);
              } else {
                fileInputRef.current?.click();
              }
            }}
            className="px-3 py-2 rounded bg-gray-100 hover:bg-gray-200 transition-colors relative"
            disabled={isUploading}
          >
            {isUploading ? (
              <CommonSpinner size="sm" />
            ) : pendingImageUrl ? (
              <Icon type="check" className="size-4 text-emerald-600" />
            ) : (
              <Icon type="camera" className="size-4" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
