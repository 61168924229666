import React, { useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { PortalVersion } from "../../../models/PortalVersion";
import { CommonModal } from "../../Common/CommonModal";

interface LiveConfirmationModalProps {
  isOpen: boolean;
  currentLiveVersion: PortalVersion | undefined;
  onConfirm: () => void;
  onCancel: () => void;
}

export const LiveConfirmationModal: React.FC<LiveConfirmationModalProps> = ({
  isOpen,
  currentLiveVersion,
  onConfirm,
  onCancel,
}) => {
  const [confirmState, setConfirmState] = useState<AnimationState>("ready");

  const currentUrl = window.location.href;
  const newUrl = currentLiveVersion
    ? currentUrl.substring(0, currentUrl.lastIndexOf("/")) +
      "/" +
      currentLiveVersion.id
    : "";
  const handleAction = () => {
    onCancel();
    onConfirm();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onDismiss={onCancel}
      title="Are you ready to publish?"
    >
      {currentLiveVersion ? (
        <p className="mb-6 text-center text-gray-500">
          Going live will replace the current live version{" "}
          <a
            href={newUrl}
            className="text-blue-500 hover:underline"
            onClick={() => onCancel()}
          >
            {currentLiveVersion.name}
          </a>
          . You will have to set it to draft in order to take it offline.
        </p>
      ) : (
        <p className="text-center text-gray-500 mb-6">
          This version will be avilable to the API via the prompt's ID.
        </p>
      )}
      <div className="grid grid-cols-2 w-full gap-2">
        <AnimatedButton
          title={`Back`}
          onClick={() => onCancel()}
          buttonState={"ready"}
          style={"normal"}
          key="cancel"
          font="font-sans"
          id="cancelButton"
        />
        <AnimatedButton
          title={`Publish Version`}
          onClick={() => handleAction()}
          buttonState={confirmState}
          setButtonState={setConfirmState}
          style={"live"}
          leftIcon="portals"
          key="action"
          font="font-sans"
          id="confirmButton"
        />
      </div>
    </CommonModal>
  );
};
