import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import {
  KnowledgeNodeData,
  KnowledgeNodeType,
} from "../../Map/Nodes/KnowledgeNode";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { useEffect, useState } from "react";
import { useKnowledgeService } from "../../../../contexts/KnowledgeContext";
import { WebsiteKnowledge } from "../../../../models/WebsiteKnowledge";
import { DocumentKnowledge } from "../../../../models/DocumentKnowledge";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";
import { AppPath } from "../../../../models/AppPath";
import { KnowledgeTestModal } from "./KnowledgeTestModal";
import AnimatedButton from "../../../AnimatedButton";
import { AdvancedQuerySettingsModal } from "./KnowledgeAdvancedSettingsModal";
import Icon from "../../../Icon";
import { CommonSpinner } from "../../../Common/CommonLoading";
import InfoComponent from "../InfoComponent";

interface KnowledgeSourceSection {
  title: string;
  items: (WebsiteKnowledge | DocumentKnowledge)[];
  type: "website" | "document";
}

export const SidebarKnowledgeDetail: React.FC<{
  data: KnowledgeNodeData;
  team: Team;
  version: WorkflowVersion;
}> = ({ data, team, version }) => {
  const knowledgeService = useKnowledgeService();
  const { setNodes } = useReactFlow();
  const [availableWebsites, setAvailableWebsites] = useState<
    WebsiteKnowledge[]
  >([]);
  const [childPages, setChildPages] = useState<Map<string, WebsiteKnowledge[]>>(
    new Map()
  );
  const [expandedParents, setExpandedParents] = useState<Set<string>>(
    new Set()
  );
  const [loadingChildPages, setLoadingChildPages] = useState<Set<string>>(
    new Set()
  );
  const [availableDocuments, setAvailableDocuments] = useState<
    DocumentKnowledge[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const [websites, documents] = await Promise.all([
          knowledgeService.websiteKnowledgeRepo.getList(
            knowledgeService.websiteKnowledgePath(team.id!)
          ),
          knowledgeService.documentKnowledgeRepo.getList(
            knowledgeService.documentKnowledgePath(team.id!)
          ),
        ]);

        setAvailableWebsites(websites);
        setAvailableDocuments(documents);

        // Create a list of all available website IDs initially (parents only)
        const availableParentIds = new Set(
          websites.map((website) => website.id)
        );

        // Create a map for easier access to website objects
        const websiteMap = new Map(
          websites.map((website) => [website.id, website])
        );

        // Find all parent websites that are selected
        const selectedParentIds = data.websiteSources.filter((id) =>
          availableParentIds.has(id)
        );

        // Track all valid IDs - start with valid parent IDs
        const allValidIds = new Set(selectedParentIds);

        // First, load all children for selected parents
        const loadedChildrenMap = new Map();

        // Load children for all parents that have children and are selected
        const parentsToLoad = selectedParentIds.filter((id) => {
          const website = websiteMap.get(id);
          return (
            website && website.hasChildren && (website.successCount || 0) > 0
          );
        });

        // Set expanded parents for UI
        if (parentsToLoad.length > 0) {
          setExpandedParents(new Set(parentsToLoad));
        }

        // Load all child pages for selected parents
        for (const parentId of parentsToLoad) {
          try {
            setLoadingChildPages((prev) => new Set(prev).add(parentId));

            const children =
              await knowledgeService.websiteKnowledgeRepo.getList(
                knowledgeService.websiteKnowledgeChildrenPath(
                  team.id!,
                  parentId
                )
              );

            // Sort children for consistency
            const sortedChildren = children.sort((a, b) =>
              a.url.localeCompare(b.url)
            );

            // Update childPages map for UI rendering
            setChildPages((prev) => {
              const newMap = new Map(prev);
              newMap.set(parentId, sortedChildren);
              return newMap;
            });

            // Store children for later processing
            loadedChildrenMap.set(parentId, sortedChildren);

            // Add all child IDs to valid IDs set
            sortedChildren.forEach((child) => {
              allValidIds.add(child.id);
            });
          } catch (error) {
            console.error(
              `Failed to load children for parent ${parentId}:`,
              error
            );
          } finally {
            setLoadingChildPages((prev) => {
              const newSet = new Set(prev);
              newSet.delete(parentId);
              return newSet;
            });
          }
        }

        // Create final list of valid website sources - keeping any that are in allValidIds
        // and were originally selected
        const validWebsiteSources = data.websiteSources.filter((id) =>
          allValidIds.has(id)
        );

        // Handle document sources separately
        const availableDocumentIds = new Set(documents.map((doc) => doc.id));
        const validDocumentSources = data.documentSources?.filter((id) =>
          availableDocumentIds.has(id)
        );

        // Update node with validated sources
        if (
          validWebsiteSources.length !== data.websiteSources.length ||
          validDocumentSources?.length !== data.documentSources?.length
        ) {
          updateNode({
            websiteSources: validWebsiteSources,
            documentSources: validDocumentSources,
          });
        }
      } catch (error) {
        console.error("Error fetching knowledge sources:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSources();
  }, [team.id]);

  const updateNode = (newData: Partial<KnowledgeNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const handleSourceToggle = (id: string, type: "website" | "document") => {
    if (type === "website") {
      const newSources = data.websiteSources.includes(id)
        ? data.websiteSources.filter((source) => source !== id)
        : [...data.websiteSources, id];
      updateNode({ websiteSources: newSources });
    } else {
      const newSources = data.documentSources?.includes(id)
        ? data.documentSources.filter((source) => source !== id)
        : [...(data.documentSources ?? []), id];
      updateNode({ documentSources: newSources });
    }
  };

  // Toggle parent expansion state and load children if needed
  const toggleExpandParent = async (
    parentId: string,
    website: WebsiteKnowledge
  ) => {
    if (expandedParents.has(parentId)) {
      // Collapse
      const newExpandedParents = new Set(expandedParents);
      newExpandedParents.delete(parentId);
      setExpandedParents(newExpandedParents);
    } else {
      // Expand and load children if not loaded yet
      if (!childPages.has(parentId) && website.hasChildren) {
        await loadChildPagesForParent(parentId);
      }

      // Add to expanded set
      const newExpandedParents = new Set(expandedParents);
      newExpandedParents.add(parentId);
      setExpandedParents(newExpandedParents);
    }
  };

  // Load child pages for a parent website
  const loadChildPagesForParent = async (parentId: string) => {
    if (loadingChildPages.has(parentId)) return;

    try {
      const newLoadingSet = new Set(loadingChildPages);
      newLoadingSet.add(parentId);
      setLoadingChildPages(newLoadingSet);

      const children = await knowledgeService.websiteKnowledgeRepo.getList(
        knowledgeService.websiteKnowledgeChildrenPath(team.id!, parentId)
      );

      // Update the childPages map
      const newChildPagesMap = new Map(childPages);
      newChildPagesMap.set(
        parentId,
        children.sort((a, b) => a.url.localeCompare(b.url))
      );
      setChildPages(newChildPagesMap);
    } catch (error) {
      console.error("Failed to load child pages:", error);
    } finally {
      const newLoadingSet = new Set(loadingChildPages);
      newLoadingSet.delete(parentId);
      setLoadingChildPages(newLoadingSet);
    }
  };

  // Toggle all children of a parent
  const toggleAllChildren = (parentId: string, selected: boolean) => {
    const children = childPages.get(parentId) || [];
    const childIds = children.map((child) => child.id);

    if (selected) {
      // Add all children that aren't already selected
      const newSources = [...new Set([...data.websiteSources, ...childIds])];
      const newChildren = [
        ...(data.websiteChildren ?? []),
        ...childIds.map((childId) => {
          return {
            id: childId,
            parentId,
          };
        }),
      ];

      updateNode({ websiteSources: newSources, websiteChildren: newChildren });
    } else {
      // Remove all children
      const newSources = data.websiteSources.filter(
        (id) => !childIds.includes(id)
      );
      const newChildren = data.websiteChildren?.filter(
        (child) => !childIds.includes(child.id)
      );
      updateNode({ websiteSources: newSources, websiteChildren: newChildren });
    }
  };

  // Check if all children are selected
  const areAllChildrenSelected = (parentId: string) => {
    const children = childPages.get(parentId) || [];
    if (children.length === 0) return false;

    return children.every((child) => data.websiteSources.includes(child.id));
  };

  // Check if some (but not all) children are selected
  const areSomeChildrenSelected = (parentId: string) => {
    const children = childPages.get(parentId) || [];
    if (children.length === 0) return false;

    const selectedCount = children.filter((child) =>
      data.websiteSources.includes(child.id)
    ).length;

    return selectedCount > 0 && selectedCount < children.length;
  };

  // Render the list of child pages
  const renderChildItems = (parentId: string) => {
    if (!expandedParents.has(parentId)) return null;

    if (loadingChildPages.has(parentId)) {
      return (
        <div className="ml-6 py-2 flex items-center text-gray-500 text-xs">
          <CommonSpinner size="sm" />
          <span className="ml-2">Loading child pages...</span>
        </div>
      );
    }

    const children = childPages.get(parentId) || [];

    if (children.length === 0) {
      return (
        <div className="ml-6 py-2 text-gray-500 text-xs italic">
          No child pages found
        </div>
      );
    }

    // Allow selecting all children at once
    const allSelected = areAllChildrenSelected(parentId);
    const someSelected = areSomeChildrenSelected(parentId);

    return (
      <div className="ml-6 border-l border-gray-200 pl-2">
        {/* "Select all" option */}
        <div className="flex items-center gap-2 py-1 px-2 mb-1 bg-gray-50 rounded-md">
          <input
            type="checkbox"
            checked={allSelected}
            ref={(input) => {
              if (input) {
                input.indeterminate = someSelected && !allSelected;
              }
            }}
            onChange={(e) => toggleAllChildren(parentId, e.target.checked)}
            className="rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
          />
          <span className="text-xs font-medium text-gray-700">
            {allSelected ? "Deselect all" : "Select all child pages"}
          </span>
        </div>

        {/* Individual child pages */}
        {children.map((child) => (
          <label
            key={child.id}
            className="flex items-start gap-2 cursor-pointer py-1 px-2 hover:bg-gray-100 rounded-md my-1"
          >
            <input
              type="checkbox"
              checked={data.websiteSources.includes(child.id)}
              onChange={() => handleSourceToggle(child.id, "website")}
              className="mt-1 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
            />
            <div className="flex flex-col overflow-hidden">
              <span className="text-xs font-medium text-gray-700 truncate">
                {child.title || "Child page"}
              </span>
              <span className="text-xs text-gray-500 truncate">
                {child.url}
              </span>
            </div>
          </label>
        ))}
      </div>
    );
  };

  // Render a website item (parent or standalone)
  const renderWebsiteItem = (website: WebsiteKnowledge) => {
    const isSelected = data.websiteSources.includes(website.id);
    const hasChildren = website.hasChildren && (website.successCount || 0) > 0;
    const isExpanded = expandedParents.has(website.id);

    // Get child selection state
    const allChildrenSelected =
      hasChildren && areAllChildrenSelected(website.id);
    const someChildrenSelected =
      hasChildren && areSomeChildrenSelected(website.id);

    return (
      <div key={website.id} className="mb-2">
        <div className="flex items-start">
          <label className="flex items-start gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-md flex-grow">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => handleSourceToggle(website.id, "website")}
              className="mt-1 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
            />
            <div className="flex flex-col overflow-hidden">
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-700 truncate">
                  {website.title || website.url.split("/")[2]}
                </span>
                {(allChildrenSelected || someChildrenSelected) && (
                  <span className="ml-2 text-xs text-blue-600">
                    {allChildrenSelected ? "All" : "Some"} child pages selected
                  </span>
                )}
              </div>
              <span className="text-xs text-gray-500 truncate">
                {website.url}
              </span>
            </div>
          </label>

          {hasChildren && (
            <button
              onClick={() => toggleExpandParent(website.id, website)}
              className="text-gray-500 hover:text-gray-700 p-1 mt-2"
              title={isExpanded ? "Collapse child pages" : "Expand child pages"}
            >
              <Icon
                type={"chevron"}
                className={`${isExpanded ? "rotate-180" : ""}`}
              />
            </button>
          )}
        </div>

        {/* Child pages section */}
        {renderChildItems(website.id)}
      </div>
    );
  };

  // Render a document item
  const renderDocumentItem = (document: DocumentKnowledge) => {
    const isSelected = data.documentSources?.includes(document.id);

    return (
      <label
        key={document.id}
        className="flex items-start gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-md mb-1"
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => handleSourceToggle(document.id, "document")}
          className="mt-1 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
        />
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-700 truncate">
            {document.title}
          </span>
          <span className="text-xs text-gray-500 truncate">
            {document.title}
          </span>
        </div>
      </label>
    );
  };

  const knowledgeSections: KnowledgeSourceSection[] = [
    {
      title: "Websites",
      items: availableWebsites,
      type: "website",
    },
    {
      title: "Documents",
      items: availableDocuments,
      type: "document",
    },
  ];
  const availableVariables = Object.keys(version.usedVariables ?? {});

  const totalSelectedSources =
    data.websiteSources.length + (data.documentSources ?? []).length;

  return (
    <div className="flex flex-col gap-4">
      <KnowledgeTestModal
        shows={showTestModal}
        setShows={setShowTestModal}
        data={data}
        team={team}
        selectedWebsiteNames={[
          ...availableWebsites.map((w) => w.title ?? ""),
          ...availableDocuments.map((d) => d.title ?? ""),
        ]}
      />

      <AnimatedButton
        title="Preview Knowledge"
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        onClick={() => setShowTestModal(true)}
        style="secondary"
        font="font-sans font-medium"
        leftIcon="play"
      />
      <AdvancedQuerySettingsModal
        isOpen={showAdvancedSettings}
        onClose={() => setShowAdvancedSettings(false)}
        settings={{
          resultCount: data.resultCount,
          similarityThreshold: data.similarityThreshold,
          contextWindow: data.contextWindow,
        }}
        onUpdate={(newSettings) => {
          updateNode({
            resultCount: newSettings.resultCount,
            similarityThreshold: newSettings.similarityThreshold,
            contextWindow: newSettings.contextWindow,
          });
        }}
        title="Knowledge Advanced Settings"
      />

      <CommonInputSegment
        title="Knowledge Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="knowledgeTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Search Query</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.input}
          setSelectedInput={(input) => updateNode({ input })}
          currentNodeId={data.id}
          id="knowledgeQuery"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Select the input to use as the search query for finding relevant
          knowledge
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <div className="text-sm font-medium text-gray-700">
            Select Knowledge Sources
          </div>
          <CommonUnderlineButton
            title="Advanced settings"
            onClick={() => setShowAdvancedSettings(true)}
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">
              Loading knowledge sources...
            </div>
          </div>
        ) : availableWebsites.length === 0 &&
          availableDocuments.length === 0 ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">
              No knowledge sources available
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-3 bg-gray-50 rounded-md max-h-96 overflow-y-auto border border-gray-200">
            {knowledgeSections.map((section) => (
              <div key={section.type} className="flex flex-col gap-2">
                <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {section.title}
                </div>

                {section.items.length === 0 ? (
                  <div className="text-sm text-gray-500 italic">
                    No {section.title.toLowerCase()} available
                  </div>
                ) : (
                  <div className="flex flex-col">
                    {section.type === "website"
                      ? section.items.map((item) =>
                          renderWebsiteItem(item as WebsiteKnowledge)
                        )
                      : section.items.map((item) =>
                          renderDocumentItem(item as DocumentKnowledge)
                        )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-between">
          <div className="text-xs text-gray-400 mt-1">
            {totalSelectedSources} sources selected
          </div>
          <CommonUnderlineButton
            title="Add knowledge"
            onClick={() => window.open(AppPath.knowledge(team.id!), "_blank")}
          />
        </div>
      </div>
      <InfoComponent howToId={KnowledgeNodeType} howToName="Knowledge Step" />
    </div>
  );
};
