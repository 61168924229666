// services/FirebaseFileUploadService.ts
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  FirebaseStorage,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export interface FileUploadService {
  uploadFile: (file: File, path: string) => Promise<string>;
  subscribe: (listener: (state: UploadState) => void) => () => void;
}

export interface UploadState {
  isUploading: boolean;
  progress: number | null;
}

export class FirebaseFileUploadService {
  private state: UploadState = {
    isUploading: false,
    progress: null,
  };
  private listeners: ((state: UploadState) => void)[] = [];

  constructor(private storage: FirebaseStorage) {}

  private setState(newState: Partial<UploadState>) {
    this.state = { ...this.state, ...newState };
    this.listeners.forEach((listener) => listener(this.state));
  }

  subscribe(listener: (state: UploadState) => void) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  async uploadFile(file: File, path: string): Promise<string> {
    this.setState({ isUploading: true, progress: 0 });

    const filename = `${uuidv4()}-${file.name}`;
    // Store with team ID and creation timestamp in metadata
    const fullPath = `${path}/${filename}`;
    const metadata = {
      customMetadata: {
        createdAt: Date.now().toString(),
      },
    };

    const storageRef = ref(this.storage, fullPath);

    try {
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ progress });
      });

      await uploadTask;
      const url = await getDownloadURL(storageRef);

      this.setState({ isUploading: false, progress: null });
      return url;
    } catch (error) {
      this.setState({ isUploading: false, progress: null });
      throw error;
    }
  }
}
