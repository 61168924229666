import React, { useEffect } from "react";
import { ReactNode } from "react";
import { CommonInputSegment } from "../../Common/CommonInput";

interface HexColorInputSegmentProps {
  title: string;
  subtitle?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
  setError?: (error: string) => void;
  rightItem?: ReactNode;
  id?: string;
  className?: string;
  leftItem?: ReactNode;
}

export const HexColorInputSegment: React.FC<HexColorInputSegmentProps> = ({
  title,
  value = "",
  onChange,
  disabled,
  error,
  setError,
  rightItem,
  id,
  className,
  leftItem,
  subtitle,
}) => {
  // Initial validation of the input value
  useEffect(() => {
    if (value && !value.startsWith("#")) {
      onChange(`#${value}`);
    }
  }, []);

  const handleChange = (newValue: string) => {
    // Remove any non-hex characters (except #)
    let sanitized = newValue.replace(/[^#0-9A-Fa-f]/g, "");

    // Ensure there's only one # at the start
    if (sanitized.includes("#")) {
      sanitized = "#" + sanitized.replace(/#/g, "");
    }

    // Add # if it's missing
    if (!sanitized.startsWith("#") && sanitized.length > 0) {
      sanitized = "#" + sanitized;
    }

    // Limit to 7 characters (#RRGGBB)
    sanitized = sanitized.slice(0, 7);

    // Validate the hex color
    const isValidHex = /^#[0-9A-Fa-f]{6}$/.test(sanitized);
    if (sanitized.length === 7 && !isValidHex) {
      setError?.("Invalid hex color");
    } else {
      setError?.("");
    }

    onChange(sanitized);
  };

  return (
    <CommonInputSegment
      title={title}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      placeholder="Enter hex color (e.g. #FF0000)"
      error={error}
      setError={setError}
      rightItem={rightItem}
      id={id}
      className={className}
      leftItem={leftItem}
      subtitle={subtitle}
      type="text"
    />
  );
};

export default HexColorInputSegment;
