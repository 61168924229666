// src/services/analytics/PostHogAnalytics.ts
import posthog from "posthog-js";
import { AnalyticsService } from "./AnalyticsService";

export class PostHogAnalytics implements AnalyticsService {
  constructor(apiKey: string) {
    posthog.init(apiKey, {
      api_host: "https://us.posthog.com",
      loaded: (posthog) => {
        if (this.isOptedIn()) {
          posthog.opt_in_capturing();
        } else {
          posthog.opt_out_capturing();
        }
      },
    });
  }

  optIn(): void {
    posthog.opt_in_capturing();
  }

  optOut(): void {
    posthog.opt_in_capturing();
  }

  isOptedIn(): boolean {
    return posthog.has_opted_in_capturing();
  }

  track(eventName: string, properties?: Record<string, any>): void {
    posthog.capture(eventName, properties);
  }

  identify(userId: string, properties?: Record<string, any>): void {
    posthog.identify(userId, properties);
  }

  group(
    groupType: string,
    groupId: string,
    properties?: Record<string, any>
  ): void {
    posthog.group(groupType, groupId, properties);
  }

  reset(): void {
    posthog.reset();
  }
}
