import React, { useState, useEffect } from "react";
import { Portal } from "../../models/Portal";
import { usePortalService } from "../../contexts/PortalContext";

interface DemoDataSectionProps {
  teamId: string;
}

export const PortalExampleSection: React.FC<DemoDataSectionProps> = ({
  teamId,
}) => {
  const portalService = usePortalService();

  const [portals, setPortals] = useState<Portal[]>([]);
  const [examplePortals, setExamplePortals] = useState<Portal[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedPortals, fetchedExamplePortals] = await Promise.all([
        portalService.portalRepo.getList(portalService.portalPath(teamId), {
          name: "modifiedAt",
          descending: true,
        }),
        portalService.portalRepo.getList(portalService.examplePortalPath(), {
          name: "modifiedAt",
          descending: true,
        }),
      ]);
      setPortals(fetchedPortals);
      setExamplePortals(fetchedExamplePortals);
    };
    loadData();
  }, [portalService, teamId]);

  const handleRowClick = (portal: Portal) => {
    console.log("clicked", portal.id);
  };

  const handleCopyToExamples = async (portal: Portal) => {
    try {
      await portalService.copyPortalToExamples(teamId, portal.id!);
      const fetchedExamplePortals = await portalService.portalRepo.getList(
        portalService.examplePortalPath(),
        {
          name: "modifiedAt",
          descending: true,
        }
      );
      setExamplePortals(fetchedExamplePortals);
    } catch (error) {
      console.error("Error copying prompt to examples:", error);
    }
  };

  const handleDeleteExamplePortal = async (portalId: string) => {
    try {
      await portalService.deletePortalExample(portalId);
      setExamplePortals(examplePortals.filter((p) => p.id !== portalId));
    } catch (error) {
      console.error("Error deleting example portal:", error);
    }
  };

  const handleCopyToProd = async (portalId: string) => {
    try {
      await portalService.copyExampleToProd(portalId);
    } catch (error) {
      console.error("Error deleting example portal:", error);
    }
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-4">Example Prompts</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {examplePortals.map((portal) => (
            <tr
              key={portal.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(portal)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{portal.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="text-blue-600 hover:text-red-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToProd(portal.id);
                  }}
                >
                  Copy to prod
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteExamplePortal(portal.id);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="text-xl font-bold mb-4">Prompts</h2>
      <table className="min-w-full divide-y divide-gray-200 mb-8">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {portals.map((portal) => (
            <tr
              key={portal.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(portal)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{portal.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="text-blue-600 hover:text-blue-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToExamples(portal);
                  }}
                >
                  Copy to Examples
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
