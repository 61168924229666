import { Repository } from "../repos/Repository";
import { CrawlOptions, WebsiteKnowledge } from "../models/WebsiteKnowledge";
import { Networking } from "../utils/NetworkHelper";
import { KnowledgeQueryConfig, WebsiteFilterOptions } from "../models/Workflow";
import { ChunkEncodingStrategy } from "../models/ChunkEncodingStrategy";
import { DocumentKnowledge } from "../models/DocumentKnowledge";
import { DatabaseKnowledge } from "../models/DatabaseKnowledge";

export interface AddWebsiteKnowledgeRequest {
  url: string;
  filterOptions: WebsiteFilterOptions;
  encoding: ChunkEncodingStrategy;
  crawlOptions?: CrawlOptions;
  parentId?: string;
}

interface ImportResult {
  successCount: number;
  message: string;
  failedItems?: Array<{
    index: number;
    recordId?: string;
    reason: string;
  }>;
}

export interface ImportJsonToDatabaseRequest {
  json: string;
  databaseId: string;
  encodingStrategy: ChunkEncodingStrategy;
  appendContent?: boolean;
}

interface DatabaseRecord {
  recordId: string;
  content: string;
  metadata: Record<string, string>;
}

interface ReadDatabaseResponse {
  records: DatabaseRecord[];
  nextPaginationToken?: string | null;
}

export interface KnowledgeService {
  websiteKnowledgeRepo: Repository<WebsiteKnowledge>;
  websiteKnowledgePath(teamId: string): string;
  documentKnowledgeRepo: Repository<DocumentKnowledge>;
  documentKnowledgePath(teamId: string): string;
  databaseKnowledgeRepo: Repository<DatabaseKnowledge>;
  databaseKnowledgePath(teamId: string): string;
  websiteKnowledgeChildrenPath(teamId: string, parentId: string): string;
  addWebsiteKnowledge(
    teamId: string,
    data: AddWebsiteKnowledgeRequest
  ): Promise<WebsiteKnowledge>;
  removeWebsiteKnowledge(
    teamId: string,
    id: string,
    parentId?: string
  ): Promise<void>;
  previewKnowledge(
    teamId: string,
    input: string,
    queryConfig: KnowledgeQueryConfig,
    websiteSources: string[],
    documentSources: string[]
  ): Promise<string>;

  addDocumentKnowledge(
    teamId: string,
    data: FormData
  ): Promise<DocumentKnowledge>;
  removeDocumentKnowledge(teamId: string, id: string): Promise<void>;
  importJsonToDatabase(
    teamId: string,
    databaseId: string,
    jsonData: string,
    encodingStrategy: ChunkEncodingStrategy,
    appendContent?: boolean
  ): Promise<ImportResult>;
  browseDatabaseRecords(
    teamId: string,
    databaseId: string,
    limit?: number,
    paginationToken?: string | null,
    metadataFilters?: Record<string, string>
  ): Promise<ReadDatabaseResponse>;
}

export class FirestoreKnowledgeService implements KnowledgeService {
  constructor(
    public websiteKnowledgeRepo: Repository<WebsiteKnowledge>,
    public documentKnowledgeRepo: Repository<DocumentKnowledge>,
    public databaseKnowledgeRepo: Repository<DatabaseKnowledge>,
    private networkHelper: Networking
  ) {}

  websiteKnowledgePath(teamId: string): string {
    return `teams/${teamId}/websiteKnowledge`;
  }
  websiteKnowledgeChildrenPath(teamId: string, parentId: string): string {
    return `${this.websiteKnowledgePath(teamId)}/${parentId}/children`;
  }
  documentKnowledgePath(teamId: string): string {
    return `teams/${teamId}/documentKnowledge`;
  }
  databaseKnowledgePath(teamId: string): string {
    return `teams/${teamId}/databaseKnowledge`;
  }

  async addWebsiteKnowledge(
    teamId: string,
    data: AddWebsiteKnowledgeRequest
  ): Promise<WebsiteKnowledge> {
    const response = await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/websites`,
      "POST",
      data
    );
    const json = await response.json();

    return json as WebsiteKnowledge;
  }

  async removeWebsiteKnowledge(
    teamId: string,
    id: string,
    parentId?: string
  ): Promise<void> {
    const sourceId = id.split("/").pop();

    await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/websites/${sourceId}`,
      "DELETE",
      { parentId }
    );
  }

  async previewKnowledge(
    teamId: string,
    input: string,
    queryConfig: KnowledgeQueryConfig,
    websiteSources: string[],
    documentSources: string[]
  ): Promise<string> {
    const serverResponse = await this.networkHelper.sendRequest(
      "/preview/knowledge",
      "POST",
      {
        input,
        teamId,
        queryConfig,
        websiteSources,
        documentSources,
      }
    );
    const { response } = await serverResponse.json();
    return response;
  }

  async addDocumentKnowledge(
    teamId: string,
    data: FormData
  ): Promise<DocumentKnowledge> {
    const response = await this.networkHelper.sendFormData(
      `/teams/${teamId}/knowledge/documents`,
      "POST",
      data
    );
    const json = await response.json();

    return json as DocumentKnowledge;
  }

  async removeDocumentKnowledge(teamId: string, id: string): Promise<void> {
    const sourceId = id.split("/").pop();

    await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/documents/${sourceId}`,
      "DELETE"
    );
  }

  async importJsonToDatabase(
    teamId: string,
    databaseId: string,
    jsonData: string,
    encodingStrategy: ChunkEncodingStrategy,
    appendContent: boolean = false
  ): Promise<ImportResult> {
    try {
      const payload: ImportJsonToDatabaseRequest = {
        json: jsonData,
        databaseId: databaseId,
        encodingStrategy: encodingStrategy,
        appendContent: appendContent,
      };

      const response = await this.networkHelper.sendRequest(
        `/teams/${teamId}/database/import`,
        "POST",
        payload
      );

      const result = await response.json();
      return result as ImportResult;
    } catch (error) {
      console.error("Error importing JSON data:", error);
      throw error;
    }
  }
  async browseDatabaseRecords(
    teamId: string,
    databaseId: string,
    limit: number = 10,
    paginationToken?: string | null,
    metadataFilters?: Record<string, string>
  ): Promise<ReadDatabaseResponse> {
    try {
      const queryParams = new URLSearchParams();

      if (paginationToken) {
        queryParams.append("paginationToken", paginationToken);
      }

      if (limit) {
        queryParams.append("limit", limit.toString());
      }

      const response = await this.networkHelper.sendRequest(
        `/teams/${teamId}/database/read/${databaseId}?${queryParams.toString()}`,
        "POST",
        { metadataFilters }
      );

      const result = await response.json();
      return result as ReadDatabaseResponse;
    } catch (error) {
      console.error("Error browsing database records:", error);
      throw error;
    }
  }
}
