import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

import SignUp from "./components/Auth/SignUp";
import LogIn from "./components/Auth/LogIn";
import CatchAll from "./components/CatchAll";
import TeamWrapper from "./components/Navigation/TeamWrapper";
import ConfirmInvite from "./components/ConfirmInvite";
import UserOnboarding from "./components/Onboarding/UserOnboarding";
import { User } from "./models/User";
import EditProfile from "./components/EditProfile/EditProfile";
import ForgotPassword from "./components/Auth/ForgotPassword";
import VerifyEmail from "./components/Auth/VerifyEmail";
import TeamOnboarding from "./components/Teams/TeamOnboarding";
import HomePage from "./components/Home/Home";
import PostOnboarding from "./components/Onboarding/PostOnboarding";
import TeamLoading from "./components/TeamLoading";
import Notifications from "./components/Notifications/Notifications";
import OkayBye from "./components/Auth/OkayBye";
import Portals from "./components/Portals/Portals";
import PortalLogs from "./components/PortalLogs/PortalLogs";
import PortalDetail from "./components/Portals/Portal Detail/PortalDetail";
import Workflows from "./components/Workflows/Workflows";
import VersionHistory from "./components/Portals/Portal Detail/VersionHistory";
import { OnboardingLoading } from "./components/Onboarding/OnboardingLoading";
import { TeamSettingsMembers } from "./components/TeamSettings/TeamSettingsMembers";
import { TeamSettingsBilling } from "./components/TeamSettings/TeamSettingsBilling";
import { TeamSettingsTokens } from "./components/TeamSettings/TeamSettingsTokens";
import { TeamSettingsAIProviders } from "./components/TeamSettings/TeamSettingsAIProviders";
import CookieConsent from "./components/CookieConsent";
import { TeamSettingsAIProviderDetail } from "./components/TeamSettings/TeamSettingsAIProviderDetail";
import { TeamSettingsVariables } from "./components/TeamSettings/TeamSettingsVarables";
import Reviews from "./components/Portals/Reviews";
import { Analytics } from "./components/Analytics/Analytics";
import { TeamSettingsTeamProfile } from "./components/TeamSettings/TeamSettingsTeamProfile";
import { TestCenter } from "./components/TestCenter/TestCenter";
import { Intercom, update, shutdown } from "@intercom/messenger-js-sdk";
import { WorkflowDetail } from "./components/Workflows/WorkflowDetail";
import { Admin } from "./components/Admin/Admin";
import { STORAGE_KEYS } from "./utils/StorageKeys";
import { TeamSettingsCustomSecrets } from "./components/TeamSettings/TeamSettingsCustomSecrets";

import { SoloAgent } from "./components/SoloAgents/SoloAgent";
import { KnowledgeWebsites } from "./components/Knowledge/KnowledgeWebsites";
import { KnowledgeDocuments } from "./components/Knowledge/KnowledgeDocuments";
import { useAnalytics } from "./contexts/AnalyticsContext";
import { KnowledgeDatabases } from "./components/Knowledge/KnowledgeDatabases";
import { TeamSettingsVectorDBs } from "./components/TeamSettings/TeamSettingsVectorDBs";
import { CommonLoading } from "./components/Common/CommonLoading";

const App: React.FC = () => {
  const auth = useAuth();
  const currentUserRef = useRef<User>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const hmac = useRef<string>();
  const [isLoading, setIsLoading] = useState(true);
  const analytics = useAnalytics();

  const company = currentUserRef.current?.currentTeam
    ? { company_id: currentUserRef.current?.currentTeam }
    : undefined;

  Intercom({
    app_id: "gbs4qe02",
    user_id: currentUserRef.current?.id,
    name: currentUserRef.current?.name,
    email: currentUserRef.current?.email,
    created_at: currentUserRef.current?.createdAt?.getTime(),
    company,
    user_hash:
      hmac.current ?? localStorage.getItem(STORAGE_KEYS.USER_HASH) ?? undefined,
  });

  const reloadIntercom = async () => {
    try {
      update({
        app_id: "gbs4qe02",
        user_id: currentUserRef.current?.id,
        name: currentUserRef.current?.name,
        email: currentUserRef.current?.email,
        created_at: currentUserRef.current?.createdAt?.getTime(), // BUG!
        user_hash:
          hmac.current ??
          localStorage.getItem(STORAGE_KEYS.USER_HASH) ??
          undefined,
      });
    } catch (error) {
      console.error("Failed to reload Intercom:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.userStateChanged((user) => {
      const prevUser = currentUserRef.current;

      // Always update the ref immediately for consistent comparisons
      currentUserRef.current = user ?? undefined;

      // Check if we need to update state based on auth or onboarding changes
      const shouldUpdateState =
        // Auth status change (logged in/out)
        (!prevUser && user) ||
        (prevUser && !user) ||
        // Onboarding status change
        (prevUser &&
          user &&
          auth.isOnboarded(prevUser) !== auth.isOnboarded(user));

      // Always update loading state on first auth check
      // This ensures we don't get stuck in loading state
      if (isLoading) {
        setIsLoading(false);
        setCurrentUser(user);
      }
      // Otherwise only update state when needed
      else if (shouldUpdateState) {
        setCurrentUser(user);

        if (user) {
          analytics?.identify(user.id, user);
        }

        if (user && hmac.current === undefined) {
          auth.getHMAC().then((newHmac) => {
            localStorage.setItem(STORAGE_KEYS.USER_HASH, newHmac);
            hmac.current = newHmac;
            reloadIntercom();
          });
        } else if (!user) {
          hmac.current = undefined;
          shutdown();
        }
      }
    });

    return unsubscribe;
  }, [isLoading]); // Add isLoading as a dependency

  // This component will handle the redirect to the current team
  const CurrentTeamRedirect: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (currentUser?.currentTeam) {
        // Extract the path after "currentTeam"
        const path = location.pathname;
        const pathAfterCurrentTeam = path.replace("/teams/currentTeam", "");

        // Redirect to the actual team ID with the same path
        navigate(`/teams/${currentUser.currentTeam}${pathAfterCurrentTeam}`, {
          replace: true,
        });
      }
    }, [navigate, location, currentUser]);

    return <CommonLoading />;
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (isLoading) {
      return <TeamLoading />;
    }

    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    if (!currentUser.verifiedEmail) {
      return <Navigate to="/verify" />;
    }

    if (!auth.isOnboarded(currentUser)) {
      return <Navigate to="/onboarding" />;
    }

    return <>{children}</>;
  };

  const ProtectLoading: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (isLoading) {
      return <CommonLoading />;
    }

    return <>{children}</>;
  };

  const ProtectedOnboarding: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (isLoading) {
      return <OnboardingLoading />;
    }

    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return <>{children}</>;
  };

  return (
    <Router>
      <div className="app-container">
        {isLoading ? null : <CookieConsent />}
        <Routes>
          <Route path="/agent/:agentId" element={<SoloAgent />} />

          {/* Auth  */}
          <Route path="/bye" element={<OkayBye />} />
          <Route
            path="/signup"
            element={
              !currentUser ? <SignUp /> : <Navigate to="/postonboarding" />
            }
          />
          <Route
            path="/login"
            element={
              !currentUser ? <LogIn /> : <Navigate to="/postonboarding" />
            }
          />
          <Route
            path="/forgotPassword"
            element={
              !currentUser ? (
                <ForgotPassword />
              ) : (
                <Navigate to="/postonboarding" />
              )
            }
          />
          <Route
            path="/verify"
            element={
              currentUser && !currentUser.verifiedEmail ? (
                <VerifyEmail />
              ) : (
                <Navigate to="/postonboarding" />
              )
            }
          />

          {/* Onboarding  */}
          <Route
            path="/onboarding"
            element={
              <ProtectedOnboarding>
                {!auth.isOnboarded(currentUser) ? (
                  <UserOnboarding />
                ) : (
                  <Navigate to="/postonboarding" />
                )}
              </ProtectedOnboarding>
            }
          />
          <Route
            path="/postonboarding"
            element={
              <ProtectedRoute>
                <PostOnboarding />
              </ProtectedRoute>
            }
          />

          <Route
            path="invites/:teamId/"
            element={
              <ProtectLoading>
                <ConfirmInvite />
              </ProtectLoading>
            }
          />

          {/* New Team  */}
          <Route
            path="/newteam"
            element={
              <ProtectedRoute>
                <TeamOnboarding />
              </ProtectedRoute>
            }
          />

          {/* Current Team Redirect */}
          <Route
            path="/teams/currentTeam/*"
            element={
              <ProtectedRoute>
                <CurrentTeamRedirect />
              </ProtectedRoute>
            }
          />

          {/* Teams  */}
          <Route
            path="/teams/:teamId/*"
            element={
              <ProtectedRoute>
                <TeamWrapper currentUser={currentUser!} />
              </ProtectedRoute>
            }
          >
            {/* Home  */}
            <Route index element={<Navigate to="home" />} />
            <Route
              path="home"
              element={<HomePage currentUser={currentUser ?? undefined} />}
            />

            {/* Portals/prompts  */}
            <Route
              path="prompts/:portalId/versions"
              element={<VersionHistory />}
            />
            <Route path="prompts" element={<Portals />} />
            <Route
              path="prompts/:portalId/version/:versionId?"
              element={<PortalDetail />}
            />

            <Route
              path="prompts/:portalId/version/:versionId/reviews/:reviewId?"
              element={<Reviews />}
            />

            {/* Notifications  */}
            <Route path="notifications" element={<Notifications />} />

            {/* Profile  */}
            <Route
              path="profile"
              element={<EditProfile currentUser={currentUser ?? undefined} />}
            />

            {/* Logs  */}
            <Route path="logs/:logId?" element={<PortalLogs />} />

            {/* Analytics  */}
            <Route path="analytics" element={<Analytics />} />

            {/* Workflows  */}
            <Route path="agents" element={<Workflows />} />
            <Route path="agents/:workflowId" element={<WorkflowDetail />} />

            {/* Test Center  */}
            <Route path="test-center" element={<TestCenter />} />

            {/* Team Settings */}
            <Route path="settings" element={<Navigate to="./providers" />} />
            <Route
              path="settings/variables"
              element={<TeamSettingsVariables />}
            />
            <Route path="settings/members" element={<TeamSettingsMembers />} />
            <Route path="settings/billing" element={<TeamSettingsBilling />} />
            <Route path="settings/tokens" element={<TeamSettingsTokens />} />
            <Route
              path="settings/custom-secrets"
              element={<TeamSettingsCustomSecrets />}
            />
            <Route
              path="settings/providers"
              element={<TeamSettingsAIProviders />}
            />
            <Route
              path="settings/providers/:providerId"
              element={<TeamSettingsAIProviderDetail />}
            />
            <Route
              path="settings/team-profile"
              element={<TeamSettingsTeamProfile />}
            />
            <Route
              path="settings/vector-dbs"
              element={<TeamSettingsVectorDBs />}
            />

            {/* Knowledge */}
            <Route path="knowledge" element={<Navigate to="./websites" />} />
            <Route path="knowledge/websites" element={<KnowledgeWebsites />} />
            <Route
              path="knowledge/documents"
              element={<KnowledgeDocuments />}
            />
            <Route path="data" element={<KnowledgeDatabases />} />

            <Route path="secretadminpage" element={<Admin />} />

            <Route path="*" element={<CatchAll />} />
          </Route>

          {/* Default route */}
          <Route path="/" element={<Navigate to="/postonboarding" />} />
          <Route path="*" element={<CatchAll />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
